// import Portal from '@codeverse-studio/components/Shared/Portal'
// import {
//   MarkdownRenderOptions,
//   MarkdownRenderOptionsLight,
// } from '@codeverse-studio/components/Studio/GuidedMissions/Steps/MarkdownOptions'
// import { useUIState } from '@codeverse-studio/containers/Context/UIStateContainer'
// import useGuidedMissions from '@codeverse-studio/containers/Hooks/useGuidedMissions'
// import {
//   useCreateMissionStepTip,
//   useDeleteMissionStepTip,
// } from '@endpoints/Missions'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
// import MissionStepTipModal from '../MissionStepTipModal'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import Portal from 'apps/studio/components/Shared/Portal'
import MissionStepTipModal from './MissionStepTipModal'

import { Markdown } from '@codeverse/helios/markdown'
import { useMissionStepTips } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { useDeleteMissionStepTip } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import { toast } from 'react-toastify'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import MarkdownPreview from 'apps/studio-shared/src/Components/Studio/CodeReference/MarkdownPreview'

interface EditMissionStepTipsProps {}

const EditMissionStepTips: React.FC<EditMissionStepTipsProps> = ({}) => {
  const { currentStep } = useMissionStore()
  const [tempMissionStepTips, setTempMissionStepTips] = React.useState([])
  const [currentStepTipIndex, setCurrentStepTipIndex] = useState<number | null>(
    0
  )
  const { data: missionStepTips } = useMissionStepTips(currentStep?.id)
  const { missionStepTipModal, setMissionStepTipModal } = useUIStore()
  const queryClient = useQueryClient()

  const deleteMissionStepTip = useDeleteMissionStepTip()

  useEffect(() => {
    if (missionStepTips) {
      setTempMissionStepTips(missionStepTips)
    }
  }, [missionStepTips])

  const inputClassname = classNames('!py-8')

  useEffect(() => {
    if (!missionStepTipModal) {
      setCurrentStepTipIndex(null)
    }
  }, [missionStepTipModal])

  const MissionStepTips = useMemo(() => {
    const stepTips = tempMissionStepTips
    return stepTips?.map((missionStepTip: any, index: any) => {
      return (
        <div
          key={missionStepTip.id}
          className="relative mt-4 flex cursor-pointer flex-row overflow-hidden rounded-2xl bg-white"
          onClick={() => {
            setCurrentStepTipIndex(index)
            setMissionStepTipModal(true)
            // openUI('missionStepTipModal')
          }}
        >
          <div className="bg-sun-dark h-auto w-12">
            <div className=" flex h-full flex-grow items-center justify-center">
              <div className="font-poppins-bold mx-4 -rotate-90 transform py-1.5 text-center text-base text-white">
                Tip
              </div>
            </div>
          </div>
          <div className="text-moon-darkest font-poppins-regular p-3">
            <MarkdownPreview>{missionStepTip.content}</MarkdownPreview>
          </div>
          <div
            className="bg-moon-light z-2000 absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              deleteMissionStepTip.mutate(missionStepTip.id, {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ['missionStepTips'],
                  })
                  toast.success('Deleted mission step tip')
                },
              })
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      )
    })
  }, [tempMissionStepTips, currentStep])

  return (
    <div className="">
      {tempMissionStepTips?.length > 0 && (
        <div className="mb-18">{MissionStepTips}</div>
      )}
      <Portal idToSearch="MissionStepTipModalDiv">
        <MissionStepTipModal
          currentStepTipIndex={currentStepTipIndex}
          tempMissionStepTips={tempMissionStepTips}
          setTempMissionStepTips={setTempMissionStepTips}
          type="edit"
        />
      </Portal>
    </div>
  )
}

export default EditMissionStepTips
