import classNames from 'classnames'
import React from 'react'

interface KidScriptCodeInlineProps {
  children: string
  noMargin?: boolean
}

const KidScriptCodeInline: React.FC<KidScriptCodeInlineProps> = ({
  children,
  noMargin,
}) => {
  console.log('typeof', children)

  return (
    <pre
      className={classNames(
        'bg-moon-darkest font-roboto-mono kidscript-syntax mt-2 inline-block overflow-x-scroll rounded-[12px] px-3 py-1.5 text-sm'
      )}
      dangerouslySetInnerHTML={{
        //@ts-ignore
        __html: KidScript.parseBlock(children[0] || '').toHTML(),
      }}
    ></pre>
  )
}

export default KidScriptCodeInline
