import Button from '@codeverse/helios/button'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import Header from '../Header'
import { UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { MissionStepFormValues } from '../../types'
import LibraryFiltersList from './LibraryFiltersList'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'

interface LibraryFiltersProps {
  getValues: UseFormGetValues<MissionStepFormValues>
  watch: UseFormWatch<MissionStepFormValues>
}

const LibraryFilters: React.FC<LibraryFiltersProps> = ({
  getValues: getValues,
  watch,
}) => {
  const { setShowToolbox, setToolboxLibraryFilter } = useRuntimeStore()

  const [libraryFilters, setLibraryFilters] = React.useState<any>(null)

  useEffect(() => {
    setLibraryFilters(getValues().library)
    const subscription = watch((value) => {
      if (value) {
        setLibraryFilters(value.library)
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center">
        <h4 className="leading-24 !mb-0 text-white">Library Filters</h4>
        <div className="ml-auto">
          <Button
            className="!pl-4 pr-6 shadow-none"
            size="small"
            context="nebula"
            onClick={(e) => {
              e.preventDefault()
              // setToolboxMissionStepInsertable(false)
              setToolboxLibraryFilter(true)
              setShowToolbox(true)
            }}
          >
            <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
            Add
          </Button>
        </div>
      </div>
      <div className="mt-0.5">
        <p className="text-neptune-lightest mb-4 text-xs leading-6">
          Add filters for library objects
        </p>
      </div>

      {libraryFilters && <LibraryFiltersList library={libraryFilters} />}
    </div>
  )
}

export default LibraryFilters
