import classNames from 'classnames'
import Textarea from 'react-expanding-textarea'
/* eslint-disable-next-line */
export interface HeliosTextareaProps {
  valid?: boolean
  disabled?: boolean
  className: string
  children?: React.ReactNode
  value: string
  setValue: (value: string) => void
  rows?: number
}

const textarea = {
  base: 'focus:outline-none ring ring-neptune font-poppins-medium pl-[16px] placeholder-moon pr-1 rounded-lg text-base text-moon-darkest w-full py-2 resize-none bg-moon-lightest leading-[24px] mt-4 mb-2',
  // active:
  //   'focus:border-purple-400 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:shadow-outline-gray focus:shadow-outline-purple',
  // disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
  // valid:
  //   'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:shadow-outline-green dark:focus:shadow-outline-green',
  // invalid:
  //   'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:shadow-outline-red dark:focus:shadow-outline-red',
}

export function TextArea(props: HeliosTextareaProps) {
  const { valid, disabled, className, children, setValue, rows, ...other } =
    props

  const baseStyle = textarea.base

  function hasValidation(valid: boolean | undefined) {
    return valid !== undefined
  }

  // function validationStyle(valid: boolean | undefined): string {
  //   if (hasValidation(valid)) {
  //     return valid ? validStyle : invalidStyle
  //   }
  //   return ''
  // }

  const cls = classNames(
    baseStyle,
    // don't apply activeStyle if has valid or disabled
    // !hasValidation(valid) && !disabled && activeStyle,
    // don't apply disabledStyle if has valid
    // !hasValidation(valid) && disabled && disabledStyle,
    // validationStyle(valid),
    className
  )

  return (
    <Textarea
      //@ts-ignore
      onChange={setValue}
      className={cls}
      disabled={disabled}
      rows={rows}
      {...other}
    >
      {children}
    </Textarea>
  )
}

export default TextArea
