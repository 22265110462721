'use client'

import { useOuterClick, useScrollTo } from '@codeverse/hooks'
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

interface KidScriptChangeActionButtonProps {
  handleDelete: any
  disabled?: boolean
  handleEdit: any
}

const KidScriptChangeActionButton: React.FC<
  KidScriptChangeActionButtonProps
> = ({ handleDelete, disabled, handleEdit }) => {
  // const {
  //   setMissionEditorDisplayType,
  //   setCurrentStep,
  //   setMissionStepToDelete,
  // } = useGuidedMissions()
  // const { openUI } = useUIState()
  const [showActions, setShowActions] = useState(false)
  const [scrollToRef, setShouldScrollTo] = useScrollTo()
  useOuterClick(scrollToRef, () => setShowActions(false))

  useEffect(() => {
    if (showActions && scrollToRef) {
      //@ts-ignore
      setShouldScrollTo(true)
    }
  }, [showActions, scrollToRef])

  const handleClick = () => {
    if (!disabled) {
      setShowActions(true)
    }
  }

  const iconClassnames = classNames(
    'bg-neptune rounded-full flex h-8 w-8 items-center justify-center',
    {
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }
  )

  return (
    <div className="">
      <div className="relative flex items-center justify-center">
        <div className={iconClassnames} onClick={() => handleClick()}>
          <FontAwesomeIcon icon={faEllipsis} />
        </div>

        {showActions && (
          <div
            //@ts-ignore
            ref={scrollToRef}
            className="z-2000 absolute right-8 top-1 w-[209px] rounded-xl bg-white p-2"
          >
            <div
              className="font-poppins-medium text-moon hover:text-moon-darkest hover:bg-moon-lightest mb-2 cursor-pointer rounded-lg px-2 py-2 text-lg"
              onClick={() => {
                setShowActions(false)
                handleEdit()
              }}
            >
              Edit
            </div>
            <div
              className="font-poppins-medium text-moon hover:text-moon-darkest hover:bg-moon-lightest cursor-pointer rounded-lg px-2 py-2 text-lg"
              onClick={() => {
                setShowActions(false)
                handleDelete()
              }}
            >
              Delete change
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default KidScriptChangeActionButton
