import React, { useEffect } from 'react'
import { Control, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { MissionStepFormValues } from './types'
import useMissionStore from '../../../../Stores/useMissionStore'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'

const useMissionStepValues = ({
  control,
  watch,
  getValues,
  initialCurrentStep,
}: {
  control: Control<MissionStepFormValues>
  watch: UseFormWatch<MissionStepFormValues>
  getValues: UseFormGetValues<MissionStepFormValues>
  initialCurrentStep: any
}) => {
  const { currentStep, setCurrentStep } = useMissionStore()
  const { kidScriptSetup } = useRuntimeStore()

  useEffect(() => {
    if (kidScriptSetup) {
      const subscription = watch((value) => {
        // setGuidedMissionsCurrentStep(value)
        if (value && kidScriptSetup) {
          setCurrentStep({
            ...value,
          })
        }
      })
      return () => {
        //@ts-ignore
        subscription.unsubscribe()
        // setCurrentStep(null)
      }
    }
  }, [watch, kidScriptSetup])

  // useEffect(() => {
  //   if (kidScriptSetup && !currentStep) {
  //     setCurrentStep({
  //       ...getValues(),
  //     })
  //   }
  // }, [kidScriptSetup])
}

export default useMissionStepValues
