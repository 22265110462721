import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { MissionStepFormValues } from '../types'
import { Select } from '@codeverse/helios/select'
import Header from './Header'

interface BoilerplateProps {
  control: Control<MissionStepFormValues>
  type: string
  setValue: UseFormSetValue<MissionStepFormValues>
}

type BoilerplateOption = {
  label: BoilerplateLabelType
  value: BoilerplateValueType
}

type BoilerplateValueType = 'code' | 'info' | 'quiz'
type BoilerplateLabelType = 'Code' | 'Info' | 'Quiz'

type BoilerplateOptions = Array<BoilerplateOption>

export const boilerplateOptions: BoilerplateOptions = [
  { label: 'Code', value: 'code' },
  { label: 'Info', value: 'info' },
  { label: 'Quiz', value: 'quiz' },
]

const Boilerplate: React.FC<BoilerplateProps> = ({
  control,
  type,
  setValue,
}) => {
  return (
    <div className="mb-6">
      <Header label="Boilerplate" description="Type of step" />
      <Controller
        name="boilerplate"
        control={control}
        render={({ field }) => (
          <Select
            context={'light'}
            size="large"
            defaultVal={boilerplateOptions?.find(
              (option) => field.value === option.value
            )}
            placeholder="Select one..."
            onChange={(option) => {
              if (option.value === 'quiz') {
                setValue('display', 'canvas')
              }
              field.onChange(option.value)
            }}
            options={boilerplateOptions}
            className=""
            ref={field.ref}
          />
        )}
      />
    </div>
  )
}

export default Boilerplate
