import classNames from 'classnames'
import MarkdownToJsx from 'markdown-to-jsx'
import React from 'react'
import KidScriptCode from './KidScriptCode'
import RunButton from './RunButton'
import KidScriptCodeInline from './KidScriptCodeInline'

interface MarkdownProps {
  children: any
}

const Image = (props: any) => {
  return (
    <div className="flex items-center justify-center">
      <img {...props} className="max-h-[600px] max-w-[500px]" alt={props.alt} />
    </div>
  )
}

const Tip = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative my-4 flex flex-row overflow-hidden rounded-xl bg-[#8D98C6] bg-opacity-10 shadow-xl">
      <div className="bg-sun-dark h-auto w-12">
        <div className="flex h-full flex-grow items-center justify-center">
          <div className="font-jakarta-sans mx-4 -rotate-90 transform py-1 text-center text-base font-bold text-white">
            TIP
          </div>
        </div>
      </div>
      <div className="font-jakarta-sans text-moon-darkest p-3 font-medium">
        {children}
      </div>

      {/* <div
      className="bg-moon-light z-2000 absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
      onClick={(e) => {
        e.preventDefault()
        console.log('missionStepTipsToCreate', missionStepTipsToCreate)
        console.log('index', index)
        const tempMissionStepsToCreate = [...missionStepTipsToCreate]
        tempMissionStepsToCreate.splice(index, 1)
        setMissionStepTipsToCreate(tempMissionStepsToCreate)
      }}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div> */}
    </div>
  )
}

const XY = ({}) => {
  return (
    <div
      className={classNames(
        'bg-moon-darkest font-jakarta-sans mx-.5 my-2 inline-block rounded-[8px] px-2 py-1 text-base'
      )}
    >
      <span style={{ color: '#FFF', opacity: '.5' }}>(</span>
      <span style={{ color: '#79f5c5' }}>X</span>
      <span style={{ color: '#FFF' }}>,</span>
      :&nbsp;
      <span style={{ color: '#79f5c5' }}>Y</span>
      <span style={{ color: '#FFF', opacity: '.5' }}>)</span>
    </div>
  )
}

const NonKidScriptCode = ({ children, color, small }) => {
  return (
    <div
      className={classNames(
        'bg-moon-darkest font-jakarta-sans mx-0.5 inline-block rounded-[8px] px-2 py-[1px] text-base'
      )}
      style={{
        color: color || '#fff',
      }}
    >
      {children}
    </div>
  )
}

const Syntax = ({ children, color, small }) => {
  return (
    <div className="inline-flex flex-shrink-0">
      <span
        className={classNames(
          'bg-moon-darkest font-roboto-mono mx-0.5 inline-flex flex-shrink-0 rounded-[8px] px-2 py-[1px] text-base'
        )}
        style={{
          color: color || '#fff',
        }}
      >
        {children}
      </span>
    </div>
  )
}

const defaultComponents = {
  code: {
    component: KidScriptCode,
  },
  KidScript: {
    component: KidScriptCode,
  },
  KidScriptInline: {
    component: KidScriptCodeInline,
  },
  NonKidScriptCode: {
    component: NonKidScriptCode,
  },
  Tip: {
    component: Tip,
  },
  Syntax: {
    component: Syntax,
  },
  RunButton: {
    component: () => <RunButton size="xs" />,
  },
  XY: {
    component: XY,
  },
  image: {
    component: Image,
  },
}

const PTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <p className="font-jakarta-sans text-moon-darkest text-base font-medium">
      {children}
    </p>
  )
}
const Span = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className="font-jakarta-sans text-moon-darkest text-base font-medium">
      {children}
    </span>
  )
}

const MarkdownRenderOptions = {
  overrides: {
    ...defaultComponents,
    p: {
      component: PTag,
    },
    span: {
      component: Span,
    },
    h1: {
      component: ({ children }: { children: React.ReactNode }) => (
        <h1 className="text-moon-darkest !mb-0 font-bold">{children}</h1>
      ),
    },
    h2: {
      component: ({ children }: { children: React.ReactNode }) => (
        <h2 className="text-moon-darkest !mb-0.5 font-semibold">{children}</h2>
      ),
    },
    h3: {
      component: ({ children }: { children: React.ReactNode }) => (
        <h3 className="text-moon-darkest !mb-0 pt-0.5 font-semibold">
          {children}
        </h3>
      ),
    },
    h4: {
      component: ({ children }: { children: React.ReactNode }) => (
        <h4 className="text-moon-darkest !mb-0 font-semibold">{children}</h4>
      ),
    },
  },
}

const Markdown: React.FC<MarkdownProps> = ({ children }) => {
  const markdownClassnames = classNames(
    'markdown-preview select-none font-jakarta-sans font-medium text-base',
    '!text-moon-darkest'
  )

  if (!children) return
  return (
    <MarkdownToJsx
      className={markdownClassnames}
      options={MarkdownRenderOptions}
    >
      {children}
    </MarkdownToJsx>
  )
}

export default Markdown
