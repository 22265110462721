import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import MissionStepTipModal from './MissionStepTipModal'
import Portal from 'apps/studio/components/Shared/Portal'
import MissionStepTip from '../../../../Missions/MissionSteps/MissionStepTip'

interface NewMissionStepTipsProps {}

const NewMissionStepTips: React.FC<NewMissionStepTipsProps> = () => {
  // const { state: GuidedMissionState, setMissionStepTipsToCreate } =
  //   useGuidedMissions()
  // const { missionStepTipsToCreate } = GuidedMissionState
  // const { openUI } = useUIState()
  const { missionStepTipsToCreate, setMissionStepTipsToCreate } =
    useMissionBuilderStore()
  const [currentStepTipIndex, setCurrentStepTipIndex] = useState(0)

  const inputClassname = classNames('!py-8')

  const MissionStepTips = useMemo(() => {
    const stepTips = missionStepTipsToCreate as Array<any>
    return stepTips?.map((missionStepTip: any, index: number) => {
      return <MissionStepTip index={index} content={missionStepTip.content} />
    })
  }, [missionStepTipsToCreate])

  return (
    <div className="">
      {missionStepTipsToCreate?.length > 0 && (
        <div className="mb-18">{MissionStepTips}</div>
      )}
      {/* {missionStepTipsToCreate?.length > 0 && (
        <Button
          context="nebula"
          size="small"
          onClick={(e) => {
            e.preventDefault()
            setMissionStepTipsToCreate([])
          }}
        >
          Remove Mission Step Tip
        </Button>
      )} */}
      <Portal idToSearch="MissionStepTipModalDiv">
        <MissionStepTipModal
          currentStepTipIndex={currentStepTipIndex}
          tempMissionStepTips={missionStepTipsToCreate}
          setTempMissionStepTips={setMissionStepTipsToCreate}
          type="new"
        />
      </Portal>
    </div>
  )
}

export default NewMissionStepTips
