import { gql } from 'graphql-request'

export const missionInsertablesQuery = (missionId: string) => {
  return gql`
  {
    missions(
      ids: [\"${missionId}\"]
      includeUnpublished: true
      includeGuideMissions: true
    ) {
      missionSteps(deleted: false) {
        position
        missionStepInsertables(deleted: false) {
          id
          lineAddress
          kidscript
          object
          position
          x
          y
          display
        }
      }
    }
  }
`
}
