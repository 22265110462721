import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type TabState = 'Published' | 'Achievements' | 'Starred'

interface UIStore {
  archiveProjectModal: boolean
  setArchiveProjectModal: (archiveProjectModal: boolean) => void
  createNewMenu: boolean
  badgeModal: boolean
  kidscriptEditModal: boolean
  missionStepTipModal: boolean
  missionStepDeleteConfirmationModal: boolean
  missionStepInsertableKidScript: boolean
  missionStepInsertableCoordinates: boolean
  toolboxLibraryFilter: boolean
  renameProjectModal: boolean
  friendsModal: boolean
  profileModal: boolean
  avatarModal: boolean
  publishShareModal: boolean
  playProjectModal: boolean
  sharePublishTab:
    | 'publish'
    | 'share'
    | 'submit'
    | 'game_submitted'
    | 'requested_changes'
    | 'confirm_app_delete'
    | 'purchase_launch_key'
    | 'cancel_submission'
  setBadgeModal: (badgeModal: boolean) => void
  setCreateNewMenu: (createNewMenu: boolean) => void
  setKidscriptEditModal: (kidscriptEditModal: boolean) => void
  setMissionStepTipModal: (missionStepTipModal: boolean) => void
  setMissionStepDeleteConfirmationModal: (
    missionStepDeleteConfirmationModal: boolean
  ) => void
  setMissionStepInsertableKidScript: (
    missionStepInsertableKidScript: boolean
  ) => void
  setMissionStepInsertableCoordinates: (
    missionStepInsertableCoordinates: boolean
  ) => void
  setToolboxLibraryFilter: (toolboxLibraryFilter: boolean) => void
  setRenameProjectModal: (renameProjectModal: boolean) => void
  setFriendsModal: (friendsModal: boolean) => void
  setProfileModal: (profileModal: boolean) => void
  setAvatarModal: (avatarModal: boolean) => void
  showMissionCompleteModal: boolean
  setShowMissionCompleteModal: (showMissionCompleteModal: boolean) => void
  setSharePublishTab: (
    sharePublishTab:
      | 'publish'
      | 'share'
      | 'submit'
      | 'game_submitted'
      | 'requested_changes'
      | 'confirm_app_delete'
      | 'purchase_launch_key'
      | 'cancel_submission'
  ) => void
  setPlayProjectModal: (playProjectModal: boolean) => void
  setPublishShareModal: (publishShareModal: boolean) => void
  setLearningPathModal: (learningPathModal: boolean) => void
  learningPathModal: boolean
  badgeDetailModal: boolean
  setBadgeDetailModal: (badgeDetailModal: boolean) => void
  newProjectTemplateModal: boolean
  setNewProjectTemplateModal: (newProjectTemplateModal: boolean) => void
  profileTabState: TabState
  setProfileTabState: (profileTabState: TabState) => void
}

const useUIStore = create<UIStore>()(
  devtools(
    (set) => ({
      createNewMenu: false,
      badgeModal: false,
      kidscriptEditModal: false,
      missionStepInsertableCoordinates: false,
      missionStepTipModal: false,
      missionStepDeleteConfirmationModal: false,
      missionStepInsertableKidScript: false,
      toolboxLibraryFilter: false,
      renameProjectModal: false,
      friendsModal: false,
      profileModal: false,
      avatarModal: false,
      publishShareModal: false,
      sharePublishTab: 'publish',
      learningPathModal: false,
      badgeDetailModal: false,
      newProjectTemplateModal: false,
      profileTabState: 'Published',
      playProjectModal: false,
      setNewProjectTemplateModal: (newProjectTemplateModal: boolean) =>
        set((state) => ({
          newProjectTemplateModal: newProjectTemplateModal,
        })),
      setBadgeDetailModal: (badgeDetailModal: boolean) =>
        set((state) => ({
          badgeDetailModal: badgeDetailModal,
        })),
      setLearningPathModal: (learningPathModal: boolean) =>
        set((state) => ({
          learningPathModal: learningPathModal,
        })),
      setBadgeModal: (badgeModal: boolean) =>
        set((state) => ({
          badgeModal: badgeModal,
        })),
      setMissionStepInsertableCoordinates: (missionStepInsertableCoordinates) =>
        set((state) => ({
          missionStepInsertableCoordinates: missionStepInsertableCoordinates,
        })),
      setKidscriptEditModal: (kidscriptEditModal) =>
        set((state) => ({ kidscriptEditModal: kidscriptEditModal })),
      setMissionStepTipModal: (missionStepTipModal) =>
        set((state) => ({ missionStepTipModal: missionStepTipModal })),
      setMissionStepDeleteConfirmationModal: (
        missionStepDeleteConfirmationModal
      ) =>
        set((state) => ({
          missionStepDeleteConfirmationModal:
            missionStepDeleteConfirmationModal,
        })),
      setMissionStepInsertableKidScript: (missionStepInsertableKidScript) =>
        set((state) => ({
          missionStepInsertableKidScript: missionStepInsertableKidScript,
        })),
      setToolboxLibraryFilter: (toolboxLibraryFilter) =>
        set((state) => ({ toolboxLibraryFilter: toolboxLibraryFilter })),
      setCreateNewMenu: (createNewMenu) => set((state) => ({ createNewMenu })),
      setRenameProjectModal: (renameProjectModal) =>
        set((state) => ({ renameProjectModal: renameProjectModal })),
      setFriendsModal: (friendsModal) =>
        set((state) => ({ friendsModal: friendsModal })),
      setProfileModal: (profileModal) =>
        set((state) => ({ profileModal: profileModal })),
      setAvatarModal: (avatarModal) =>
        set((state) => ({ avatarModal: avatarModal })),
      showMissionCompleteModal: false,
      setShowMissionCompleteModal: (showMissionCompleteModal) =>
        set((state) => ({
          showMissionCompleteModal: showMissionCompleteModal,
        })),
      setSharePublishTab: (sharePublishTab) =>
        set((state) => ({ sharePublishTab: sharePublishTab })),
      setPublishShareModal: (publishShareModal) =>
        set((state) => ({ publishShareModal: publishShareModal })),
      setProfileTabState: (profileTabState) =>
        set((state) => ({ profileTabState: profileTabState })),
      setPlayProjectModal: (playProjectModal) =>
        set((state) => ({ playProjectModal: playProjectModal })),
      archiveProjectModal: false,
      setArchiveProjectModal: (archiveProjectModal: boolean) =>
        set((state) => ({ archiveProjectModal: archiveProjectModal })),
    }),
    {
      name: 'UI Store',
    }
  )
)

export default useUIStore
