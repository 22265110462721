'use client'

import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import Input from '@codeverse/helios/input'
import classNames from 'classnames'
import { inputClassname } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/MissionStepForm'

interface SidebarHeaderFieldProps {
  control: Control<MissionStepFormValues>
}

const SidebarHeaderField: React.FC<SidebarHeaderFieldProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header
        label="Sidebar Header"
        description="Header to display in the sidebar"
      />

      <Controller
        name="side_bar_header"
        control={control}
        render={({ field }) => (
          <Input
            inputClassName={classNames(inputClassname, '!bg-moon-lightest')}
            {...field}
          />
        )}
      />
    </div>
  )
}

export default SidebarHeaderField
