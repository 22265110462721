'use client'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Control,
  UseFormWatch,
  UseFormGetValues,
  UseFormSetValue,
  Controller,
} from 'react-hook-form'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import DocumentField, { DocumentOption } from './Form/DocumentField'
import Display, { DisplayOption } from './Form/Display'
import useMissionStore from '../../../../Stores/useMissionStore'

import SuccessHeader from './Form/StepHeaderField'
import Input from '@codeverse/helios/input'
import ImageField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/ImageField'
import ContentField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/ContentField'
import BodyField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/BodyField'
import DetailField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/DetailField'
import SidebarHeaderField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/SidebarHeaderField'
import AudioField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/AudioField'
import VideoField from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/VideoField'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import KidScriptChanges from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/KidScriptChanges/KidScriptChanges'
import MissionInsertablesHeader from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/MissionInsertables/MissionInsertablesHeader'
import EditMissionInsertables from './Form/MissionInsertables/EditMissionInsertables'
import MissionStepTips from './Form/MissionStepTips/MissionStepTips'
import Runnable from './Form/Runnable'
import Portal from 'apps/studio/components/Shared/Portal'
import Modal from '@codeverse/helios/modal'
import KidScriptEditModal from './Form/KidScriptChanges/KidScriptEditModal'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import LibraryFilters from './Form/LibraryFilter/LibraryFilters'
import NewMissionInsertables from './Form/MissionInsertables/NewMissionInsertables'
import Boilerplate from './Form/BoilerplateField'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'
import { LineAddress } from '@kidscript/editor/dist/src/types/LineAddress'
import { StepSymbolAddress } from '@kidscript/editor/dist/src/types/StepSymbolAddress'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStepActions from './useMissionStepActions'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import MissionStepInsertableCoordiantes from './Form/MissionInsertables/MissionStepInsertableCoordiantes'
import MissionBuilderToolBar from '../MissionBuilderToolBar'
import MissionBuilderHeader from './MissionBuilderHeader'
import RunAutomatically from './Form/RunAutomatically'
import LibraryFilterPortal from './LibraryFilters/LibraryFilterPortal'
import LibraryFilterModal from './LibraryFilters/LibraryFilterModal'
import ValidationTarget from './Form/ValidationTarget'
import EditorFields from './Form/EditorFields'

type MissionStepFormProps = {
  control: Control<MissionStepFormValues>
  watch: UseFormWatch<MissionStepFormValues>
  getValues: UseFormGetValues<MissionStepFormValues>
  setValue: UseFormSetValue<MissionStepFormValues>
  type: 'edit' | 'new'
  // currentStep: any
}

export const inputClassname = '!py-8'
export const headerClassnames = classNames(
  'text-16 leading-24 !mb-0 text-white'
)
const MissionStepForm: React.FC<MissionStepFormProps> = ({
  control,
  watch,
  getValues,
  setValue,
  type,
  // currentStep,
}) => {
  const missionSteps = []
  const { currentStep, setCurrentStep, setInitialReplayMissionSteps } =
    useMissionStore()
  const { replayMissionStep } = useMissionStepActions()
  const {
    showMissionEditor,
    missionInsertableToSave,
    setMissionInsertableToSave,
    setToolboxMissionStepInsertable,
    setCurrentPickedLineAddress,
    setPickLine,
    currentLibraryFilterObject,
  } = useMissionBuilderStore()
  const { toolboxLibraryFilter, showToolbox, setShowToolbox } =
    useRuntimeStore()
  const { setMissionStepInsertableCoordinates } = useUIStore()
  const isCode = currentStep?.boilerplate === 'code'
  const isInfo = currentStep?.boilerplate === 'info'
  const isQuiz = currentStep?.boilerplate === 'quiz'

  const mainEditorRef = useMainEditorRef()

  useEffect(() => {
    if (currentStep?.boilerplate === 'quiz') {
      // setValue(
      //   'display',
      //   { label: 'Canvas', value: 'canvas' },
      //   { shouldDirty: true }
      // )
    }
  }, [currentStep?.boilerplate])

  // const stepDependencies = useMemo(() => {
  //   if (missionEditorValidatorRef.current && currentStep) {
  //     try {
  //       const stepDeps = missionEditorValidatorRef.current.getStepDependencies(
  //         currentStep.position
  //       )
  //       return stepDeps
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }
  //   return []
  // }, [missionSteps, currentStep, missionEditorValidatorRef])

  // useEffect(() => {
  //   replayMissionSteps(currentStep, true)
  // }, [currentStep?.boilerplate])

  const setEditorValues = useCallback(() => {
    return new Promise((resolve) => {
      if (!mainEditorRef) return
      const values = getValues()
      const lineAddress =
        mainEditorRef.dynamicLineContent.getFirstLineAddress() as LineAddress
      const symbolAddress =
        mainEditorRef.dynamicSymbolContent.getFirstSymbolAddress() as StepSymbolAddress
      const highlight = mainEditorRef.highlight.getOptions()
      const editable_symbols = mainEditorRef.editableSymbols.getOptions()
      const type_over = mainEditorRef.typeOver.getOptions()
      setValue('line_address', lineAddress, { shouldDirty: true })
      setValue('symbol_address', symbolAddress, { shouldDirty: true })
      setValue('highlight', highlight, { shouldDirty: true })
      setValue('editable_symbols', editable_symbols, { shouldDirty: true })
      setValue('type_over', type_over, { shouldDirty: true })
      return resolve(null)
    })
  }, [mainEditorRef])

  // useEffect(() => {
  //   if (showMissionEditor && mainEditorRef) {
  //     setEditorValues().then(() => {
  //       mainEditorRef.reset()
  //       replayMissionStep(getValues(), true)
  //     })
  //   }
  // }, [showMissionEditor])

  // useEffect(() => {
  //   replayMissionSteps(getValues(), true)
  // }, [library])

  const handleCancel = () => {
    setShowToolbox(false)
    setToolboxMissionStepInsertable(false)
    setMissionInsertableToSave(null)
    mainEditorRef?.reset()
  }

  const handleCleanUp = () => {
    setInitialReplayMissionSteps(false)
    setCurrentStep(null)
  }

  useEffect(() => {
    return () => handleCleanUp()
  }, [])

  return (
    <div>
      <Boilerplate setValue={setValue} type={type} control={control} />
      {currentStep?.boilerplate && (
        <>
          <DocumentField control={control} />
          <Display
            control={control}
            currentStep={currentStep}
            headerClassnames={headerClassnames}
            getValues={getValues}
            setEditorValues={setEditorValues}
          />

          <SuccessHeader
            control={control}
            setValue={setValue}
            isQuiz={isQuiz}
          />
          {isInfo && <VideoField control={control} />}
          {isInfo && <ImageField control={control} />}
          {isInfo && <AudioField control={control} />}
          {!isQuiz && <ContentField control={control} />}
          {!isQuiz && <BodyField control={control} />}
          {!isQuiz && <DetailField control={control} />}
          {!isQuiz && <SidebarHeaderField control={control} />}
          {!isQuiz && <hr className="text-moon-light mt-6" />}
          {!isQuiz && (
            <KidScriptChanges
              getValues={getValues}
              control={control}
              setValue={setValue}
              setEditorValues={setEditorValues}
            />
          )}
          {isCode && <hr className="text-moon-light mt-4" />}
          {isCode && (
            <div className="mt-4">
              <MissionInsertablesHeader />
              <p className="text-neptune-lightest mb-4 text-xs leading-6">
                Adds drag & droppable objects into the step to make imports easy
              </p>

              {type === 'edit' && (
                <EditMissionInsertables addKidScriptChange={() => {}} />
              )}
              {type === 'new' && (
                <NewMissionInsertables addKidScriptChange={() => {}} />
              )}
            </div>
          )}
          {!isQuiz && <hr className="text-moon-light mt-2" />}
          {(isCode || isInfo) && <MissionStepTips type={type} />}
          {!isQuiz && <hr className="text-moon-light my-4" />}
          {!isQuiz && <Runnable control={control} />}
          {!isQuiz && <hr className="text-moon-light my-4" />}
          {!isQuiz && <RunAutomatically control={control} />}
          {!isQuiz && <hr className="text-moon-light mt-2" />}
          {!isQuiz && <LibraryFilters watch={watch} getValues={getValues} />}
          <hr className="text-moon-light mt-2" />
          {!isQuiz && <ValidationTarget setValue={setValue} />}
          <hr className="text-moon-light my-4" />
          <EditorFields getValues={getValues} setValue={setValue} />
          {/* {!isQuiz && (
            <div className="mt-16">
              <div className="flex flex-row items-center">
                <h3 className="!mb-24 mt-16 text-white">
                  Automatic Progression
                </h3>
                <div className="ml-auto">
                  <Button
                    className="!pl-16 pr-24 shadow-none"
                    size="small"
                    context="nebula"
                    onClick={(e) => {
                      e.preventDefault()
                      setValue('validation_rules', '{}', {
                        shouldDirty: true,
                      })
                      setValue('validation_target', 'interpreter', {
                        shouldDirty: true,
                      })
                    }}
                  >
                    <FontAwesomeIcon size="lg" icon={faPlus} className="mr-8" />
                    Add
                  </Button>
                </div>
              </div>
              <p className="font-poppins-medium leading-24 text-14 mb-[20px] text-white">
                Write some JSON logic to automatically progress the user to the
                next step
              </p>

              {currentStep.validation_target?.length > 0 && (
                <div className="bg-neptune-darkest rounded-16 bg-opacity-20 p-16">
                  {currentStep.validation_target && (
                    <Select
                      className="mb-16"
                      context={'light'}
                      defaultVal={filterOptions.find(
                        (option) =>
                          currentStep.validation_target === option.value
                      )}
                      onChange={(option) =>
                        setValue('validation_target', option.value)
                      }
                      options={filterOptions}
                    />
                  )}
                  {currentStep.validation_target && (
                    <div>
                      <div className="bg-neptune-dark font-poppins-semibold px-18 rounded-t-12 w-2/4 bg-opacity-50 py-6">
                        Validation Rules
                      </div>
                      <CodeMirror
                        className="bg-moon-darkest"
                        value={
                          typeof currentStep.validation_rules ===
                          'string'
                            ? currentStep.validation_rules
                            : JSON.stringify(
                                currentStep.validation_rules,
                                null,
                                2
                              )
                        }
                        height="300px"
                        onChange={(value) => {
                          setValue('validation_rules', value)
                        }}
                        extensions={[json()]}
                        editable={true}
                        theme={githubDark}
                      />
                    </div>
                  )}
                  {currentStep.validation_target &&
                    currentStep.validation_target === 'interpreter' && (
                      <div className="mt-16">
                        <div className="bg-neptune-dark font-poppins-semibold px-18 rounded-t-12 w-2/4 bg-opacity-50 py-6">
                          Output
                        </div>
                        <CodeMirror
                          className="bg-moon-darkest"
                          value={
                            interpreterEvents?.length > 0
                              ? typeof interpreterEvents[0] === 'string'
                                ? interpreterEvents[0]
                                : JSON.stringify(interpreterEvents[0], null, 2)
                              : '{}'
                          }
                          height="300px"
                          extensions={[json()]}
                          editable={true}
                          theme={githubDark}
                        />
                      </div>
                    )}
                  {currentStep.validation_target &&
                    currentStep.validation_target === 'kidscript' && (
                      <div className="mt-16">
                        <div className="bg-neptune-dark font-poppins-semibold px-18 rounded-t-12 w-2/4 bg-opacity-50 py-6">
                          Output
                        </div>
                        <CodeMirror
                          className="bg-moon-darkest"
                          value={JSON.stringify(kidscriptCompileData, null, 2)}
                          height="300px"
                          extensions={[json()]}
                          editable={true}
                          theme={githubDark}
                        />
                      </div>
                    )}
                </div>
              )}
            </div>
          )} */}

          {/* --------------------- MODALS --------------------- */}
          {/* {!isQuiz && (
            <Controller
              name="kidScriptChanges"
              control={control}
              render={({ field }) => {
                if (!currentKidScriptChange) return
                const { documentIndex, changeIndex } = currentKidScriptChange
                return (
                  <KidScriptReactPortal>
                    <KidScriptEditModal
                      handleSetKidScriptChanges={handleSetKidScriptChanges}
                      documentIndex={documentIndex}
                      changeIndex={changeIndex}
                      documents={field?.value?.documents}
                    />
                  </KidScriptReactPortal>
                )
              }}
            />
          )} */}
          {!isQuiz && (
            <Controller
              name="library"
              control={control}
              render={({ field }) => {
                if (!toolboxLibraryFilter && !currentLibraryFilterObject) {
                  return <></>
                }
                return (
                  <Portal debug idToSearch="LibraryFilterEditorModal">
                    <LibraryFilterModal setValue={setValue} />
                  </Portal>
                )
              }}
            />
          )}
        </>
      )}
      {/* {pickLine && (
        <Portal idToSearch="missionStepInsertableHeader">
          <div className="grid h-full grid-cols-12">
            <div className="col-span-4 flex items-center pl-16">
              <FontAwesomeIcon
                className="text-neptune-dark mr-16"
                size="2x"
                icon={faArrowLeft}
              />
              <span className="font-poppins-semibold text-18 text-moon-darkest">
                Select a line
              </span>
            </div>
            <div className="col-span-8 flex items-center justify-end">
              <Button
                size="small"
                className="mr-8 !h-32 shadow-xl"
                context="secondary"
                onClick={() => {
                  //@ts-ignore
                  editorRef.display.clearLinePicker()
                  closeUI('pickLine')
                  setShowToolbox(false)
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Portal>
      )} */}
      <Portal idToSearch="MissionInsertableToolboxHeader">
        <div className="grid w-full grid-cols-2">
          <div className="flex items-center pl-2">
            <h4 className="mb-0 ml-2.5 flex-grow text-[19px]">
              {toolboxLibraryFilter
                ? 'Select Library Object'
                : 'Select Insertable'}
            </h4>
          </div>
          {showToolbox && (
            <div className="ml-auto mr-2 flex items-center">
              <Button
                disabled={!missionInsertableToSave}
                className="mr-2 !h-8 w-[102px]"
                onClick={(e) => {
                  setPickLine(true)
                  setShowToolbox(false)
                  mainEditorRef?.display.pickLine((e) => {
                    setCurrentPickedLineAddress(e)
                    setMissionStepInsertableCoordinates(true)
                  })
                }}
                context="earthDark"
                size="small"
              >
                Next
              </Button>
              <Button
                onClick={() => {
                  handleCancel()
                }}
                className="!h-8"
                context="secondary"
                size="small"
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Portal>
      <Portal idToSearch="MissionStepInsertableCoordiantes" debug>
        <MissionStepInsertableCoordiantes />
      </Portal>
      <Portal idToSearch="MissionBuilderToolbar">
        <MissionBuilderHeader
          getValues={getValues}
          setEditorValues={setEditorValues}
        />
      </Portal>
    </div>
  )
}

export default MissionStepForm
