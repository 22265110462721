'use client'

import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import CodeMirror from '@uiw/react-codemirror'
import { githubDark } from '@uiw/codemirror-theme-github'
import { json } from '@codemirror/lang-json'
import { MissionStepFormValues } from '../types'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import classNames from 'classnames'
import { useState } from 'react'
import Button from '@codeverse/helios/button'
import Header from './Header'
import useMissionStepActions from '../useMissionStepActions'

export default function Name({
  setValue,
  getValues,
}: {
  setValue: UseFormSetValue<MissionStepFormValues>
  getValues: UseFormGetValues<MissionStepFormValues>
}) {
  const { currentStep, setCurrentStep, setInitialReplayMissionSteps } =
    useMissionStore()
  const { replayMissionStep, getEditorOption } = useMissionStepActions()
  const [showFields, setShowFields] = useState(false)

  const isCode = currentStep?.boilerplate === 'code'
  const isInfo = currentStep?.boilerplate === 'info'
  const isQuiz = currentStep?.boilerplate === 'quiz'

  return (
    <div className="w-full">
      <Header
        label="Editor Fields"
        description="Highlight, typeover, editable_symbols"
      />
      <div className="ml-auto">
        <Button
          onClick={(e) => {
            e.preventDefault()
            setShowFields(!showFields)
          }}
          context="primaryFlat"
          size="small"
        >
          Show Editor Fields
        </Button>
      </div>
      <div
        className={classNames({
          hidden: !showFields,
          block: showFields,
        })}
      >
        {(isInfo || isCode) && (
          <div className="">
            <div className="flex flex-row pt-4">
              <h4 className="text-white">Type over</h4>

              <Button
                context="secondary"
                size="small"
                className="ml-auto"
                onClick={(e) => {
                  e.preventDefault()
                  setValue(
                    'type_over',
                    {
                      documents: [
                        {
                          document: 0,
                          symbols: [],
                        },
                        {
                          document: 1,
                          symbols: [],
                        },
                      ],
                    },
                    { shouldDirty: true }
                  )
                  replayMissionStep(getValues(), true)
                }}
              >
                Clear Typeover
              </Button>
            </div>
            <CodeMirror
              className="bg-moon-darkest mb-4"
              value={
                typeof currentStep.type_over === 'string'
                  ? currentStep.type_over
                  : JSON.stringify(currentStep.type_over, null, 2)
              }
              height="300px"
              onChange={(value) => {
                //@ts-ignore
                setValue('type_over', value)
              }}
              extensions={[json()]}
              editable={true}
              theme={githubDark}
            />
            {/* {currentStep.editable_symbols}
              {currentStep.highlight} */}
          </div>
        )}
        {(isInfo || isCode) && (
          <div className="">
            <h4 className="text-white">Editable Symbols</h4>
            <CodeMirror
              className="bg-moon-darkest mb-4"
              value={
                typeof currentStep.editable_symbols === 'string'
                  ? currentStep.editable_symbols
                  : JSON.stringify(currentStep.editable_symbols, null, 2)
              }
              height="300px"
              onChange={(value) => {
                //@ts-ignore
                setValue('editable_symbols', value)
              }}
              extensions={[json()]}
              editable={true}
              theme={githubDark}
            />
            {/* {currentStep.editable_symbols}
              {currentStep.highlight} */}
          </div>
        )}
        {(isInfo || isCode) && (
          <div className="">
            <h4 className="text-white">Highlight</h4>
            <CodeMirror
              className="bg-moon-darkest mb-4"
              value={
                typeof currentStep.highlight === 'string'
                  ? currentStep.highlight
                  : JSON.stringify(currentStep.highlight, null, 2)
              }
              height="300px"
              onChange={(value) => {
                //@ts-ignore
                setValue('highlight', value)
              }}
              extensions={[json()]}
              editable={true}
              theme={githubDark}
            />
            {/* {currentStep.editable_symbols}
              {currentStep.highlight} */}
          </div>
        )}
      </div>
    </div>
  )
}
