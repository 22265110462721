import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface UIStore {
  question: string
  answer: string
  answer_a: string
  answer_b: string
  answer_c: string
  answer_d: string
  setQuestion: (question: string) => void
  setAnswer: (answer: string) => void
  setAnswerA: (answer_a: string) => void
  setAnswerB: (answer_b: string) => void
  setAnswerC: (answer_c: string) => void
  setAnswerD: (answer_d: string) => void
}

const useUIStore = create<UIStore>()(
  devtools(
    (set) => ({
      question: '',
      answer: 'a',
      answer_a: '',
      answer_b: '',
      answer_c: '',
      answer_d: '',
      setQuestion: (question: string) =>
        set((state) => ({ question: question })),
      setAnswer: (answer: string) => set((state) => ({ answer: answer })),
      setAnswerA: (answer_a: string) =>
        set((state) => ({ answer_a: answer_a })),
      setAnswerB: (answer_b: string) =>
        set((state) => ({ answer_b: answer_b })),
      setAnswerC: (answer_c: string) =>
        set((state) => ({ answer_c: answer_c })),
      setAnswerD: (answer_d: string) =>
        set((state) => ({ answer_d: answer_d })),
    }),
    {
      name: 'Quiz Store',
    }
  )
)

export default useUIStore
