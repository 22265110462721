'use client'

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  children: React.ReactNode
  idToSearch: string
  debug?: boolean
}

const Portal: React.FC<PortalProps> = ({ children, idToSearch, debug }) => {
  const [domNode, setDomNode] = useState<any>(
    document.getElementById(idToSearch)
  )
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      setDomNode(document.getElementById(idToSearch))
    }
  }, [mounted])

  // useEffect(() => {
  //   let interval
  //   if (!domNode && mounted) {
  //     interval = setInterval(() => {
  //       setDomNode(document.getElementById(idToSearch))
  //     }, 10)
  //   }
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   }
  // }, [mounted])
  if (debug) {
    // console.log('domNode', domNode)
  }

  if (!domNode) {
    return null
  }

  if (debug) {
    // console.log('mounted', mounted)
    // console.log('mounted search', document.getElementById(idToSearch))
    // console.log('mounted dom node', idToSearch)
  }

  return mounted
    ? ReactDOM.createPortal(
        children,
        document.getElementById(idToSearch) || document.createElement('div')
      )
    : null
}

export default Portal
