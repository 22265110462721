'use client'

import React from 'react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

type Props = any

const FormCheckbox: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  defaultChecked,
  classNameOverride,
  disabled,
  iconSizeOverride = '2x',
  heightOverride = 'h-8 w-8',
  ...rest
}) => {
  const [checked, setChecked] = React.useState(value)
  const checkboxClassname = classNames(
    'bg-white shadow-sm border border-moon-dark border-opacity-5 flex items-center justify-center rounded-lg',
    heightOverride
  )

  // React.useEffect(() => {
  //   if (onChange) {
  //     onChange(checked)
  //   }
  // }, [checked])

  const defaultClassname = classNames(classNameOverride || 'ml-2', {
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled,
  })

  return (
    <div
      className={defaultClassname}
      onClick={() => {
        if (!disabled) {
          //TODO: REMOVE SETCHECKED
          setChecked(!checked)
          onChange(!checked)
        }
      }}
    >
      <input
        style={{ display: 'none' }}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        // onChange={(e) => {
        //   setChecked(e.target.checked)
        // }}
      />
      {checked ? (
        <div className={checkboxClassname}>
          <FontAwesomeIcon
            size={iconSizeOverride}
            className={
              disabled ? 'text-moon-darkest opacity-50' : 'text-moon-darkest'
            }
            icon={faTimes}
          />
        </div>
      ) : (
        <div className={checkboxClassname}></div>
      )}
    </div>
  )
}
export default FormCheckbox
