'use client'

import { Markdown } from '@codeverse/helios/markdown'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import React from 'react'
import MarkdownPreview from '../../../Studio/CodeReference/MarkdownPreview'

interface MissionStepTipProps {
  content: string
  index: number
}

const MissionStepTip: React.FC<MissionStepTipProps> = ({ content, index }) => {
  const { missionStepTipsToCreate, setMissionStepTipsToCreate } =
    useMissionBuilderStore()
  return (
    <div className="relative mt-4 flex flex-row overflow-hidden rounded-2xl bg-[#8D98C6] bg-opacity-10">
      <div className="bg-sun-dark h-auto w-12">
        <div className=" flex h-full flex-grow items-center justify-center">
          <div className="font-jakarta-sans mx-4 -rotate-90 transform py-1.5 text-center text-base font-bold tracking-wider text-white">
            TIP
          </div>
        </div>
      </div>
      <div className="text-moon-darkest font-jakarta-sans p-3 font-medium">
        <MarkdownPreview>{content}</MarkdownPreview>
      </div>

      {/* <div
        className="bg-moon-light z-2000 absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
        onClick={(e) => {
          e.preventDefault()
          console.log('missionStepTipsToCreate', missionStepTipsToCreate)
          console.log('index', index)
          const tempMissionStepsToCreate = [...missionStepTipsToCreate]
          tempMissionStepsToCreate.splice(index, 1)
          setMissionStepTipsToCreate(tempMissionStepsToCreate)
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div> */}
    </div>
  )
}

export default MissionStepTip
