import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { KidScriptChange } from '../../types'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import Button from '@codeverse/helios/button'
import Modal from '@codeverse/helios/modal'
import { TextArea } from '@codeverse/helios/textarea'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionStepActions from '../../useMissionStepActions'

interface KidScriptEditModalProps {
  documents: any
  documentIndex: number
  changeIndex: number
  handleSetKidScriptChanges: (
    documentNumber: number,
    changeIndex: number,
    kidscriptChange: KidScriptChange
  ) => void
}

const KidScriptEditModal: React.FC<KidScriptEditModalProps> = ({
  documents,
  documentIndex,
  changeIndex,
  handleSetKidScriptChanges,
}) => {
  const { currentKidScriptChange, setCurrentKidScriptChange } =
    useMissionBuilderStore()
  const { replayMissionStep } = useMissionStepActions()
  const { currentStep, setInitialReplayMissionSteps } = useMissionStore()
  const { kidscriptEditModal, setKidscriptEditModal } = useUIStore()
  // const { state: GuidedMissionState, setCurrentKidScriptChange } =
  //   useGuidedMissions()
  // const { state: UIState, closeUI } = useUIState()
  // const { kidscriptEditModal } = UIState
  // const { currentKidScriptChange } = GuidedMissionState
  const [kidScriptValue, setKidScriptValue] = useState(
    documents[documentIndex].changes[changeIndex].kidscript || ''
  )

  useEffect(() => {
    if (!kidscriptEditModal) {
      setCurrentKidScriptChange(null)
    }
  }, [kidscriptEditModal])

  // we need access to the controller somehow

  const inputClassnames = classNames(
    'focus:outline-none ring ring-neptune font-poppins-medium pl-[16px] placeholder-moon pr-1 rounded-lg text-base text-moon-darkest w-full py-2 resize-none bg-moon-lightest !min-h-56 leading-[24px]  mt-4 mb-2'
  )
  const kidscriptChange = documents[documentIndex].changes[changeIndex]

  // useEffect(() => {
  //   if (kidscriptEditModal) {
  //     setKidScriptValue(
  //       documents[documentIndex].changes[changeIndex]?.kidscript || ''
  //     )
  //   }
  // }, [kidscriptEditModal])

  const kidscriptHtml = useMemo(() => {
    //@ts-ignore
    return KidScript.parseBlock(kidScriptValue).toHTML()
  }, [kidScriptValue])

  if (!kidscriptChange) return null

  const handleSave = () => {
    handleSetKidScriptChanges(
      documentIndex,
      changeIndex,
      Object.assign(
        {},
        {
          kidscript: kidScriptValue,
          id: kidscriptChange.id,
          strategy:
            currentKidScriptChange.strategyToChange || kidscriptChange.strategy,
          locked: kidscriptChange.locked,
        },
        currentKidScriptChange.strategyToChange === 'insert' && {
          lineAddress: currentKidScriptChange.lineAddress,
        }
      )
    )
    setInitialReplayMissionSteps(false)
    setKidScriptValue('')
    setKidscriptEditModal(false)
    replayMissionStep(currentStep, true)
  }

  return (
    <Modal
      id="KidScriptEditModal"
      isOpen={kidscriptEditModal}
      onClose={() => setKidscriptEditModal(false)}
      width={543}
      height={'auto'}
      containerClassname="items-center"
      contentClassname="!rounded-4xl mx-4 z-[3000]"
    >
      <div className="max-h-[600px] overflow-y-scroll p-6">
        <div>
          <h4 className="!mb-1.5">KidScript</h4>
          <TextArea
            setValue={(e: any) => {
              setKidScriptValue(e.target.value)
            }}
            rows={3}
            value={kidScriptValue}
            className="max-h-[300px]"
          />
          <div className="bg-moon-darkest relative mb-4 flex-grow rounded-lg px-4 py-2">
            <pre
              className="kidscript-syntax overflow-x-scroll pb-2"
              dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
            />
          </div>
          {/* <MDEditor
            previewOptions={{
              components: {
                code: ({ inline, children = [], className, ...props }) => {
                  return <KidScriptCode>{children[0].toString()}</KidScriptCode>
                },
              },
            }}
            commands={[]}
            value={value}
            onChange={(value) => {
              setKidScriptValue(value)
            }}
          /> */}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button
            id="MissionBuilder_Save_KidScriptChange_KidScript"
            context="primary"
            size="regular"
            onClick={() => handleSave()}
          >
            Save
          </Button>
          <Button
            id="MissionBuilder_Cancel_KidScriptChange_KidScript"
            context="secondary"
            size="regular"
            onClick={() => setKidscriptEditModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default KidScriptEditModal
