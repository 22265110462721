import Button from '@codeverse/helios/button'
import { useOuterClick, useScrollTo } from '@codeverse/hooks'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import React, { useEffect, useState } from 'react'

interface MissionInsertablesHeaderProps {}

const MissionInsertablesHeader: React.FC<
  MissionInsertablesHeaderProps
> = () => {
  const { setMissionStepInsertableKidScript } = useUIStore()
  const [showActions, setShowActions] = useState(false)
  const [scrollToRef, setShouldScrollTo] = useScrollTo()
  const { setShowToolbox } = useRuntimeStore()
  const { setToolboxMissionStepInsertable } = useMissionBuilderStore()

  // const { setToolboxMissionStepInsertable, setShowToolbox } = useRuntime()

  // const { openUI } = useUIState()

  useOuterClick(scrollToRef, () => setShowActions(false))
  useEffect(() => {
    if (showActions && scrollToRef) {
      //@ts-ignore
      setShouldScrollTo(true)
    }
  }, [showActions, scrollToRef])

  const handleClick = () => {
    setShowActions(true)
  }

  return (
    <div className="flex flex-row items-center">
      <h4 className="text-16 leading-24 !mb-0 text-white">
        Mission Insertable
      </h4>
      <div className="relative ml-auto">
        <Button
          className="!pl-4 pr-6 shadow-none"
          size="small"
          context="nebula"
          onClick={(e) => {
            e.preventDefault()
            handleClick()
          }}
        >
          <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
          Add
        </Button>
        {showActions && (
          <div
            //@ts-ignore
            ref={scrollToRef}
            className="absolute right-0 top-[40px] z-[3000] w-[209px] rounded-xl bg-white p-2"
          >
            <div
              className="font-poppins-medium text-moon hover:text-moon-darkest hover:bg-moon-lightest mb-2 cursor-pointer rounded-lg px-2 py-2 text-lg"
              onClick={() => {
                setMissionStepInsertableKidScript(true)
                setShowActions(false)
              }}
            >
              Add KidScript
            </div>
            <div
              className="font-poppins-medium text-moon hover:text-moon-darkest hover:bg-moon-lightest cursor-pointer rounded-lg px-2 py-2 text-lg"
              onClick={() => {
                setShowActions(false)
                setToolboxMissionStepInsertable(true)
                setShowToolbox(true)
              }}
            >
              Add Object
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MissionInsertablesHeader
