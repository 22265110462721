import React from 'react'
import ReactDOM from 'react-dom'

interface MissionStepInsertableKidScriptPortalProps {
  children: React.ReactNode
}

const MissionStepInsertableKidScriptPortal: React.FC<
  MissionStepInsertableKidScriptPortalProps
> = ({ children }) => {
  const domNode = document.getElementById('MissionStepInsertableKidScriptModal')
  if (!domNode) return
  return ReactDOM.createPortal(children, domNode)
}

export default MissionStepInsertableKidScriptPortal
