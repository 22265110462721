'use client'

import Input from '@codeverse/helios/input'
import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface AudioFieldProps {
  control: Control<MissionStepFormValues>
}

const AudioField: React.FC<AudioFieldProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header label="Audio Link" description="Link to audio file to play" />
      <Controller
        name="audio_uri"
        control={control}
        render={({ field }) => (
          <Input className="!bg-moon-lightest" {...field} />
        )}
      />
    </div>
  )
}

export default AudioField
