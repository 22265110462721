'use client'
import React from 'react'
import FormCheckbox from './FormCheckbox'
import { Control, Controller } from 'react-hook-form'
import { MissionStepFormValues } from '../types'
import Header from './Header'

interface RunAutomaticallyProps {
  control: Control<MissionStepFormValues>
}

const RunAutomatically: React.FC<RunAutomaticallyProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header
        label="Run Automatically"
        description="Should the step run automatically when it is displayed"
      />
      <Controller
        name="run_automatically"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            // disabled={type === 'new'}
            classNameOverride="col-span-1"
            {...field}
          />
        )}
      />
    </div>
  )
}

export default RunAutomatically
