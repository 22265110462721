import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface MissionBuilderStore {
  showMissionEditor: boolean
  missionEditorDisplayType: 'viewMission' | 'editMissionStep'
  choosingInsertLine: boolean
  previewMissionMode: boolean
  setShowMissionEditor: (showMissionEditor: boolean) => void
  setMissionEditorDisplayType: (
    missionEditorDisplayType: 'viewMission' | 'editMissionStep'
  ) => void
  setChoosingInsertLine: (choosingInsertLine: boolean) => void
  setPreviewMissionMode: (previewMissionMode: boolean) => void
  setToolboxMissionStepInsertable: (missionStepInsertable: any) => void
  toolboxMissionStepInsertable: any
  missionInsertablesToUpdate: any
  setMissionInsertablesToUpdate: (missionInsertablesToUpdate: any) => void
  missionInsertablesToCreate: any
  setMissionInsertablesToCreate: (missionInsertablesToCreate: any) => void
  setCurrentKidScriptChange: (kidScriptChange: any) => void
  currentKidScriptChange: any
  setMissionStepTipsToCreate: (missionStepTipsToCreate: any) => void
  missionStepTipsToCreate: Array<any>
  setCurrentLibraryFilterObject: (libraryFilterObject: any) => void
  currentLibraryFilterObject: any
  libraryFilterObject: any
  setMissionInsertableToSave: (missionInsertableToSave: any) => void
  missionInsertableToSave: any
  setCurrentPickedLineAddress: (pickedLineAddress: any) => void
  currentPickedLineAddress: any
  pickLine: boolean
  setPickLine: (pickLine: boolean) => void
  resetMissionInsertablesToCreate: () => void
  addMissionInsertablesToCreate: (missionInsertablesToCreate: any) => void
  missionStepRunning: boolean
  setMissionStepRunning: (missionStepRunning: boolean) => void
}

const useMissionBuilderStore = create<MissionBuilderStore>()(
  devtools(
    (set) => ({
      pickLine: false,
      choosingInsertLine: false,
      currentKidScriptChange: null,
      missionInsertablesToUpdate: [],
      currentLibraryFilterObject: null,
      missionInsertablesToCreate: [],
      missionEditorDisplayType: 'viewMission',
      previewMissionMode: false,
      showMissionEditor: false,
      toolboxMissionStepInsertable: null,
      missionInsertableToSave: null,
      currentPickedLineAddress: null,
      setPickLine: (pickLine) => set((state) => ({ pickLine: pickLine })),
      setMissionStepTipsToCreate: (missionStepTipsToCreate) =>
        set((state) => ({ missionStepTipsToCreate: missionStepTipsToCreate })),
      missionStepTipsToCreate: [],
      setToolboxMissionStepInsertable: (missionStepInsertable) =>
        set((state) => ({
          toolboxMissionStepInsertable: missionStepInsertable,
        })),
      setShowMissionEditor: (showMissionEditor) =>
        set((state) => ({ showMissionEditor: showMissionEditor })),
      setMissionEditorDisplayType: (missionEditorDisplayType) =>
        set((state) => ({
          missionEditorDisplayType: missionEditorDisplayType,
        })),
      setChoosingInsertLine: (choosingInsertLine) =>
        set((state) => ({ choosingInsertLine: choosingInsertLine })),
      setPreviewMissionMode: (previewMissionMode) =>
        set((state) => ({ previewMissionMode: previewMissionMode })),
      setMissionInsertablesToUpdate: (missionInsertablesToUpdate) =>
        set((state) => ({
          missionInsertablesToUpdate: missionInsertablesToUpdate,
        })),
      setMissionInsertablesToCreate: (missionInsertablesToCreate) =>
        set((state) => {
          return {
            missionInsertablesToCreate: missionInsertablesToCreate,
          }
        }),
      addMissionInsertablesToCreate: (missionInsertablesToCreate) =>
        set((state) => {
          return {
            missionInsertablesToCreate: [
              ...state.missionInsertablesToCreate,
              missionInsertablesToCreate,
            ],
          }
        }),
      resetMissionInsertablesToCreate: () =>
        set((state) => ({ missionInsertablesToCreate: [] })),
      setCurrentKidScriptChange: (kidScriptChange) =>
        set((state) => ({ currentKidScriptChange: kidScriptChange })),
      setCurrentLibraryFilterObject: (libraryFilterObject) =>
        set((state) => ({
          libraryFilterObject: libraryFilterObject,
          currentLibraryFilterObject: libraryFilterObject,
        })),
      libraryFilterObject: null,
      setMissionInsertableToSave: (missionInsertableToSave) =>
        set((state) => ({ missionInsertableToSave: missionInsertableToSave })),
      setCurrentPickedLineAddress: (pickedLineAddress) =>
        set((state) => ({ currentPickedLineAddress: pickedLineAddress })),
      missionStepRunning: false,
      setMissionStepRunning: (missionStepRunning) =>
        set((state) => ({ missionStepRunning: missionStepRunning })),
    }),
    {
      name: 'Mission Builder Store',
    }
  )
)

export default useMissionBuilderStore
