import { MutableRefObject, createRef } from 'react'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface MissionStore {
  blockNextStep: boolean
  currentMission: any
  currentStep: any
  guidedMissionMode: boolean
  initialReplayMissionSteps: boolean
  interpreterEvents: any
  kidscriptCompileData: any
  missionStepPortalRef: MutableRefObject<HTMLDivElement | null>
  resetInterpreterEvents: () => void
  setBlockNextStep: (blockNextStep: boolean) => void
  setCurrentMission: (currentMission: any) => void
  setCurrentStep: (currentStep: any) => void
  setCurrentStepFormValue: any
  setGuidedMissionMode: (guidedMissionMode: boolean) => void
  setInitialReplayMissionSteps: (initialReplayMissionSteps: boolean) => void
  setInterpreterEvents: (interpreterEvents: any) => void
  setKidScriptCompileData: (kidscriptCompileData: any) => void
  setSetCurrentStepFormValue: (setCurrentStepFormValue: any) => void
  setShowMissionContent: (showMissionContent: boolean) => void
  setShowPortal: (showPortal: boolean) => void
  setSubmittedAnswer: (submittedAnswer: boolean) => void
  showMissionContent: boolean
  showPortal: boolean
  submittedAnswer: boolean
  missionAchievement: any
  setMissionAchievement: (missionAchievement: any) => void
}

const MissionStore = create<MissionStore>()(
  devtools(
    (set) => ({
      blockNextStep: false,
      currentMission: null,
      currentStep: null,
      guidedMissionMode: false,
      initialReplayMissionSteps: false,
      interpreterEvents: [],
      kidscriptCompileData: [],

      missionStepPortalRef:
        createRef<HTMLDivElement | null>() as MutableRefObject<HTMLDivElement | null>,
      resetInterpreterEvents: () =>
        set((state) => ({
          interpreterEvents: [],
        })),
      setBlockNextStep: (blockNextStep: boolean) =>
        set((state) => ({
          blockNextStep: blockNextStep,
        })),
      setCurrentMission: (currentMission: any) =>
        set((state) => ({
          currentMission: currentMission,
        })),
      setCurrentStep: (currentStep) =>
        set((state) => ({
          currentStep: currentStep,
        })),
      setCurrentStepFormValue: null,
      setGuidedMissionMode: (guidedMissionMode: boolean) =>
        set((state) => ({
          guidedMissionMode: guidedMissionMode,
        })),
      setInitialReplayMissionSteps: (initialReplayMissionSteps: boolean) =>
        set((state) => ({
          initialReplayMissionSteps: initialReplayMissionSteps,
        })),
      setInterpreterEvents: (interpreterEvents: any) =>
        set((state) => ({
          interpreterEvents: interpreterEvents,
        })),
      setKidScriptCompileData: (kidscriptCompileData: any) =>
        set((state) => ({
          kidscriptCompileData: kidscriptCompileData,
        })),
      setSetCurrentStepFormValue: (setCurrentStepFormValue) =>
        set((state) => ({
          setCurrentStepFormValue: setCurrentStepFormValue,
        })),
      setShowMissionContent: (showMissionContent: boolean) =>
        set((state) => ({
          showMissionContent: showMissionContent,
        })),
      setShowPortal: (showPortal: boolean) =>
        set((state) => ({
          showPortal: showPortal,
        })),
      setSubmittedAnswer: (submittedAnswer: boolean) =>
        set((state) => ({
          submittedAnswer: submittedAnswer,
        })),
      showMissionContent: false,
      showPortal: false,
      submittedAnswer: false,
      missionAchievement: null, // Add this line
      setMissionAchievement: (
        missionAchievement: any // Add this block
      ) =>
        set((state) => ({
          missionAchievement: missionAchievement,
        })),
    }),
    {
      name: 'Mission Store',
    }
  )
)

export default MissionStore
