import capitalize from 'lodash/capitalize'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import Modal from '@codeverse/helios/modal'
import Button from '@codeverse/helios/button'

import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import MarkdownEditor from '../MarkdownPreview'
import dynamic from 'next/dynamic'
import {
  useCreateMissionStepTipMutation,
  useUpdateMissionStepTip,
} from 'apps/studio-shared/src/Data/Mission/MissionMutations'

interface MissionStepTipModalProps {
  tempMissionStepTips: Array<any>
  setTempMissionStepTips: any
  type: 'new' | 'edit'
  currentStepTipIndex: number | null
}

const MissionStepTipModal: React.FC<MissionStepTipModalProps> = ({
  tempMissionStepTips,
  type,
  setTempMissionStepTips,
  currentStepTipIndex,
}) => {
  const { missionStepTipModal, setMissionStepTipModal } = useUIStore()
  const { setMissionStepTipsToCreate } = useMissionBuilderStore()
  const { currentStep } = useMissionStore()
  const queryClient = useQueryClient()

  const [value, setValue] = useState('')

  const createMissionStepTipMutation = useCreateMissionStepTipMutation()
  const updateMissionStepTip = useUpdateMissionStepTip()

  useEffect(() => {
    if (currentStepTipIndex !== null && missionStepTipModal) {
      const content = tempMissionStepTips[currentStepTipIndex]?.content
      if (content) {
        setTimeout(() => {
          setValue(content)
        }, 200)
      } else {
        setValue('')
      }
    }
  }, [currentStepTipIndex, tempMissionStepTips, missionStepTipModal])

  const handleSave = () => {
    if (type === 'new' && typeof currentStepTipIndex === 'number') {
      const newTempMissionStepTips = [...tempMissionStepTips]
      const newTempMissionStepTip = {
        ...tempMissionStepTips[currentStepTipIndex],
        content: value,
      }
      // newTempMissionStepTips.splice(currentStepTipIndex, 1)
      newTempMissionStepTips.push(newTempMissionStepTip)
      setMissionStepTipsToCreate(newTempMissionStepTips)
      setTempMissionStepTips(newTempMissionStepTips)
      setValue('')
      setMissionStepTipModal(false)

      setMissionStepTipModal(false)
      return
    } else if (typeof currentStepTipIndex === 'number') {
      updateMissionStepTip.mutate(
        {
          missionStepTipId: tempMissionStepTips[currentStepTipIndex].id,
          data: {
            content: value,
          },
        },
        {
          onSuccess: () => {
            setValue('')
            queryClient.invalidateQueries({
              queryKey: ['missionStepTips'],
            })
            setMissionStepTipModal(false)
          },
        }
      )
    } else {
      createMissionStepTipMutation.mutate(
        {
          missionStepId: currentStep.id,
          content: value,
        },
        {
          onSuccess: () => {
            setValue('')
            queryClient.invalidateQueries({ queryKey: ['missionStepTips'] })
            setMissionStepTipModal(false)
          },
        }
      )
    }
  }

  return (
    <Modal
      id="MissionStepTipModal"
      isOpen={missionStepTipModal}
      onClose={() => setMissionStepTipModal(false)}
      width={543}
      height={'auto'}
      containerClassname="items-center"
      contentClassname="!rounded-3xl mx-4 z-3000"
    >
      <div className="p-7">
        <h4>{capitalize(type)} Mission Step Tip</h4>
        <div data-color-mode="light">
          <MarkdownEditor
            //@ts-ignore
            value={value}
            onChange={(e: any) => {
              setValue(e)
            }}
          />
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Button
            context="primary"
            onClick={() => {
              handleSave()
            }}
            size="regular"
          >
            Save
          </Button>
          <Button
            context="secondary"
            onClick={() => {
              setValue('')
              setMissionStepTipModal(false)
            }}
            size="regular"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MissionStepTipModal
