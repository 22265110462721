import { faImage, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

interface ImageFileUploadDropZoneProps {
  theme: 'light' | 'dark'
  value?: string
  handleReset?: () => void
}

const ImageFileUploadClassnames = {
  light: {
    mainContent: 'text-moon ml-3',
    fileType: 'font-poppins-medium text-base ml-auto text-moon-light',
  },
  dark: {
    mainContent: 'text-white ml-3',
    fileType: 'font-poppins-medium text-base ml-auto text-white',
  },
}

const ImageFileUploadDropZone: React.FC<ImageFileUploadDropZoneProps> = ({
  theme,
  value,
  handleReset,
}) => {
  const borderClassname = classNames('border-spacing-4 border-2 border-dashed')
  const selectedFileClassname = classNames(
    'rounded-xl flex cursor-pointer flex-row items-center p-3',
    borderClassname,
    {
      'border-earth-dark': theme === 'light',
      'border-white': theme === 'dark',
    }
  )
  const unselectedFileClassname = classNames(
    'rounded-xl flex cursor-pointer flex-row items-center p-3',
    borderClassname,
    {
      'border-white': theme === 'dark',
      'border-earth-dark': theme === 'light',
    }
  )
  const iconClassname = classNames({
    'text-white': theme === 'dark',
    'text-earth-dark': theme === 'light',
  })

  if (value) {
    return (
      <div className={selectedFileClassname}>
        <div>
          <div
            className="rounded-[8px]"
            style={{
              height: '74px',
              width: '49px',
              minWidth: '49px',
              backgroundImage: `url(${value})`,
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className={ImageFileUploadClassnames[theme].mainContent}>
          <span className="underline">Upload</span> or drop a file here
        </div>
        {typeof handleReset === 'function' && (
          <div
            className="bg-moon-light ml-auto flex h-8 w-8 items-center justify-center rounded-full"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleReset()
            }}
          >
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </div>
        )}
      </div>
    )
  }
  return (
    <div className={unselectedFileClassname}>
      <div className={iconClassname}>
        <FontAwesomeIcon icon={faImage} size="2x" />
      </div>
      <div className={ImageFileUploadClassnames[theme].mainContent}>
        <span className="underline">Upload</span> or drop a file here
      </div>
      <div className={ImageFileUploadClassnames[theme].fileType}>jpg/png</div>
    </div>
  )
}

export default ImageFileUploadDropZone
