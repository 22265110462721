//@ts-nocheck
'use client'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import kidscriptVersionFormatter from '../Utils/kidscriptVersionFormatter'
import { updateDocument } from 'apps/studio-shared/src/Data/User/UserFetch'
import { useParams, useRouter } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'
import { usePathname } from 'next/navigation'
import { useCallback } from 'react'
import { useMutateProject } from 'apps/studio-shared/src/Data/Projects/ProjectMutations'

const useKidScript = () => {
  const pathname = usePathname()
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const queryClient = useQueryClient()
  const { kidscriptRunning, setKidscriptRunning, documents } = useRuntimeStore()
  const router = useRouter()
  const params = useParams()
  const projectMutation = useMutateProject()

  const runKidScript = (
    documents?: any,
    ksVersion?: string,
    editor?: any,
    interpreter?: any
  ) => {
    const mainEditorRef = editor || editorRefs.main
    const interpreterRef = interpreter || interpreterRefs.main
    let documentsToRun = null
    let versionToRun = null
    if (!mainEditorRef && !interpreterRef) return

    if (!mainEditorRef) {
      //@ts-ignore
      // Use the documents passed
      documentsToRun = documents
      //@ts-ignore
      versionToRun = ksVersion
    } else {
      // Use the documents from the editor
      documentsToRun = mainEditorRef.getKidScript()
      //@ts-ignore
      versionToRun = KidScript.Version
    }

    try {
      const mainDocument = documents.find((doc) => doc.filename === '/main.ks')
      const headDocument = documents.find((doc) => doc.filename === '/head.ks')
      const ksVersions = kidscriptVersionFormatter(versionToRun)
      //@ts-ignore
      const instructions = KidScript.parseDocuments(
        [headDocument, mainDocument],
        `${ksVersions[0]}.${ksVersions[1]}.${ksVersions[2]}`
      ).toInstructionTree()
      if (instructions !== false) {
        const state = interpreterRef.getState()

        if (interpreterRef && interpreterRef.getState() !== 'READY') {
          interpreterRef.stop()
        }

        const promise = interpreterRef.execute(instructions)
        promise
          .then(
            (result) => {
              if (!interpreterRef.isStopped()) {
                setKidscriptRunning(true)
                // if (projectsMatch) {
                //   takeScreenshot()
                // }
              }
              // setRunnning(true)
              // dispatch(editorActions.disableEditMode());
            },
            (error) => {}
          )
          .catch((exception) => {})
      }
    } catch (e) {
      console.log('error:', e)
    }
  }

  const stopKidScript = () => {
    const interpreterRef = interpreterRefs.main
    if (interpreterRef.getState() !== 'READY') {
      setKidscriptRunning(false)
      interpreterRef.stop()
    }
  }

  const saveDocument = async (
    currentDocumentId: string,
    filename: string,
    kidscript: string,
    currentProjectId: string
  ) => {
    return await updateDocument(
      currentDocumentId,
      filename,
      kidscript,
      currentProjectId
    )
  }

  const handleMenuClick = async () => {
    if (documents[0].id === '1' && documents[1].id === '2')
      return router.push('/missions')

    await Promise.all([
      documents?.map(async (doc, index) => {
        const kidscript = editorRefs['main']?.getKidScript() || ''
        return await saveDocument(
          doc.id,
          doc.filename,
          doc.filename === '/main.ks' ? kidscript[1] : kidscript[0],
          params.id as string
        )
      }),
    ]).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['projectDocuments'],
      })

      router.push('/projects')
    })
  }

  const takeScreenshot = useCallback((projectId: string) => {
    setTimeout(() => {
      if (!projectId) return
      const canvas = document.querySelector('#stage canvas')
      const extra_canvas = document.createElement('canvas')
      extra_canvas.setAttribute('width', '1058px')
      extra_canvas.setAttribute('height', '1596px')
      const ctx = extra_canvas.getContext('2d')
      //@ts-ignore
      ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 1058, 1596)
      const dataURL = extra_canvas.toDataURL()
      projectMutation.mutate({
        projectId: projectId,
        data: {
          screenshot: dataURL,
        },
      })
      queryClient.invalidateQueries({
        queryKey: ['project', projectId],
      })
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      })
    }, 200)
  }, [])

  return {
    runKidScript,
    stopKidScript,
    saveDocument,
    handleMenuClick,
    takeScreenshot,
  }
}

export default useKidScript
