import classNames from 'classnames'
import React from 'react'

interface KidScriptCodeProps {
  children: string
  noMargin?: boolean
}

const KidScriptCode: React.FC<KidScriptCodeProps> = ({
  children,
  noMargin,
}) => {
  return (
    <pre
      className={classNames(
        'bg-moon-darkest font-roboto-mono kidscript-syntax inline-block overflow-x-scroll rounded-[16px] px-4 py-3',
        {
          'my-6': !noMargin,
          '!px-[8px] !py-1': noMargin,
        }
      )}
      dangerouslySetInnerHTML={{
        //@ts-ignore
        __html: KidScript.parseBlock(children || '').toHTML(),
      }}
    ></pre>
  )
}

export default KidScriptCode
