import React, { useMemo } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import { DragHandle } from '../MissionStep/Form/KidScriptChanges/DragHandle'
import { cn } from '@codeverse/cn'

interface DraggableTableRowProps {
  row: any
  oneRow: boolean
  disabled?: boolean
  rowClassName?: string
  activeId?: string | null
}

const DraggableTableRow: React.FC<DraggableTableRowProps> = ({
  row,
  oneRow,
  disabled,
  rowClassName,
  activeId,
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }

  const rowClassnames = classNames('w-full', rowClassName, {
    'opacity-0': row.original.id === activeId,
  })
  const { key, ...rowProps } = row.getRowProps()

  return (
    <tr
      className={rowClassnames}
      key={row.original.id}
      ref={setNodeRef}
      style={style}
      {...rowProps}
    >
      {row.cells.map((cell: any, i: any) => {
        const { key, ...cellProps } = cell.getCellProps()
        if (i === 0) {
          return (
            <td
              className="bg-neptune-darkest rounded-tl-4.5xl rounded-bl-4.5xl !bg-opacity-25 py-1 pl-2"
              {...cellProps}
              key={key}
            >
              <div className="">
                {disabled ? (
                  <DragHandle
                    className={classNames('col-span-2 inline-block pl-1 pr-1', {
                      // 'pointer-events-none opacity-0': oneRow,
                    })}
                  />
                ) : (
                  <DragHandle
                    className={classNames('col-span-2 inline-block pl-1 pr-1', {
                      // 'pointer-events-none opacity-0': oneRow,
                    })}
                    {...attributes}
                    {...listeners}
                  />
                )}
                <span className="col-span-10 inline-block text-white">
                  {cell.render('Cell')}
                </span>
              </div>
            </td>
          )
        }
        return (
          <td
            className={cn('bg-neptune-darkest !bg-opacity-25 py-1 text-white', {
              'rounded-tr-4.5xl rounded-br-4.5xl pl-2': i === 3,
            })}
            {...cellProps}
            key={key}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export default DraggableTableRow
