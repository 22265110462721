'use client'

import Cookies from 'js-cookie'
import { GraphQLClient } from 'graphql-request'

export default () => {
  //@ts-ignore
  const auth = JSON.parse(localStorage.getItem('auth'))
  const { accessToken } = auth
  const { currentUserId } = auth
  const graphqlClient = new GraphQLClient(
    `https://api.codeverse.com/graphql?access_token=${accessToken}&current_user_id=${currentUserId}`
  )
  return graphqlClient
}
