'use client'
import React, { useMemo } from 'react'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import useKidScript from 'apps/studio-shared/src/Components/Studio/Hooks/useKidScript'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionStepActions from './useMissionStepActions'
import { usePathname, useParams } from 'next/navigation'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { UseFormGetValues } from 'react-hook-form'
import { MissionStepFormValues } from './types'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'

interface MissionBuilderHeaderProps {
  setEditorValues: () => Promise<any>
  getValues: UseFormGetValues<MissionStepFormValues>
}

const MissionBuilderHeader: React.FC<MissionBuilderHeaderProps> = ({
  setEditorValues,
  getValues,
}) => {
  const params = useParams()
  const pathname = usePathname()
  const { editorRefs } = useRuntimeRefsStore()
  const { stopKidScript, runKidScript } = useKidScript()
  const mainEditorRef = useMainEditorRef()
  const {
    setGuidedMissionMode,
    guidedMissionMode,
    setCurrentStep,
    currentStep,
    setShowPortal,
  } = useMissionStore()
  const {
    showMissionEditor,
    previewMissionMode,
    setShowMissionEditor,
    setToolboxMissionStepInsertable,
  } = useMissionBuilderStore()
  const { replayMissionStep } = useMissionStepActions()
  const { data: missionSteps } = useMissionSteps(params.id as string)

  // const { currentStep, previewMissionMode, showMissionEditor } =
  //   missionState.get()

  const isMissionStep = useMemo(() => {
    return !!params?.stepId || pathname.includes('/new')
  }, [params, pathname])

  return (
    <div>
      {/* <Button
          id="MenuBar_PreviewMission"
          style={{ height: '32px' }}
          className="mr-8 !pl-4 pr-4"
          context={'earthDark'}
          size="small"
          onClick={() => {
            // editorRef.reset()
            // setShowEditMission(false)
            // setShowMissionEditor(false)
            // setPreviewMissionMode(true)
            // setCurrentStep(missionSteps[0])
            // setShowPortal(true)
            // setTimeout(() => {
            //   replayMissionSteps(missionSteps[0], false, null, true)
            //   setBlockNextStep(false)
            // }, 1000)
          }}
        >
          Preview mission
        </Button> */}
      {/* {!showMissionEditor && previewMissionMode && (
          <Button
            id="MenuBar_EditCurrentStep"
            style={{ height: '32px' }}
            className="mr-2 !pl-3 pr-4"
            context={'earthDark'}
            size="small"
            // onClick={() => setShowToolbox(!showToolbox)}
            iconCircleClassnames="h-[32px] pr-1.5"
            iconWithCircle
            iconSide="left"
            onClick={() => {
              // setShowEditMission(true)
              // setPreviewMissionMode(false)
              // setShowPortal(false)
              // setMissionEditorDisplayType('editMissionStep')
            }}
          >
            Edit current step
          </Button>
        )} */}

      {isMissionStep && showMissionEditor && !guidedMissionMode && (
        <Button
          id="MenuBar_PreviewCurrentStep"
          className="mr-2 !pl-4 pr-4"
          context={'earthDark'}
          size="tiny"
          // onClick={() => setShowToolbox(!showToolbox)}
          onClick={() => {
            setEditorValues().then(() => {
              mainEditorRef?.reset()
              setShowMissionEditor(false)
              setGuidedMissionMode(true)
              setShowPortal(true)

              setTimeout(() => {
                replayMissionStep(getValues(), false, [])
              }, 200)

              if (currentStep?.run_automatically) {
                runKidScript()
              }
            })
            setToolboxMissionStepInsertable(false)
            // setPreviewCurrentStep(true)
            // setShowEditMission(false)
            // setToolboxMissionStepInsertable(false)
          }}
        >
          Preview step
        </Button>
      )}
    </div>
  )
}

export default MissionBuilderHeader
