'use client'
import classNames from 'classnames'
import React from 'react'

interface HeaderProps {
  label: string
  description: string
}

const Header: React.FC<HeaderProps> = ({ label, description }) => {
  const headerClassnames = classNames('!mb-0 text-white')
  return (
    <div className="flex flex-col">
      <h4 className={classNames(headerClassnames, '!mb-0')}>{label}</h4>
      <p className="text-neptune-lightest mb-4 text-xs leading-6">
        {description}
      </p>
    </div>
  )
}

export default Header
