'use client'
import { useMemo } from 'react'
import { useRuntimeRefsStore } from './Runtime/useRuntimeRefsStore'

export const useMainEditorRef = () => {
  const { editorRefs } = useRuntimeRefsStore()

  const mainEditorRef = useMemo(() => {
    if (editorRefs) {
      return editorRefs['main']
    }
  }, [editorRefs])
  return mainEditorRef
}
