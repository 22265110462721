'use client'

import { faCheck, faLeftLong, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lowerCase } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { useUpdateMissionStepMutation } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import Button from '@codeverse/helios/button'
import Input from '@codeverse/helios/input'
import { MissionStepFormValues } from '../types'

interface LibraryFilterModalProps {
  setValue: UseFormSetValue<MissionStepFormValues>
}

const LibraryFilterModal: React.FC<LibraryFilterModalProps> = ({
  setValue,
}) => {
  const { currentStep } = useMissionStore()
  const { currentLibraryFilterObject, setCurrentLibraryFilterObject } =
    useMissionBuilderStore()

  const { setToolboxLibraryFilter, setShowToolbox } = useRuntimeStore()
  // const {
  //   state: RuntimeState,
  //   setCurrentLibraryFilterObject,
  //   setShowToolbox,
  //   setToolboxLibraryFilter,
  // } = useRuntime()
  // const {
  //   state: { currentStep },
  //   setCurrentStep,
  // } = useGuidedMissions()
  // const queryClient = useQueryClient()
  // const { state: UIState } = useUIState()
  // const { libraryFilterModal } = UIState
  // const { currentLibraryFilterObject } = RuntimeState
  const [searchTerm, setSearchTerm] = React.useState('')
  const [typeToShow, setTypeToShow] = React.useState<
    'methods' | 'constants' | 'events'
  >('methods')
  const mutateUpdateMissionStep = useUpdateMissionStepMutation()

  const objectIsSelected = useMemo(() => {
    return currentStep?.library?.objects?.find(
      (object: any) => object.name === currentLibraryFilterObject?.name
    )
  }, [currentStep, currentLibraryFilterObject])

  const kidscriptMethods = useMemo(() => {
    if (currentLibraryFilterObject) {
      return currentLibraryFilterObject.getMethods()
    }
  }, [currentLibraryFilterObject])

  const kidscriptConstants = useMemo(() => {
    if (currentLibraryFilterObject) {
      return currentLibraryFilterObject.constants
    }
  }, [currentLibraryFilterObject])

  const kidscriptEvents = useMemo(() => {
    if (currentLibraryFilterObject) {
      return currentLibraryFilterObject.getEvents()
    }
  }, [currentLibraryFilterObject])

  const RenderMethods = useMemo(() => {
    if (kidscriptMethods) {
      return Object.keys(kidscriptMethods).map((key) => {
        const kidscriptMethod = kidscriptMethods[key]
        if (kidscriptMethod.internal) return
        //@ts-ignore
        const kidscriptHtml = KidScript.parseBlock(
          kidscriptMethod.getPreview()
        ).toHTML()

        if (
          searchTerm?.length > 0 &&
          !kidscriptMethod.name.includes(searchTerm)
        ) {
          return null
        }

        const isSelected = currentStep.library?.objects
          ?.find(
            (object: any) => object.name === currentLibraryFilterObject.name
          )
          ?.methods?.find(
            (constant: any) => constant.name === kidscriptMethod.name
          )

        return (
          <div
            className="my-2 flex cursor-pointer flex-row overflow-y-scroll rounded-s bg-white px-5 py-2"
            onClick={() => handleSetObject('method', kidscriptMethod.name)}
          >
            <div className="mr-6 flex items-center justify-center">
              <div
                style={{ opacity: isSelected ? 1 : 0 }}
                className="bg-titan-dark py-auto flex h-[40px] w-[40px] items-center justify-center rounded-full"
              >
                <FontAwesomeIcon className=" px-4 text-white" icon={faCheck} />
              </div>
            </div>
            <div className="bg-moon-darkest flex-grow rounded-lg p-8">
              <pre
                className="kidscript-syntax w-full overflow-y-scroll"
                dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
              />
            </div>
          </div>
        )
      })
    }
  }, [kidscriptMethods, searchTerm, currentStep])

  const RenderConstants = useMemo(() => {
    if (kidscriptConstants) {
      return Object.keys(kidscriptConstants).map((key) => {
        const kidscriptMethod = kidscriptConstants[key]
        const isSearchingForValue = kidscriptMethod.values.find((value: any) =>
          value.includes(searchTerm)
        )
        if (
          searchTerm?.length > 0 &&
          !kidscriptMethod.name.includes(searchTerm)
        ) {
          return null
        }
        const isSelected = currentStep?.library?.objects
          ?.find(
            (object: any) => object.name === currentLibraryFilterObject.name
          )
          ?.constants?.find(
            (constant: any) => constant.name === kidscriptMethod.name
          )

        const values = kidscriptMethod.values.map((value: any) => {
          const valueIsSelected = currentStep?.library?.objects
            ?.find(
              (object: any) => object.name === currentLibraryFilterObject.name
            )
            ?.constants?.find(
              (constant: any) => constant.name === kidscriptMethod.name
            )
            ?.values?.find((selectedValue: any) => selectedValue === value)
          return (
            <div
              className="my-2 ml-32 flex cursor-pointer flex-row rounded-lg bg-white p-8"
              onClick={() =>
                handleSetObject('constant', kidscriptMethod.name, value)
              }
            >
              <div className="mr-6 flex items-center justify-center">
                <div
                  style={{ opacity: valueIsSelected ? 1 : 0 }}
                  className="bg-titan-dark py-auto flex h-[40px] w-[40px] items-center justify-center rounded-full"
                  onClick={() =>
                    handleSetObject('constant', kidscriptMethod.name, value)
                  }
                >
                  <FontAwesomeIcon
                    className="px-16 text-white"
                    icon={faCheck}
                  />
                </div>
              </div>
              <div className="bg-moon-darkest flex-grow rounded-lg p-8 text-white">
                "{value}"
              </div>
            </div>
          )
        })

        return (
          <div>
            <div
              className="my-8 flex cursor-pointer flex-row rounded-lg bg-white p-8"
              onClick={() => handleSetObject('constant', kidscriptMethod.name)}
            >
              <div className="mr-6 flex items-center justify-center">
                <div
                  style={{ opacity: isSelected ? 1 : 0 }}
                  className="bg-titan-dark py-auto flex h-[40px] w-[40px] items-center justify-center rounded-full"
                >
                  <FontAwesomeIcon
                    className=" px-4 text-white"
                    icon={faCheck}
                  />
                </div>
              </div>
              <div className="bg-moon-darkest flex-grow rounded-lg p-8 text-white">
                {kidscriptMethod.name}
              </div>
            </div>
            {isSelected ? values : null}
          </div>
        )
      })
    }
  }, [kidscriptConstants, searchTerm, currentStep])

  const RenderEvents = useMemo(() => {
    if (kidscriptEvents) {
      return Object.keys(kidscriptEvents).map((key) => {
        const kidscriptEvent = kidscriptEvents[key]
        if (
          searchTerm?.length > 0 &&
          !kidscriptEvent.name.includes(searchTerm)
        ) {
          return null
        }

        const isSelected = currentStep.library?.objects
          ?.find(
            (object: any) => object.name === currentLibraryFilterObject.name
          )
          ?.events?.find((event: any) => event.name === kidscriptEvent.name)

        return (
          <div
            className="my-8 flex cursor-pointer flex-row rounded-lg bg-white p-4"
            onClick={() => handleSetObject('event', kidscriptEvent.name)}
          >
            <div className="mr-6 flex items-center justify-center">
              <div
                style={{ opacity: isSelected ? 1 : 0 }}
                className="bg-titan-dark py-auto flex h-[40px] w-[40px] items-center justify-center rounded-full"
              >
                <FontAwesomeIcon className=" px-16 text-white" icon={faCheck} />
              </div>
            </div>
            <div className="bg-moon-darkest flex-grow rounded-lg p-8 text-white">
              {kidscriptEvent.name}
            </div>
          </div>
        )
      })
    }
  }, [kidscriptEvents, searchTerm, currentStep])

  const handleSetObject = (
    type: 'method' | 'constant' | 'event',
    typeValue,
    constantValue?
  ) => {
    const libraryFiltersToModify = Object.assign({}, currentStep.library)
    const foundObjectIndex = libraryFiltersToModify.objects?.findIndex(
      (object: any) => object.name === currentLibraryFilterObject.name
    )
    // Check for existing object
    if (typeof foundObjectIndex === 'number') {
      if (type === 'method') {
        if (libraryFiltersToModify.objects[foundObjectIndex].methods) {
          const foundMethod = libraryFiltersToModify.objects[
            foundObjectIndex
          ].methods.find((method: any) => method.name === typeValue)
          if (!foundMethod) {
            libraryFiltersToModify.objects[foundObjectIndex].methods = [
              ...libraryFiltersToModify.objects[foundObjectIndex].methods,
              { name: typeValue },
            ]
          }
        } else {
          libraryFiltersToModify.objects[foundObjectIndex].methods = [
            { name: typeValue },
          ]
        }
      } else if (type === 'constant') {
        // Find Constants within object
        if (libraryFiltersToModify.objects[foundObjectIndex].constants) {
          const foundConstantIndex = libraryFiltersToModify.objects[
            foundObjectIndex
          ].constants.findIndex((constant: any) => constant.name === typeValue)
          const foundConstant = libraryFiltersToModify.objects[
            foundObjectIndex
          ].constants.find((constant: any) => constant.name === typeValue)
          if (foundConstantIndex > -1) {
            const newValue = [...foundConstant.values]
            if (
              constantValue &&
              newValue.find((value) => value === constantValue) === undefined
            ) {
              newValue.push(constantValue)
            }
            libraryFiltersToModify.objects[foundObjectIndex].constants.splice(
              foundConstantIndex,
              1,
              {
                name: typeValue,
                values: newValue,
              }
            )
          } else {
            libraryFiltersToModify.objects[foundObjectIndex].constants = [
              ...libraryFiltersToModify.objects[foundObjectIndex].constants,
              { name: typeValue, values: [] },
            ]
          }
        } else {
          libraryFiltersToModify.objects[foundObjectIndex].constants = [
            { name: typeValue, values: [] },
          ]
        }
      } else if (type === 'event') {
        if (libraryFiltersToModify.objects[foundObjectIndex].events) {
          const foundEvent = libraryFiltersToModify.objects[
            foundObjectIndex
          ].events.find((event: any) => event.name === typeValue)
          if (!foundEvent) {
            libraryFiltersToModify.objects[foundObjectIndex].events = [
              ...libraryFiltersToModify.objects[foundObjectIndex].events,
              { name: typeValue },
            ]
          }
        } else {
          libraryFiltersToModify.objects[foundObjectIndex].events = [
            { name: typeValue },
          ]
        }
      }
    }

    setValue('library', libraryFiltersToModify, { shouldDirty: true })
  }

  const removeObject = () => {
    let libraryFiltersToModify = Object.assign({}, currentStep.library)
    const foundObjectIndex = libraryFiltersToModify.objects?.findIndex(
      (object: any) => object.name === currentLibraryFilterObject.name
    )
    if (typeof foundObjectIndex === 'number') {
      libraryFiltersToModify.objects.splice(foundObjectIndex, 1)
    }
    if (libraryFiltersToModify.objects.length === 0) {
      libraryFiltersToModify = null
      // mutateUpdateMissionStep.mutate(
      //   {
      //     id: currentStep.id,
      //     library: null,
      //   },
      //   {
      //     onSuccess: (data) => {
      //       setCurrentStep(data)
      //     },
      //   }
      // )
    }
    setValue('library', libraryFiltersToModify, { shouldDirty: true })
  }

  const handleEnableObject = () => {
    let libraryFiltersToModify = Object.assign({}, currentStep.library)
    if (!currentStep.library?.objects) {
      libraryFiltersToModify = {
        objects: [{ name: currentLibraryFilterObject.name }],
      }
    } else {
      libraryFiltersToModify.objects.push({
        name: currentLibraryFilterObject.name,
      })
    }
    setValue('library', libraryFiltersToModify, { shouldDirty: true })
  }

  if (!currentLibraryFilterObject) {
    return null
  }

  return (
    <div className="bg-neptune absolute inset-0 overflow-y-scroll">
      <div
        className="cursor-pointer pl-6 pt-4"
        onClick={() => setCurrentLibraryFilterObject(null)}
      >
        <FontAwesomeIcon className="text-white" icon={faLeftLong} />
        <div className="font-poppins-bold ml-2 inline-block text-white">
          Back to Objects
        </div>
      </div>
      <div
        className="cursor-pointer pl-6 pt-4"
        onClick={() => {
          setCurrentLibraryFilterObject(null)
          setToolboxLibraryFilter(false)
          setShowToolbox(false)
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
        <div className="font-poppins-bold ml-2 inline-block text-white">
          Close Library Filter
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 p-6">
        <div
          className="relative col-span-1 flex flex-auto rounded-lg bg-white"
          style={{
            height: '0%',
            paddingTop: '130%',
            // x,
            // y,
            cursor: 'grab',
            touchAction: 'none',
          }}
        >
          <img
            className="absolute left-1/2 top-1/2"
            // className="absolute left-1/2 top-1/2"
            draggable={false}
            style={{
              maxWidth: '70%',
              transform: 'translate(-50%, -63%)',
              maxHeight: '96px',
              userSelect: 'none',
            }}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              currentLibraryFilterObject.getIcon()
            )}`}
            alt=""
          />
        </div>
        <div className="col-span-2">
          <h2 className="text-sun-dark">{currentLibraryFilterObject.name}</h2>
          {objectIsSelected ? (
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault()
                removeObject()
              }}
              context="danger"
            >
              Remove object
            </Button>
          ) : (
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault()
                handleEnableObject()
              }}
              context="earth"
            >
              Add this object to be filtered
            </Button>
          )}
        </div>
      </div>

      {objectIsSelected && (
        <div className="mx-6 grid grid-cols-3 gap-4">
          <div className="flex justify-center">
            <Button
              size="small"
              id="MissionEditor_ShowEvents"
              onClick={(e) => {
                e.preventDefault()
                setTypeToShow('methods')
              }}
              context="secondary"
            >
              Show Methods
            </Button>
          </div>
          <div className="flex justify-center">
            <Button
              size="small"
              id="MissionEditor_ShowEvents"
              onClick={(e) => {
                e.preventDefault()
                setTypeToShow('constants')
              }}
              context="secondary"
            >
              Show Constants
            </Button>
          </div>
          <div className="flex justify-center">
            <Button
              size="small"
              id="MissionEditor_ShowEvents"
              onClick={(e) => {
                e.preventDefault()
                setTypeToShow('events')
              }}
              context="secondary"
            >
              Show Events
            </Button>
          </div>
        </div>
      )}

      {objectIsSelected && (
        <div className="p-6">
          <div>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-8"
              label="Search"
            />
          </div>
          {typeToShow === 'methods' && <div>{RenderMethods}</div>}
          {typeToShow === 'constants' && <div>{RenderConstants}</div>}
          {typeToShow === 'events' && <div>{RenderEvents}</div>}
        </div>
      )}
    </div>
  )
}

export default LibraryFilterModal
