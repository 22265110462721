import { faPennant } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'

import { headerClassnames, inputClassname } from './Styles'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { MissionStepFormValues } from '../types'
import Input from '@codeverse/helios/input'
import ImageFileUpload from '../../Mission/Form/ImageFileUpload'
import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import FormCheckbox from './FormCheckbox'

interface StepHeaderFieldProps {
  control: Control<MissionStepFormValues>
  isQuiz: boolean
  setValue: UseFormSetValue<MissionStepFormValues>
}

const StepHeaderField: React.FC<StepHeaderFieldProps> = ({
  isQuiz,
  control,
  setValue,
}) => {
  const { currentStep } = useMissionStore()

  const [defaultMissionSuccess, setDefaultMissionSuccess] = React.useState(
    !!currentStep?.success_header
  )

  return (
    <div>
      {!isQuiz && (
        <div className="grid grid-cols-12">
          <div className="col-span-10 mb-6">
            <Header
              label={defaultMissionSuccess ? 'Success Header' : 'Header'}
              description={
                defaultMissionSuccess
                  ? 'Header to display for the last step'
                  : 'Header of the current step'
              }
            />
            {defaultMissionSuccess ? (
              <Controller
                name="success_header"
                control={control}
                render={({ field }) => (
                  <Input
                    inputClassName={classNames(
                      inputClassname,
                      '!bg-moon-lightest'
                    )}
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                name="header"
                control={control}
                render={({ field }) => (
                  <Input
                    inputClassName={classNames(
                      inputClassname,
                      '!bg-moon-lightest'
                    )}
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <div className="col-span-2 flex flex-col items-center justify-center">
            <div className="bg-titan-dark flex h-[32px] w-[32px] items-center justify-center rounded-full">
              <FontAwesomeIcon className="pl-1 text-white" icon={faPennant} />
            </div>
            <FormCheckbox
              classNameOverride="mt-4"
              // heightOverride="h-12 w-12"
              iconSizeOverride="1x"
              name=""
              value={defaultMissionSuccess}
              onChange={() => {
                if (!defaultMissionSuccess) {
                  setValue('header', '', { shouldDirty: false })
                } else {
                  setValue('success_header', '', { shouldDirty: false })
                }
                setDefaultMissionSuccess(!defaultMissionSuccess)
              }}
            />
          </div>
        </div>
      )}
      {defaultMissionSuccess && (
        <div>
          <h4 className={classNames(headerClassnames, '!mb-0')}>Image</h4>
          <Controller
            name="image"
            control={control}
            render={({ field }) => {
              return (
                <div className="mb-2 mt-2">
                  <ImageFileUpload
                    value={field.value}
                    handleFile={field.onChange}
                    handleReset={() => field.onChange(null)}
                    theme="dark"
                  />
                </div>
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default StepHeaderField
