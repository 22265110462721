import { SatelliteClient } from '@codeverse/satellite-client'
import Cookies from 'js-cookie'

export const getProjects = (fetchParams: any) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null

  return SatelliteClient.platform.get(`/users/${currentUserId}/projects`, {
    query: fetchParams,
  })
}

export const getProject = (projectId: any) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null

  return SatelliteClient.platform.get(`/projects/${projectId}`)
}

export const getProjectDocuments = (projectId: any) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null

  return SatelliteClient.platform.get(`/projects/${projectId}/documents`)
}

export const createProject = (name: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.post('/projects', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      name,
      //@ts-ignore
      initial_kidscript_version: KidScript.Version,
      relationships: {
        user: {
          id: currentUserId,
          type: 'users',
        },
      },
    },
    serializer: 'projects',
  })
}

export const createMissionAchievement = (
  projectId: string,
  missionId: string
) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.post('/mission_achievements', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      relationships: {
        mission: {
          id: missionId,
          type: 'missions',
        },
        project: {
          id: projectId,
          type: 'projects',
        },
      },
    },
    query: {
      current_user_id: currentUserId,
    },
    serializer: 'mission_achievements',
  })
}

export const createBaseProjectDocuments = (
  projectId: string,
  headKidScript: string,
  mainKidScript: string
) => {
  return SatelliteClient.platform
    .post('/documents', {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      data: {
        filename: '/head.ks',
        kidscript: headKidScript,
        relationships: {
          project: {
            id: projectId,
            type: 'projects',
          },
        },
      },
      serializer: 'documents',
    })
    .then((response1) => {
      SatelliteClient.platform
        .post('/documents', {
          headers: Object.assign(
            {},
            { Accept: 'application/vnd.api+json' },
            { 'content-type': 'application/vnd.api+json' }
          ),
          data: {
            filename: '/main.ks',
            kidscript: mainKidScript,
            relationships: {
              project: {
                id: projectId,
                type: 'projects',
              },
            },
          },
          serializer: 'documents',
        })
        .then((response2) => ({
          response1,
          response2,
        }))
    })
}

export const getProjectVersions = (projectId: string) => {
  return SatelliteClient.platform.get(`/projects/${projectId}/versions`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}

export const updateProjectVersion = (
  versionId: string,
  status: string,
  name: string,
  description: string
) => {
  return SatelliteClient.platform.put(`/versions/${versionId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      id: versionId,
      status: status,
      name,
      description,
    },
    serializer: 'versions',
  })
}

export const createProjectVersion = ({
  projectId,
  status = 'is_public',
  screenshot,
  name,
  description,
  version,
}: {
  projectId: string
  status: string
  screenshot: string
  name: string
  description: string | null
  version: string
}) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.post('/versions', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      significance: 'patch',
      status: status,
      name,
      description,
      version,
      //@ts-ignore
      kidscript_version: KidScript.Version,
      screenshot: screenshot,
      relationships: {
        project: {
          id: projectId,
          type: 'projects',
        },
        user: {
          id: currentUserId,
          type: 'users',
        },
      },
    },
    serializer: 'versions',
  })
}

export const updateProject = (projectId: string, data: any) => {
  return SatelliteClient.platform.put(`/projects/${projectId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      id: projectId,
      ...data,
    },
    serializer: 'projects',
  })
}

export const createVersionPlay = (id: string) => {
  return SatelliteClient.platform.post('version_plays', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      relationships: {
        version: {
          type: 'versions',
          id: id,
        },
        public_session: {
          type: 'public_sessions',
          id: localStorage.getItem('sessionToken'),
        },
      },
    },
    serializer: 'version_plays',
  })
}
