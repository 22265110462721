import React, { useContext } from 'react'
import classNames from 'classnames'

export const label = {
  base: 'absolute top-1/2 transform -translate-y-1/2 left-1.5 text-lg text-moon pointer-events-none font-system px-4',
  // check and radio get this same style
  check: 'inline-flex items-center',
  invalid: 'text-mars-dark',
  valid: 'text-titan-darkalt',
  disabled: 'opacity-50 cursor-not-allowed',
  size: {
    regular: '',
    large: 'top-[26px] text-[20px] font-poppins-semibold',
  },
  focused: {
    large: 'font-system',
  },
}

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  /**
   * Applies specific styles for checkboxes
   */
  check?: boolean
  /**
   * Applies specific styles for radios
   */
  radio?: boolean
  /**
   * Defines if the label is disabled (you should still disable child elements)
   */
  disabled?: boolean
}

const Label = function Label(props: Props, ref: any) {
  const { children, check, radio, disabled, className, ...other } = props

  const baseStyle = label.base
  const checkStyle = label.check
  const disabledStyle = label.disabled

  const cls = classNames(
    baseStyle,
    // check and radio are interchangeable
    (check || radio) && checkStyle,
    disabled && disabledStyle,
    className
  )

  return (
    <label className={cls} {...other}>
      {children}
    </label>
  )
}

export default Label
