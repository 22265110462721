import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface RuntimeStore {
  kidscriptRunning: boolean
  setKidscriptRunning: (kidscriptRunning: boolean) => void
  editorSetup: boolean
  setEditorSetup: (editorSetup: boolean) => void
  kidScriptSetup: boolean
  setKidScriptSetup: (kidscriptSetup: boolean) => void
  projectSaved: boolean
  setProjectSaved: (projectSaved: boolean) => void
  scriptsLoaded: boolean
  setScriptsLoaded: (scriptsLoaded: boolean) => void
  showToolbox: boolean
  setShowToolbox: (showToolbox: boolean) => void
  showCodeReference: boolean
  setShowCodeReference: (showCodeReference: boolean) => void
  toolboxMissionStepInsertable: boolean
  setToolboxMissionStepInsertable: (
    toolboxMissionStepInsertable: boolean
  ) => void
  toolboxLibraryFilter: boolean
  setToolboxLibraryFilter: (toolboxLibraryFilter: boolean) => void
  projectSaving: boolean
  setProjectSaving: (projectSaving: boolean) => void
  projectHasChanges: boolean
  setProjectHasChanges: (projectHasChanges: boolean) => void
  setCurrentEvent: any
  setCurrentMethod: any
  currentEvent: any
  currentMethod: any
  currentToolboxObject: any
  setCurrentToolboxObject: any
  setDocuments: (documents: any) => void
  documents: Array<any> | null
  setKidScriptDependenciesFetched: (
    kidScriptDependenciesFetched: boolean
  ) => void
  kidScriptDependenciesFetched: boolean
}

const useRuntimeStore = create<RuntimeStore>()(
  devtools(
    (set) => ({
      setKidScriptDependenciesFetched: (kidScriptDependenciesFetched) =>
        set(() => ({
          kidScriptDependenciesFetched: kidScriptDependenciesFetched,
        })),
      kidScriptDependenciesFetched: false,
      kidscriptRunning: false,
      editorSetup: false,
      kidScriptSetup: false,
      scriptsLoaded: false,
      showToolbox: false,
      toolboxMissionStepInsertable: false,
      toolboxLibraryFilter: false,
      projectSaved: true,
      projectSaving: false,
      projectHasChanges: false,
      documents: null,
      setDocuments: (documents) => set(() => ({ documents: documents })),
      setKidscriptRunning: (kidscriptRunning) =>
        set(() => ({ kidscriptRunning: kidscriptRunning })),
      setEditorSetup: (editorSetup) =>
        set(() => ({ editorSetup: editorSetup })),
      setKidScriptSetup: (kidscriptSetup) =>
        set(() => ({ kidScriptSetup: kidscriptSetup })),
      setProjectSaved: (projectSaved) =>
        set(() => ({ projectSaved: projectSaved })),
      setScriptsLoaded: (scriptsLoaded) =>
        set(() => ({ scriptsLoaded: scriptsLoaded })),
      setShowToolbox: (showToolbox) =>
        set(() => ({ showToolbox: showToolbox })),
      setToolboxMissionStepInsertable: (toolboxMissionStepInsertable) =>
        set(() => ({
          toolboxMissionStepInsertable: toolboxMissionStepInsertable,
        })),
      setToolboxLibraryFilter: (toolboxLibraryFilter) =>
        set(() => ({ toolboxLibraryFilter: toolboxLibraryFilter })),
      setProjectSaving: (projectSaving) =>
        set(() => ({ projectSaving: projectSaving })),
      setProjectHasChanges: (projectHasChanges) =>
        set(() => ({ projectHasChanges: projectHasChanges })),
      setCurrentEvent: (event: any) => set(() => ({ currentEvent: event })),
      setCurrentMethod: (method: any) => set(() => ({ currentMethod: method })),
      currentToolboxObject: null,
      currentEvent: null,
      currentMethod: null,
      setShowCodeReference: (showCodeReference: any) =>
        set(() => ({ showCodeReference: showCodeReference })),
      showCodeReference: false,
      setCurrentToolboxObject: (object: any) =>
        set(() => ({ currentToolboxObject: object })),
    }),
    { name: 'Runtime Store' }
  )
)

export default useRuntimeStore
