'use client'

import Button from '@codeverse/helios/button'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import EditMissionStepTips from './EditMissionStepTips'
import NewMissionStepTips from './NewMissionStepTips'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'

interface MissionStepTipsProps {
  type: 'edit' | 'new'
}

const MissionStepTips: React.FC<MissionStepTipsProps> = ({ type }) => {
  const { setMissionStepTipModal } = useUIStore()
  return (
    <div className="mt-4">
      <div className="flex flex-row items-center">
        <h4 className="!mb-0 text-white">Mission Step Tips</h4>
        <div className="ml-auto">
          <Button
            className="!pl-4 pr-6 shadow-none"
            size="small"
            context="nebula"
            onClick={(e) => {
              e.preventDefault()
              setMissionStepTipModal(true)
            }}
          >
            <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
            Add
          </Button>
        </div>
      </div>
      <p className="text-neptune-lightest mb-4 text-xs leading-6">
        Add tip(s) to display with this step
      </p>

      {type === 'edit' && <EditMissionStepTips />}
      {type === 'new' && <NewMissionStepTips />}
    </div>
  )
}

export default MissionStepTips
