'use client'

import Input from '@codeverse/helios/input'
import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface VideoFieldProps {
  control: Control<MissionStepFormValues>
}

const VideoField: React.FC<VideoFieldProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header label="Video Link" description="Link to video file to play" />
      <Controller
        name="video_uri"
        control={control}
        render={({ field }) => {
          return <Input value={field.value} onChange={field.onChange} />
        }}
      />
    </div>
  )
}

export default VideoField
