import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createMissionStepTip,
  getMissionAchievement,
  getMissionAchievements,
  getMissionStep,
  getMissionStepInsertables,
  getMissionStepTips,
  getMissionSteps,
  getMissionStepsMultipleChoice,
} from './MissionFetch'
import fetchClientGql from '../fetch/fetchClientGql'
import { missionInsertablesQuery } from './MissionQueries'
import { MissionStep, MissionsData } from './MissionTypes'

export const useMissionStepInsertables = (missionStepId: string) => {
  return useQuery({
    queryKey: ['missionStepInsertables', missionStepId],
    queryFn: () => {
      return getMissionStepInsertables(missionStepId)
    },
    enabled: !!missionStepId,
  })
}

export const useAllMissionStepInsertables = (missionId: string) => {
  return useQuery({
    queryKey: ['allMissionStepInsertables', missionId],
    queryFn: () => {
      return fetchClientGql().request(
        missionInsertablesQuery(missionId)
      ) as Promise<MissionsData>
    },
    enabled: !!missionId,
  })
}

export const useMissionSteps = (
  missionId: string,
  initialData?: Array<any>
) => {
  const { data, isFetched } = useQuery(
    Object.assign(
      {},
      {
        queryKey: ['missionSteps', missionId],
        queryFn: () =>
          getMissionSteps(missionId).then((res) => {
            return res.data as Array<any>
          }),
        enabled: !!missionId,
      }
    )
  )
  return {
    data: data as Array<any> | null,
    isFetched,
  }
}

export const useMissionStep = (missionStepId: string) => {
  const { data, isFetched } = useQuery(
    Object.assign(
      {},
      {
        queryKey: ['missionStep', missionStepId],
        queryFn: () =>
          getMissionStep(missionStepId).then((res) => {
            return res
          }),
        enabled: !!missionStepId,
      }
    )
  )
  return {
    data: data as Array<MissionStep> | null,
    isFetched,
  }
}

export const useMissionStepTips = (missionStepId: string) => {
  return useQuery({
    queryKey: ['missionStepTips', missionStepId],
    queryFn: () => {
      return getMissionStepTips(missionStepId)
    },
    enabled: !!missionStepId,
  })
}

export const useMissionStepsMultipleChoice = (missionStepId: string) => {
  return useQuery({
    queryKey: ['missionSteps', `missionStepMC-${missionStepId}`],
    queryFn: () => {
      return getMissionStepsMultipleChoice(missionStepId)
    },
    enabled: !!missionStepId,
  })
}

export const useMissionAchievements = () => {
  return useQuery({
    queryKey: ['missionAchievements'],
    queryFn: () => getMissionAchievements(),
    refetchOnWindowFocus: false,
  })
}

export const useMissionAchievement = (missionAchievementId: string) => {
  return useQuery({
    queryKey: ['missionAchievement'],
    queryFn: () => getMissionAchievement(missionAchievementId),
    enabled: !!missionAchievementId,
  })
}
