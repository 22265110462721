'use client'

import React, { useCallback } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faSquare } from '@fortawesome/pro-solid-svg-icons'

import classNames from 'classnames'
import { useRuntimeRefsStore } from '../Hooks/Runtime/useRuntimeRefsStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { toast } from 'react-toastify'
import useKidScript from '../Hooks/useKidScript'
import { useParams } from 'next/navigation'

interface EditorActionButtonsProps {
  bottomOverride?: string
  runCallback?: any
  size?: any
  cx?: any
}

interface ActionButtonsProps {}

const RunButton = ({
  setRunning,
  runCallback,
  size,
}: {
  setRunning?: any
  runCallback?: any
  size?: string
}) => {
  const params = useParams()
  const { takeScreenshot } = useKidScript()
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const { setShowMissionEditor, previewMissionMode, setMissionStepRunning } =
    useMissionBuilderStore()

  const handleRunClick = useCallback(async () => {
    const mainEditorRef = editorRefs.main
    const interpreterRef = interpreterRefs.main

    if (mainEditorRef === null) return

    try {
      const documents = mainEditorRef.getKidScript()
      //@ts-ignore
      KidScript.onDependenciesResolved(documents, KidScript.Version, () => {
        let instructions: any = false
        try {
          instructions = mainEditorRef.getInstructionTree()
        } catch (e) {
          console.log('error', e)
        }

        if (instructions !== false) {
          const state = interpreterRef.getState()

          if (state === 'IDLE') return

          let promise
          try {
            promise = interpreterRef.execute(instructions)
          } catch (e) {
            toast.error('Please check your code')
            return
          }
          promise
            .then(
              (result: any) => {
                console.log('result', result)
                if (!interpreterRef.isStopped()) {
                  // if (projectsMatch) {
                  // takeScreenshot(params?.id as string)
                  // }
                }
                typeof runCallback === 'function' && runCallback()
                setRunning(true)
                setMissionStepRunning(true)
                setShowMissionEditor(false)
                // dispatch(editorActions.disableEditMode());
              },
              (error) => {
                console.log('error', error)
                toast.error('Please check your code')
              }
            )
            .catch((error) => {
              console.log('error', error)
              toast.error('Please check your code')
            })
        } else {
          console.log('error')
          toast.error('Please check your code')
        }
      })
    } catch (e) {
      toast.error('Please check your code')
      console.log('error:', e)
    }
  }, [editorRefs, interpreterRefs])

  return (
    <motion.div
      id="ActionButtons"
      className="inline-flex"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Button
        onClick={handleRunClick}
        className={classNames({
          'flex !h-[28px] !flex-col items-center justify-center !pl-0.5 !pr-3 text-xs':
            size === 'xs',
          'flex h-12 !flex-col !pl-1.5': size !== 'xs',
        })}
        context="success"
        size={'small'}
      >
        <div
          className={classNames(
            'bg-titan-light pointer-events-auto mr-2 inline-flex items-center justify-center rounded-full bg-opacity-40',
            {
              'h-6 max-w-3': size === 'xs',
              'h-10 w-10': size !== 'xs',
            }
          )}
        >
          <FontAwesomeIcon size="lg" icon={faPlay} />
        </div>
        Run
      </Button>
    </motion.div>
  )
}
const StopButton = ({
  setRunning,
  size,
}: {
  setRunning: any
  size?: string
}) => {
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const { setShowMissionEditor, previewMissionMode, setMissionStepRunning } =
    useMissionBuilderStore()
  // const { setShowPortal } = useGuidedMissions()
  // const { interpreterRef } = useRuntime()
  const handleStopClick = () => {
    const interpreterRef = interpreterRefs.main
    if (interpreterRef.getState() !== 'READY') {
      interpreterRef.stop()
    }

    if (previewMissionMode) {
      setShowMissionEditor(true)
    }
    setMissionStepRunning(false)
    setRunning(false)
    // setShowPortal(true)
  }
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Button
        onClick={handleStopClick}
        className={classNames('', {
          'flex !h-[28px] !flex-col !pl-1 !pr-2 text-xs': size === 'xs',
          'flex h-12 !flex-col pl-2': size !== 'xs',
        })}
        context="danger"
        size={'small'}
      >
        <div
          className={classNames(
            'bg-mars-light pointer-events-auto mr-1 inline-flex items-center justify-center rounded-full bg-opacity-40',
            {
              'h-5 w-5': size === 'xs',
              'h-8 w-8': size !== 'xs',
            }
          )}
        >
          <FontAwesomeIcon size="sm" icon={faSquare} />
        </div>
        Stop
      </Button>
    </motion.div>
  )
}

const EditorActionButtons: React.FC<EditorActionButtonsProps> = ({
  bottomOverride = 'bottom-6',
  runCallback,
  size,
  cx,
}: {
  bottomOverride?: string
  runCallback?: any
  size?: any
  cx?: string
}) => {
  const { kidscriptRunning, setKidscriptRunning } = useRuntimeStore()
  const { currentStep, guidedMissionMode } = useMissionStore()

  const className = classNames('inline-flex', cx)
  // 'pointer-events-auto absolute right-6 z-[9999]',
  return (
    <div className={className}>
      {kidscriptRunning ? (
        <AnimatePresence>
          <StopButton size={size} setRunning={setKidscriptRunning} />
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          <RunButton
            size={size}
            runCallback={runCallback}
            setRunning={setKidscriptRunning}
          />
        </AnimatePresence>
      )}
    </div>
  )
}

export default EditorActionButtons
