import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
// import MissionStepInsertableKidScriptModal from '../MissionStepInsertableKidScriptModal'
// import MissionStepInsertableKidScriptPortal from '../MissionStepInsertableKidScriptPortal'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import { useMissionStepInsertables } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import MissionStepInsertableKidScriptModal from './MissionStepInsertableKidScriptModal'
import MissionStepInsertableKidScriptPortal from './MissionStepInsertableKidScriptPortal'
import { useDeleteMissionStepInsertable } from 'apps/studio-shared/src/Data/Mission/MissionMutations'

interface EditMissionInsertablesProps {
  addKidScriptChange: any
}

const EditMissionInsertables: React.FC<EditMissionInsertablesProps> = ({
  addKidScriptChange,
}) => {
  const queryClient = useQueryClient()
  const [from, setFrom] = useState<number | null>(null)
  const [to, setTo] = useState<number | null>(null)
  const { kidScriptSetup, kidScriptDependenciesFetched } = useRuntimeStore()
  const { editorRefs } = useRuntimeRefsStore()
  const { currentStep } = useMissionStore()
  const {
    toolboxMissionStepInsertable,
    setToolboxMissionStepInsertable,
    missionInsertablesToUpdate,
    setMissionInsertablesToUpdate,
  } = useMissionBuilderStore()
  const [arrayOfKidScriptObjects, setArrayOfKidScriptObjects] = useState([])

  const { data: missionStepInsertables } = useMissionStepInsertables(
    currentStep?.id
  )

  const objects = useMemo(() => {
    if (kidScriptSetup && kidScriptDependenciesFetched) {
      //@ts-ignore
      return KidScript?.library.objects
    }
    return []
  }, [kidScriptSetup, kidScriptDependenciesFetched])

  // const {
  //   state: RuntimeState,
  //   setToolboxMissionStepInsertable,
  //   setShowToolbox,
  //   editorRef,
  // } = useRuntime()
  // const { setMissionInsertablesToUpdate, removeMissionInsertablesToUpdate } =
  //   useGuidedMissions()
  // const { openUI } = useUIState()
  // const deleteMissionStepInsertableMutataion = useDeleteMissionStepInsertable()
  // const { state: GuidedMissionState } = useGuidedMissions()

  // const { objects } = RuntimeState
  // const { missionInsertablesToUpdate, currentStep } = GuidedMissionState

  const deleteMissionStepInsertableMutataion = useDeleteMissionStepInsertable()

  const handleRemoveKidScriptSnippet = (id: string) => {
    deleteMissionStepInsertableMutataion.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['missionStepInsertables', currentStep?.id],
        })
      },
    })
  }

  const arrayOfKidScriptObjectsMemo = useMemo(() => {
    const filteredObjectsTemp: any = []

    Object.keys(objects)
      .sort((a, b) => {
        const textA = a.toUpperCase()
        const textB = b.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      .map((objectKeyName) => {
        if (
          objects[objectKeyName].engine &&
          objects[objectKeyName].engine.importable
        ) {
          filteredObjectsTemp.push(objects[objectKeyName])
        }
      })

    return filteredObjectsTemp
  }, [objects, kidScriptDependenciesFetched])

  useEffect(() => {
    setArrayOfKidScriptObjects(arrayOfKidScriptObjectsMemo)
  }, [arrayOfKidScriptObjectsMemo])

  useEffect(() => {
    if (missionStepInsertables) {
      setMissionInsertablesToUpdate(missionStepInsertables)
    }
  }, [missionStepInsertables])

  const missionInsertablesToUpdateObjectCount = useMemo(() => {
    return missionInsertablesToUpdate?.filter(
      (insertable: any) => insertable.display.toLowerCase() === 'object'
    ).length
  }, [missionInsertablesToUpdate])

  const MissionInsertables = useMemo(() => {
    return missionInsertablesToUpdate?.map((insertable: any, i: any) => {
      const object: any = arrayOfKidScriptObjects?.find(
        (object: any) => object.name === insertable.object
      )
      if (insertable.display.toLowerCase() === 'object' && object) {
        return (
          <div
            key={object?.name}
            className="rounded-8 relative flex flex-auto bg-white"
            data-index={i}
            style={{
              height: '0%',
              paddingTop: '130%',
              // x,
              // y,
              cursor: 'grab',
              touchAction: 'none',
            }}
            draggable
            onDragStart={(e) => setFrom(Number(e.currentTarget.dataset.index))}
            onDragOver={(e) => {
              e.preventDefault()
              setTo(Number(e.currentTarget.dataset.index))
            }}
            onDragEnd={() => {
              const newItems = [...missionInsertablesToUpdate]
              newItems.splice(to || 0, 0, newItems.splice(from || 0, 1)[0])
              setMissionInsertablesToUpdate(newItems)
              setFrom(null)
              setTo(null)
            }}
          >
            <div
              className="bg-moon-light z-2000 absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
              onClick={(e) => {
                e.preventDefault()
                deleteMissionStepInsertableMutataion.mutate(insertable.id, {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ['missionStepInsertables', currentStep?.id],
                    })
                  },
                })
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <img
              className="absolute left-1/2 top-1/2"
              // className="absolute left-1/2 top-1/2"
              draggable={false}
              style={{
                maxWidth: '70%',
                transform: 'translate(-50%, -63%)',
                maxHeight: '96px',
                userSelect: 'none',
              }}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                object.getIcon()
              )}`}
              alt=""
            />
            <div>
              <div
                className="font-poppins-semibold absolute bottom-3 left-1/2 mx-2 inline-block text-center text-sm text-black"
                style={{
                  maxWidth: '100%',
                  width: 'calc(100% - 16px)',
                  transform: 'translate(calc(-50% - 8px), 0%)',
                }}
              >
                <span>{insertable.object}</span>
                {/* <span>{insertable.kidscript}</span> */}
              </div>
            </div>
          </div>
        )
      }

      return null
    })
  }, [missionInsertablesToUpdate, arrayOfKidScriptObjects, from, to])

  const missionInsertablesKidScript = useMemo(() => {
    return missionInsertablesToUpdate?.filter(
      (insertable: any) => insertable.display === 'snippet'
    )
  }, [missionInsertablesToUpdate])

  const missionInsertablesObjects = useMemo(() => {
    return missionInsertablesToUpdate?.filter(
      (insertable: any) => insertable.display === 'object'
    )
  }, [missionInsertablesToUpdate])

  const MissionInsertablesKidScript = useMemo(() => {
    const mainEditorRef = editorRefs['main']
    if (!mainEditorRef) return null
    return missionInsertablesKidScript
      ?.filter((insertable: any) => insertable.display === 'snippet')
      .map((insertable: any, i: any) => {
        //@ts-ignore
        const kidscriptHtml = KidScript.parseBlock(
          mainEditorRef.applyTemplate(insertable.kidscript)
        ).toHTML()
        return (
          <div>
            <div className="bg-moon-darkest relative flex-grow rounded-sm px-4 py-4">
              <pre
                className="kidscript-syntax"
                dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
              />
              <div
                className="text-moon-darkest absolute right-4 top-1/2 flex h-10 w-10 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-white"
                id="MissionInsertables_RemoveKidScriptSnippet"
                onClick={(e) => handleRemoveKidScriptSnippet(insertable.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        )
      })
  }, [missionInsertablesKidScript, editorRefs])

  return (
    <>
      {missionInsertablesToUpdate?.length > 0 && (
        <div className="bg-neptune-darkest rounded-2xl bg-opacity-20 p-4">
          <div className="grid grid-cols-3 gap-4">{MissionInsertables}</div>
          {missionInsertablesKidScript?.length > 0 && (
            <div
              className={classNames({
                'mt-4': missionInsertablesToUpdateObjectCount > 0,
              })}
            >
              {MissionInsertablesKidScript}
            </div>
          )}
        </div>
      )}
      <MissionStepInsertableKidScriptPortal>
        <MissionStepInsertableKidScriptModal
          addKidScriptChange={addKidScriptChange}
        />
      </MissionStepInsertableKidScriptPortal>
    </>
  )
}

export default EditMissionInsertables
