import { useMutation } from '@tanstack/react-query'
import {
  createBaseProjectDocuments,
  createMissionAchievement,
  createProject,
  createVersionPlay,
  updateProject,
} from './ProjectFetch'

export const useCreateNewProjectMutation = () => {
  const mutation = useMutation({
    mutationFn: ({ name }: { name: string }) => createProject(name),
  })
  return mutation
}

export const useMutateProject = () => {
  const mutation = useMutation({
    mutationFn: ({ projectId, data }: any) => {
      return updateProject(projectId, data)
    },
  })
  return mutation
}

export const useCreateMissionAchievementMutation = () => {
  const mutation = useMutation({
    mutationFn: ({
      projectId,
      missionId,
    }: {
      projectId: string
      missionId: string
    }) => {
      return createMissionAchievement(projectId, missionId)
    },
  })
  //   ({ projectId, missionId }: { projectId: string; missionId: string }) => {
  //     return createMissionAchievement(projectId, missionId)
  //   }
  // )
  return mutation
}

export const FIRST_PROJECT_KIDSCRIPT_MAIN = `# import the objects from the toolbox
import Alien
import Rocket
import Platform
# import the special game variable
import game
game.setBackgroundColor(0, 0, 0)

# some moon for the alien to stand on
var moon = new Platform(50, 100)
moon.setGrassColor(79, 82, 87)
moon.hideTexture()
moon.setBodyColor(81, 87, 91)
moon.setSize(834.3)

# an alien
var alien = new Alien(12.8, 73.5)
alien.setSize(171.2)

# a rocket
var rocket = new Rocket(77.8, 66.4)
rocket.setSize(500)

# tap the alien and she will walk to the rocket
when alien.tapped()
  alien.walkRight()
end

# when the alien touches the rocket, take off!
when alien.collidesWith(rocket)
  alien.hide()
  rocket.shake(10, .5).showFlame().takeOff(100)
end
`

export const useCreateNewProjectDocumentsMutation = () => {
  const mutation = useMutation({
    mutationFn: ({
      firstProject,
      projectId,
      mainKidScript,
      headKidScript,
    }: {
      firstProject: boolean
      projectId: string
      mainKidScript?: string
      headKidScript?: string
    }) => {
      if (firstProject) {
        return createBaseProjectDocuments(
          projectId,
          '',
          FIRST_PROJECT_KIDSCRIPT_MAIN
        )
      } else {
        return createBaseProjectDocuments(
          projectId,
          headKidScript || '',
          mainKidScript || ''
        )
      }
    },
  })
  return mutation
}

export const useCreateVersionPlay = () => {
  const mutation = useMutation({
    mutationFn: ({ id }: any) => {
      return createVersionPlay(id)
    },
  })
  return mutation
}
