'use client'
import React, { useMemo, useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useTable } from 'react-table'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

import StaticTableRow from './StaticTableRow'
import DraggableTableRow from '../../../Shared/DraggableTableRow'

import FormCheckbox from '../FormCheckbox'
import KidScriptChangeActionButton from './KidScriptChangeActionButton'
import { StepDependency } from '@kidscript/editor/dist/src/types/MissionStepEditor/StepDependency'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  KidScriptChange,
  KidScriptChanges,
  KidScriptStrategy,
  MissionStepFormValues,
} from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import { Select } from '@codeverse/helios/select'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import { useMainEditorRef } from 'apps/studio-shared/src/Components/Studio/Hooks/useMainEditorRef'
// import { DraggableTableRow } from "./DraggableTableRow";
// import { StaticTableRow } from "./StaticTableRow";

interface DocumentKidScriptChangesProps {
  control: Control<MissionStepFormValues>
  handleSetKidScriptChanges: (
    documentNumber: number,
    changeIndex: number,
    kidscriptChange: KidScriptChange
  ) => void
  documentIndex: 0 | 1
  changes: KidScriptChange[]
  removeKidScriptChange: (
    documentNumber: 0 | 1,
    kidscriptChangeIndex: number
  ) => void
  kidscriptChanges: KidScriptChanges
  setValue: UseFormSetValue<MissionStepFormValues>
  stepDependencies: StepDependency[]
}

type StrategyValueType = 'inherit' | 'append' | 'replace' | 'insert' | 'prepend'
type StrategyLabelType = 'Inherit' | 'Append' | 'Replace' | 'Insert' | 'Prepend'
type StrategyOption = {
  label: StrategyLabelType
  value: StrategyValueType
}

export const kidscriptStrategy = (strategy: StrategyValueType) => {
  switch (strategy) {
    case 'inherit':
      return KidScriptStrategy.inherit
    case 'replace':
      return KidScriptStrategy.replace
    case 'prepend':
      return KidScriptStrategy.prepend
    case 'append':
      return KidScriptStrategy.append
    case 'insert':
      return KidScriptStrategy.insert
  }
}

type StrategyOptions = Array<StrategyOption>

const DocumentKidScriptChanges: React.FC<DocumentKidScriptChangesProps> = ({
  control,
  changes,
  handleSetKidScriptChanges,
  documentIndex,
  removeKidScriptChange,
  kidscriptChanges,
  setValue,
  stepDependencies,
}) => {
  // const { openUI } = useUIState()
  const { setKidscriptEditModal } = useUIStore()
  const { setCurrentKidScriptChange, setChoosingInsertLine } =
    useMissionBuilderStore()
  const mainEditorRef = useMainEditorRef()
  const [activeId, setActiveId] = useState<null | string>(null)
  // const { setCurrentKidScriptChange, setChoosingInsertLine } =
  //   useGuidedMissions()
  const { editorRefs } = useRuntimeRefsStore()
  const items = useMemo(() => changes?.map(({ id }) => id), [changes])

  const headers = useMemo(() => changes?.map(({ id }) => id), [changes])
  const columns = React.useMemo(
    () => [
      {
        Header: 'STRATEGY',
        accessor: 'strategy',
        minWidth: 100,
        Cell: ({ cell: { value }, row }: any) => {
          const hasInherit = changes.find(
            (change) => change.strategy === 'inherit'
          )
          const inheritOption = {
            label: 'Inherit',
            value: 'inherit',
          } as StrategyOption
          const strategyOptions: StrategyOptions = [
            { label: 'Append', value: 'append' },
            { label: 'Prepend', value: 'prepend' },
            { label: 'Replace', value: 'replace' },
            { label: 'Insert', value: 'insert' },
          ]
          if (!hasInherit) {
            strategyOptions.push(inheritOption)
          }
          if (row.original.strategy === 'inherit') {
            strategyOptions.push(inheritOption)
          }
          return (
            <Select
              key={row.original.id}
              context={'light'}
              size="small"
              defaultVal={
                strategyOptions?.find((option) => value === option.value) ||
                strategyOptions[0]
              }
              disabled={stepDependencies.length > 0}
              onChange={(option: StrategyOption) => {
                if (option.value === 'insert') {
                  setChoosingInsertLine(true)
                  return mainEditorRef?.display.pickLine((e) => {
                    setChoosingInsertLine(false)
                    console.log('Line Picked:', e)
                    setCurrentKidScriptChange({
                      changeIndex: row.index,
                      documentIndex: documentIndex,
                      strategyToChange: 'insert',
                      lineAddress: e,
                    })
                    setKidscriptEditModal(true)

                    if (e) {
                    }
                  })
                }
                handleSetKidScriptChanges(
                  documentIndex,
                  row.index,
                  //@ts-ignore
                  Object.assign(
                    {},
                    {
                      ...kidscriptChanges?.documents[documentIndex].changes[
                        row.index
                      ],
                    },
                    {
                      strategy: kidscriptStrategy(option.value),
                    },
                    (option.value === 'replace' ||
                      option.value === 'append' ||
                      option.value === 'prepend') && { kidscript: '' }
                  )
                )
              }}
              options={strategyOptions}
              iconClassname="text-moon-dark"
              // valueClassname="mr-4.5"
            />
          )
        },
      },
      {
        Header: 'KIDSCRIPT PREVIEW',
        Cell: ({ cell, row }: any) => {
          const cx = classNames('mx-4', {
            'opacity-0 pointer-events-none':
              row.original.strategy === 'inherit',
          })
          return (
            <div className="">
              <div
                className={classNames(
                  'font-poppins-regular text-overflow truncate text-ellipsis'
                )}
                style={{ maxWidth: '100px' }}
              >
                {row.original.kidscript}
              </div>
            </div>
          )
        },
        accessor: 'actions',
      },
      {
        Header: 'LOCKED?',
        Cell: ({ cell: { value }, row }) => {
          return (
            <FormCheckbox
              key={row.original.id}
              value={row.original.locked}
              disabled={stepDependencies.length > 0}
              onChange={(checked: any) => {
                const newKidscriptChange = Object.assign({}, row.original, {
                  locked: checked,
                })
                handleSetKidScriptChanges(
                  documentIndex,
                  row.index,
                  newKidscriptChange
                )
              }}
            />
          )
        },
        accessor: 'locked',
      },
      {
        Header: '',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div
              className="flex justify-center"
              key={
                kidscriptChanges?.documents[documentIndex].changes[row.index]
                  ?.id
              }
            >
              <KidScriptChangeActionButton
                handleDelete={() => {
                  if (
                    kidscriptChanges?.documents[documentIndex].changes.length >
                    0
                  ) {
                    removeKidScriptChange(documentIndex, row.index)
                  }
                }}
                handleEdit={() => {
                  setCurrentKidScriptChange({
                    changeIndex: row.index,
                    documentIndex: documentIndex,
                    strategyToChange: row.original.strategy,
                  })
                  setKidscriptEditModal(true)
                }}
                disabled={stepDependencies.length > 0}
              />
            </div>
          )
        },
        accessor: 'remove',
      },
    ],
    [kidscriptChanges, editorRefs, stepDependencies]
  )
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data: changes,
      headers,
      //@ts-ignore
      columns,
    })

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  function handleDragStart(event: any) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event: any) {
    const { active, over } = event
    if (active.id !== over?.id) {
      const tempKidscriptChanges = Object.assign({}, kidscriptChanges)

      const tempActiveKidscriptChange =
        tempKidscriptChanges.documents[documentIndex].changes

      const oldIndex = items.indexOf(active.id)
      const newIndex = items.indexOf(over.id)
      tempKidscriptChanges.documents[documentIndex].changes = arrayMove(
        tempKidscriptChanges.documents[documentIndex].changes,
        oldIndex,
        newIndex
      )
      setValue('kidscript_changes', tempKidscriptChanges)
    }

    setActiveId(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ original }) => original.id === activeId)
    if (row) {
      prepareRow(row)
    }
    return row
  }, [activeId, rows, prepareRow])

  function handleDragCancel() {
    setActiveId(null)
  }

  const errorMessages = useMemo(() => {
    if (stepDependencies && stepDependencies.length > 0) {
      return stepDependencies.map((stepDependency) => {
        return <div className="text-moon-darkest">{stepDependency.message}</div>
      })
    }
    return null
  }, [stepDependencies])

  const tableClassnames = classNames(
    'w-full border-separate border-spacing-y-2 table-auto',
    {
      'cursor-not-allowed': stepDependencies.length > 0,
    }
  )

  if (rows.length === 0) {
    return null
  }

  return (
    <div className="">
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <table
          className={tableClassnames}
          data-tip="table-row"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const thClassname = classNames(
                    'font-poppins-bold text-moon-lightest text-xs text-left',
                    {
                      'pl-[38px]': column.Header === 'STRATEGY',
                      'w-[48px]': column.Header === '',
                    }
                  )
                  return (
                    <th className={thClassname} {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <SortableContext
              //@ts-ignore
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows?.map((row, i) => {
                prepareRow(row)
                return (
                  <DraggableTableRow
                    oneRow={rows.length === 1}
                    key={row.original.id}
                    row={row}
                    disabled={stepDependencies?.length > 0}
                    rowClassName=""
                    activeId={activeId}
                  />
                )
              })}
            </SortableContext>
          </tbody>
        </table>
        {stepDependencies.length > 0 && (
          <ReactTooltip
            className="!bg-mars-dark z-[3000]"
            place="bottom"
            // effect="solid"
            arrowColor="#fe5353"
          >
            {errorMessages}
          </ReactTooltip>
        )}
        <DragOverlay>
          {activeId && (
            <table style={{ width: '100%' }}>
              <tbody>
                <StaticTableRow row={selectedRow} />
              </tbody>
            </table>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

export default DocumentKidScriptChanges
