'use client'

import React from 'react'
import dynamic from 'next/dynamic'
import MarkdownPreview from 'apps/studio-shared/src/Components/Studio/CodeReference/MarkdownPreview'
import { faSquareTerminal, faTerminal } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const MDEditor = dynamic(() => import('@uiw/react-md-editor'), { ssr: false })

interface MarkdownEditorProps {
  type: 'content' | 'detail'
  onChange: (e: any) => void
  value: string
}

const buttonStyles = '!rounded-lg !bg-moon-darkest text-sm !px-1.5 !mx-1'

const Syntax = {
  name: 'Syntax',
  keyCommand: 'Syntax',
  render: (command, disabled, executeCommand) => (
    <FontAwesomeIcon
      onClick={() => executeCommand(command, command.groupName)}
      icon={faTerminal}
      className="px-2 text-sm"
    />
  ),
  execute: (state, api) => {
    let modifyText = `<Syntax>${state.selectedText}</Syntax>\n`
    if (!state.selectedText) {
      modifyText = `<Syntax></Syntax>`
    }
    api.replaceSelection(modifyText)
  },
}

const Var = {
  name: 'Var',
  keyCommand: 'Var',
  render: (command, disabled, executeCommand) => (
    <button
      onClick={(event) => {
        event.stopPropagation()
        executeCommand(command, command.groupName)
      }}
      type="button"
      className={classNames(
        'text-12  font-roboto-mono !text-nebula font-bold',
        buttonStyles
      )}
    >
      var
    </button>
  ),
  execute: (state, api) => {
    let modifyText = `<Syntax color="#FF57D0">${state.selectedText}</Syntax>\n`
    if (!state.selectedText) {
      modifyText = `<Syntax color="#FF57D0"></Syntax>`
    }
    api.replaceSelection(modifyText)
  },
}
const X = {
  name: 'X',
  keyCommand: 'X',
  render: (command, disabled, executeCommand) => (
    <button
      onClick={(event) => {
        event.stopPropagation()
        executeCommand(command, command.groupName)
      }}
      type="button"
      className={classNames(
        ' font-roboto-mono !text-titan text-sm font-bold',
        buttonStyles
      )}
    >
      X
    </button>
  ),
  execute: (state, api) => {
    let modifyText = `<Tip>${state.selectedText}</Tip>\n`
    if (!state.selectedText) {
      modifyText = `<Tip></Tip>`
    }
    api.replaceSelection(modifyText)
  },
}
const Y = {
  name: 'Y',
  keyCommand: 'Y',
  render: (command, disabled, executeCommand) => (
    <button
      onClick={(event) => {
        event.stopPropagation()
        executeCommand(command, command.groupName)
      }}
      type="button"
      className={classNames(
        ' font-roboto-mono !text-titan text-sm font-bold',
        buttonStyles
      )}
    >
      Y
    </button>
  ),
  execute: (state, api) => {
    let modifyText = `<Tip>${state.selectedText}</Tip>\n`
    if (!state.selectedText) {
      modifyText = `<Tip></Tip>`
    }
    api.replaceSelection(modifyText)
  },
}
const Tip = {
  name: 'Tip',
  keyCommand: 'Tip',
  render: (command, disabled, executeCommand) => (
    <button
      onClick={(event) => {
        event.stopPropagation()
        executeCommand(command, command.groupName)
      }}
      type="button"
      className={classNames(
        ' font-roboto-mono !text-sun text-sm font-bold',
        buttonStyles
      )}
    >
      Tip
    </button>
  ),
  execute: (state, api) => {
    let modifyText = `<Tip>${state.selectedText}</Tip>\n`
    if (!state.selectedText) {
      modifyText = `<Tip></Tip>`
    }
    api.replaceSelection(modifyText)
  },
}

const ObjectName = {
  name: 'ObjectName',
  keyCommand: 'ObjectName',
  render: (command, disabled, executeCommand) => (
    <button
      onClick={(event) => {
        event.stopPropagation()
        executeCommand(command, command.groupName)
      }}
      type="button"
      className={classNames(
        ' font-roboto-mono text-sm font-bold !text-[#a594ff]',
        buttonStyles
      )}
    >
      Object
    </button>
  ),
  execute: (state, api) => {
    let modifyText = `<Syntax color="#a594ff">${state.selectedText}</Syntax>\n`
    if (!state.selectedText) {
      modifyText = `<Syntax color="#a594ff"></Tip>`
    }
    api.replaceSelection(modifyText)
  },
}

const commands = [Syntax, Var, ObjectName, X, Y, Tip]

const MarkdownEditor = React.forwardRef(({ ...props }, ref) => {
  return (
    <MDEditor
      //@ts-ignore
      onChange={props.onChange}
      extraCommands={commands}
      components={{
        preview: (source, state, dispath) => {
          return (
            <MarkdownPreview
            // customComponents={{}}
            // fontClassName={classNames("font-poppins-regular", buttonStyles)}
            // theme="light"
            // type="default"
            >
              {source || ''}
            </MarkdownPreview>
          )
        },
      }}
      height={400}
      {...props}
    />
    // <textarea value="" onChange={() => {}} />
  )
})
export default MarkdownEditor
