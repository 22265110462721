import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useCreateMissionStepInsertable } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import Modal from '@codeverse/helios/modal'
import Input from '@codeverse/helios/input'
import Button from '@codeverse/helios/button'
import { usePathname } from 'next/navigation'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'

interface MissionStepInsertableCoordiantesProps {}

const MissionStepInsertableCoordiantes: React.FC<
  MissionStepInsertableCoordiantesProps
> = () => {
  const pathname = usePathname()
  const { currentStep } = useMissionStore()
  const {
    setMissionInsertablesToCreate,
    missionInsertablesToCreate,
    missionInsertableToSave,
    currentPickedLineAddress,
    setMissionInsertableToSave,
    setPickLine,
  } = useMissionBuilderStore()
  const { setToolboxMissionStepInsertable } = useRuntimeStore()
  const {
    setMissionStepInsertableCoordinates,
    missionStepInsertableCoordinates,
  } = useUIStore()

  const createMissionInsertableMutation = useCreateMissionStepInsertable()

  const queryClient = useQueryClient()

  const [{ x, y }, setCoordinates] = React.useState({ x: '0', y: '0' })

  const isNewMissionStep = pathname.includes('new')

  const handleSubmit = () => {
    if (isNewMissionStep) {
      setMissionInsertablesToCreate([
        ...missionInsertablesToCreate,
        {
          ...missionInsertableToSave,
          line_address: currentPickedLineAddress,
          x,
          y,
        },
      ])
      setMissionInsertableToSave(null)
      setPickLine(false)
      setMissionStepInsertableCoordinates(false)
      setToolboxMissionStepInsertable(true)
    } else {
      createMissionInsertableMutation.mutate(
        {
          missionStepId: currentStep?.id,
          ...missionInsertableToSave,
          line_address: currentPickedLineAddress,
          x,
          y,
        },
        {
          onSuccess: (data) => {
            setPickLine(true)
            setMissionInsertableToSave(null)
            queryClient.invalidateQueries({
              queryKey: ['missionStepInsertables', currentStep?.id],
            })
            setMissionStepInsertableCoordinates(false)
            setToolboxMissionStepInsertable(true)
          },
        }
      )
    }
  }

  const handleCancel = () => {
    setMissionInsertableToSave(null)
    setMissionStepInsertableCoordinates(false)
    setPickLine(false)
  }

  return (
    <Modal
      id="missionStepInsertableCoodiantes"
      isOpen={missionStepInsertableCoordinates}
      onClose={() => setMissionStepInsertableCoordinates(false)}
      width={543}
      height={'auto'}
      containerClassname="items-center"
      contentClassname="!rounded-28 mx-4 z-3000"
    >
      <div className="text-moon-darkest px-6 py-8">
        <h3 className="text-moon-darkest pb-4">Object Coordiantes</h3>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="font-poppins-semibold text-lg">X</div>
            <Input
              value={x}
              onChange={(e) =>
                setCoordinates((prevState: any) => ({
                  ...prevState,
                  x: e.target.value,
                }))
              }
              type="number"
            />
          </div>

          <div>
            <div className="font-poppins-semibold text-lg">Y</div>
            <Input
              value={y}
              onChange={(e) =>
                setCoordinates((prevState: any) => ({
                  ...prevState,
                  y: e.target.value,
                }))
              }
              type="number"
            />
          </div>
        </div>
        <div className="mt-6 grid grid-cols-2 gap-8">
          <Button size="regular" onClick={handleSubmit} context="primary">
            Save
          </Button>
          <Button size="regular" onClick={handleCancel} context="secondary">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MissionStepInsertableCoordiantes
