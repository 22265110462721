import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import Modal from '@codeverse/helios/modal'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { TextArea } from '@codeverse/helios/textarea'
import Button from '@codeverse/helios/button'
import { useMainEditorRef } from 'apps/studio-shared/src/Components/Studio/Hooks/useMainEditorRef'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateMissionStepInsertable } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { usePathname } from 'next/navigation'

interface MissionStepInsertableKidScriptModalProps {
  // documents: any
  // documentIndex: number
  // changeIndex: number
  // handleSetKidScriptChanges: (
  //   documentNumber: number,
  //   changeIndex: number,
  //   kidscriptChange: KidScriptChange
  // ) => void
  addKidScriptChange: any
}

const MissionStepInsertableKidScriptModal: React.FC<
  MissionStepInsertableKidScriptModalProps
> = ({ addKidScriptChange }) => {
  const pathname = usePathname()
  const missionEditorDisplayType = pathname.includes('/new')
    ? 'newMissionStep'
    : 'editMissionStep'
  const { currentStep } = useMissionStore()
  const { addMissionInsertablesToCreate, setChoosingInsertLine } =
    useMissionBuilderStore()
  const { missionStepInsertableKidScript, setMissionStepInsertableKidScript } =
    useUIStore()
  const mainEditorRef = useMainEditorRef()
  const [kidScriptValue, setKidScriptValue] = useState('')
  const queryClient = useQueryClient()

  // const {
  //   state: GuidedMissionState,
  //   setMissionInsertablesToCreate,
  //   setMissionInsertablesToUpdate,
  // } = useGuidedMissions()
  // const { state: UIState, closeUI } = useUIState()
  // const { missionStepInsertableKidScript } = UIState
  // const {
  //   state: { currentStep },
  //   setChoosingInsertLine,
  // } = useGuidedMissions()
  // const { editorRef } = useRuntime()
  const createMissionInsertableMutation = useCreateMissionStepInsertable()
  // const queryClient = useQueryClient()

  // const { missionEditorDisplayType } = GuidedMissionState

  // const inputClassnames = classNames(
  //   'focus:outline-none focus:ring focus:ring-neptune font-poppins-medium pl-[16px] placeholder-moon pr-1 rounded-sm text-base text-moon-darkest w-full py-2 resize-none bg-moon-lightest !min-h-[56px] leading-24 mb-4'
  // )

  // useEffect(() => {
  //   if (missionStepInsertableKidScript) {
  //     setKidScriptValue('')
  //   }
  // }, [missionStepInsertableKidScript])

  const handleSave = (lineAddress: any) => {
    if (missionEditorDisplayType === 'editMissionStep') {
      createMissionInsertableMutation.mutate(
        {
          missionStepId: currentStep.id,
          kidscript: kidScriptValue,
          display: 'snippet',
          line_address: lineAddress,
        },
        {
          onSuccess: (data) => {
            setChoosingInsertLine(false)
            queryClient.invalidateQueries({
              queryKey: ['missionStepInsertables', currentStep.id],
            })
            setKidScriptValue('')
            setMissionStepInsertableKidScript(false)
          },
        }
      )
    } else {
      setChoosingInsertLine(false)
      addMissionInsertablesToCreate({
        kidscript: kidScriptValue,
        display: 'snippet',
        line_address: lineAddress,
        id: uuidv4(),
      })
      setKidScriptValue('')
      setMissionStepInsertableKidScript(false)
    }
  }

  const kidscriptHtml = useMemo(() => {
    //@ts-ignore
    return KidScript.parseBlock(kidScriptValue).toHTML()
  }, [kidScriptValue])

  return (
    <Modal
      id="MissionStepInsertableKidScriptModal"
      isOpen={missionStepInsertableKidScript}
      onClose={() => setMissionStepInsertableKidScript(false)}
      width={543}
      height={'auto'}
      containerClassname="items-center"
      contentClassname="!rounded-3xl mx-4 z-3000"
    >
      <div className="px-8 pb-8 pt-10">
        <div>
          <h3 className="!mb-1.5 text-center">Add KidScript Insertable</h3>
          <p className="font-poppins-bold !pb-.5 mt-6 text-base">KidScript</p>
          <TextArea
            setValue={(e: any) => {
              e.stopPropagation()
              setKidScriptValue(e.target.value)
            }}
            value={kidScriptValue}
            className=""
          />
          <p className="font-poppins-bold !pb-2 text-base">KidScript Preview</p>
          <div className="bg-moon-darkest relative mb-4 flex-grow rounded-lg px-4 py-1">
            <pre
              className="kidscript-syntax overflow-x-scroll !pb-0"
              dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
            />
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-4">
          <Button
            size="regular"
            id="MissionBuilder_Save_KidScriptChange_KidScript"
            context="earthDark"
            onClick={(e) => {
              // closeUI('missionStepInsertableKidScript')
              setChoosingInsertLine(true)
              setMissionStepInsertableKidScript(false)
              if (!mainEditorRef) return
              mainEditorRef.display.pickLine((e) => {
                console.log('LINE PICK: ', e)
                handleSave(e)
              })
            }}
          >
            Next
          </Button>
          <Button
            size="regular"
            id="MissionBuilder_Cancel_KidScriptChange_KidScript"
            context="secondary"
            onClick={() => setMissionStepInsertableKidScript(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MissionStepInsertableKidScriptModal
