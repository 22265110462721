import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Select } from '@codeverse/helios/select'
import { MissionStepFormValues } from '../types'

type DocumentValueType = 0 | 1
type DocumentLabelType = 'Head.ks' | 'Main.ks'

export type DocumentOption = {
  label: DocumentLabelType
  value: DocumentValueType
}
type DocumentOptions = Array<DocumentOption>
export const documentOptions: DocumentOptions = [
  { label: 'Main.ks', value: 1 },
  { label: 'Head.ks', value: 0 },
]

interface DocumentProps {
  control: Control<MissionStepFormValues>
}

const DocumentField: React.FC<DocumentProps> = ({ control }) => {
  return (
    <div className="">
      <div className="mb-6">
        <h4 className="leading-24 !mb-0 text-white">Document</h4>
        <p className="text-neptune-lightest leading-24 mb-4 text-xs">
          The document that will be displayed to the user
        </p>
        <Controller
          name="document"
          control={control}
          render={({ field }) => (
            <Select
              context={'light'}
              defaultVal={
                documentOptions?.find(
                  //@ts-ignore
                  (option) => field.value?.value === option.value
                ) || documentOptions[0]
              }
              size="large"
              onChange={(option) => field.onChange(option)}
              options={documentOptions}
              className=""
              ref={field.ref}
            />
          )}
        />
      </div>
    </div>
  )
}

export default DocumentField
