import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Control } from 'react-hook-form'
import { MissionStepFormValues } from '../types'
import useSingleState from './useSingleState'
import Input from '@codeverse/helios/input'
import useQuizStateStore from 'apps/studio-shared/src/Stores/useQuizStateStore'
import { Select } from '@codeverse/helios/select'
import { useMissionStepsMultipleChoice } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'

interface QuizFormProps {
  control: Control<MissionStepFormValues>
  type: 'new' | 'edit'
  questionState?: {
    question: string
    answerA: string
    answerB: string
    answerC: string
    answerD: string
    answer: string
  }
  setState?: (any) => void
  setValue?: any
}

type MultipleChoiceValueType = 'a' | 'b' | 'c' | 'd'
type MultipleChoiceLabelType = 'A' | 'B' | 'C' | 'D'

type MultipleChoiceOption = {
  label: MultipleChoiceLabelType
  value: MultipleChoiceValueType
}

type MultipleChoiceOptions = Array<MultipleChoiceOption>

const multipleChoiceOptions: MultipleChoiceOptions = [
  { label: 'A', value: 'a' },
  { label: 'B', value: 'b' },
  { label: 'C', value: 'c' },
  { label: 'D', value: 'd' },
]

const QuizForm: React.FC<QuizFormProps> = ({
  control,
  setState,
  setValue,
  questionState,
}) => {
  // const quizStateStore = useHookstate(quizStore)
  // const missionMatch = useMatch('/mission_builder/:missionId')
  // const missionId = missionMatch?.params
  // const { state: GuidedMissionState } = useGuidedMissions()
  // const { currentStep } = GuidedMissionState
  const { currentStep } = useMissionStore()
  const { data: MultipleChoiceData, isFetched } = useMissionStepsMultipleChoice(
    currentStep?.id
  )

  const {
    question,
    answer,
    answer_a,
    answer_b,
    answer_c,
    answer_d,
    setAnswer,
    setQuestion,
    setAnswerA,
    setAnswerB,
    setAnswerC,
    setAnswerD,
  } = useQuizStateStore()

  const inputGroupClassnames = classNames('mb-4')
  const inputClassname = classNames('!py-2')

  const headerClassnames = classNames('!pb-[4px] text-white text-2xl mb-2')
  const defaultValue = useMemo(() => {
    return (
      multipleChoiceOptions.find((option) => {
        return option.value === answer
      }) || multipleChoiceOptions[0]
    )
  }, [])

  useEffect(() => {
    if (isFetched && MultipleChoiceData?.length > 0) {
      const { question, answer_a, answer_b, answer_c, answer_d, answer } =
        MultipleChoiceData[0]
      setAnswer(answer)
      setQuestion(question)
      setAnswerA(answer_a)
      setAnswerB(answer_b)
      setAnswerC(answer_c)
      setAnswerD(answer_d)
    }
  }, [MultipleChoiceData, isFetched])

  useEffect(() => {
    setValue('display', 'canvas')
  }, [])

  return (
    <div className="mb-12 mt-4">
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Question</h4>
        <Input
          inputClassName={inputClassname}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </div>
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Answer A</h4>
        <Input
          inputClassName={inputClassname}
          value={answer_a}
          onChange={(e) => setAnswerA(e.target.value)}
        />
      </div>
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Answer B</h4>
        <Input
          inputClassName={inputClassname}
          value={answer_b}
          onChange={(e) => setAnswerB(e.target.value)}
        />
      </div>
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Answer C</h4>
        <Input
          inputClassName={inputClassname}
          value={answer_c}
          onChange={(e) => setAnswerC(e.target.value)}
        />
      </div>
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Answer D</h4>
        <Input
          inputClassName={inputClassname}
          value={answer_d}
          onChange={(e) => setAnswerD(e.target.value)}
        />
      </div>
      <div className={inputGroupClassnames}>
        <h4 className={headerClassnames}>Correct Answer</h4>
        <Select
          className="text-moon-darkest"
          context={'light'}
          size="regular"
          options={multipleChoiceOptions}
          onChange={(option: any) => setAnswer(option.value)}
          defaultVal={defaultValue}
        ></Select>
      </div>
    </div>
  )
}

export default QuizForm
