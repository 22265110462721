import classNames from 'classnames'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Select } from '@codeverse/helios/select'
import { MissionStepFormValues } from '../types'
import Header from './Header'

type DisplayValueType = 'modal' | 'line' | 'canvas' | 'fixed' | 'fullscreen'
type DisplayLabelType = 'Modal' | 'Line' | 'Canvas' | 'Fixed' | 'Fullscreen'
export type DisplayOption = {
  label: DisplayLabelType
  value: DisplayValueType
}

type DisplayOptions = Array<DisplayOption>

export const displayOptions: DisplayOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Line', value: 'line' },
  { label: 'Canvas', value: 'canvas' },
  { label: 'Modal', value: 'modal' },
  // { label: 'Fullscreen', value: 'fullscreen' },
]
export const displayOptionsQuiz: DisplayOptions = [
  { label: 'Canvas', value: 'canvas' },
  // { label: 'Fullscreen', value: 'fullscreen' },
]
export const displayOptionsCode: DisplayOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Line', value: 'line' },
  { label: 'Canvas', value: 'canvas' },
  { label: 'Modal', value: 'modal' },
  // { label: 'Fullscreen', value: 'fullscreen' },
]

interface DisplayProps {
  headerClassnames: string
  control: Control<MissionStepFormValues>
  currentStep: any
  getValues: any
  setEditorValues: () => void
}

const Display: React.FC<DisplayProps> = ({
  headerClassnames,
  control,
  currentStep,
  getValues,
  setEditorValues,
}) => {
  return (
    <div className="mb-6">
      <Header
        label="Display"
        description="Change the way the step is displayed"
      />
      <Controller
        name="display"
        control={control}
        render={({ field }) => {
          if (!field.value) return <></>
          if (currentStep?.boilerplate === 'quiz') {
            return (
              <Select
                size="large"
                debug
                key="1"
                context={'light'}
                defaultVal={displayOptionsQuiz?.find(
                  (option) => field.value === option.value
                )}
                // currentValue={displayOptionsQuiz?.find(
                //   (option) => field.value === option.value
                // )}
                onChange={(option) => {
                  field.onChange(option.value)
                  setEditorValues()
                }}
                options={displayOptionsQuiz}
              />
            )
          } else if (currentStep?.boilerplate === 'code') {
            return (
              <Select
                debug
                key="1"
                context={'light'}
                defaultVal={displayOptionsCode.find(
                  (option) => field.value === option.value
                )}
                // currentValue={displayOptionsCode?.find(
                //   (option) => field.value === option.value
                // )}
                size="large"
                onChange={(option) => {
                  field.onChange(option.value)
                  setEditorValues()
                }}
                options={displayOptionsCode}
              />
            )
          }
          return (
            <Select
              key="2"
              context={'light'}
              defaultVal={displayOptions.find(
                (option) => field.value === option.value
              )}
              // currentValue={displayOptions.find(
              //   (option) => field.value === option.value
              // ) || displayOptions[0]}
              onChange={(option) => {
                field.onChange(option.value)
                setEditorValues()
              }}
              size="large"
              options={displayOptions}
              ref={field.ref}
            />
          )
        }}
      />
    </div>
  )
}

export default Display
