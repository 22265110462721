import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import React, { useEffect, useMemo, useState } from 'react'

interface LibraryFiltersProps {
  library: any
}

const LibraryFilters: React.FC<LibraryFiltersProps> = ({ library }) => {
  const {
    // state: { objects },
    setShowToolbox,
    setToolboxLibraryFilter,
    kidScriptDependenciesFetched,
  } = useRuntimeStore()

  const { setCurrentLibraryFilterObject } = useMissionBuilderStore()
  const libraryFilter = useMemo(() => library, [library])

  const [objects, setObjects] = useState(null)
  const [objectsLoaded, setObjectsLoaded] = useState(false)

  useEffect(() => {
    if (kidScriptDependenciesFetched) {
      //@ts-ignore
      setObjects(KidScript.library.objects)
    }
  }, [kidScriptDependenciesFetched])

  const SelectedObjects = useMemo(() => {
    if (libraryFilter && libraryFilter.objects) {
      return libraryFilter.objects.map((object: any) => {
        const { name } = object
        const kidscriptObject = objects && objects[name]
        return (
          <div>
            <div
              className="relative flex flex-auto cursor-pointer rounded-lg bg-white"
              style={{
                height: '0%',
                paddingTop: '130%',
                // x,
                // y,
                touchAction: 'none',
              }}
              onMouseOver={() => {
                //@ts-ignore
                kidscriptObject.ensureLoaded()
              }}
              onClick={() => {
                setCurrentLibraryFilterObject(kidscriptObject)
                setToolboxLibraryFilter(true)
                setShowToolbox(true)
              }}
            >
              <img
                className="absolute left-1/2 top-1/2"
                // className="absolute left-1/2 top-1/2"
                draggable={false}
                style={{
                  maxWidth: '70%',
                  transform: 'translate(-50%, -63%)',
                  maxHeight: '96px',
                  userSelect: 'none',
                }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  //@ts-ignore
                  kidscriptObject?.getIcon()
                )}`}
                alt=""
              />
              <div className="text-moon-darkest font-poppins-bold absolute bottom-4 left-1/2 -translate-x-1/2 transform">
                {name}
              </div>
            </div>
            <div className="mt-2 rounded-lg bg-white p-1">
              <div>
                <div className="font-poppins-bold text-moon-darkest">
                  Methods
                </div>
                <div>
                  {object.methods?.map((method: any) => {
                    return (
                      <div className="font-poppins text-moon-darkest">
                        {method.name}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <div className="font-poppins-bold text-moon-darkest">
                  Constants
                </div>
                <div>
                  {object.constants?.map((method: any) => {
                    return (
                      <div>
                        <div className="font-poppins text-moon-darkest">
                          {method.name}
                        </div>
                        <div className="">
                          {method.values.map((value: any) => {
                            return (
                              <div className="font-poppins text-moon-darkest ml-4">
                                "{value}"
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <div className="font-poppins-bold text-moon-darkest">
                  Events
                </div>
                <div>
                  {object.events?.map((method: any) => {
                    return (
                      <div className="font-poppins text-moon-darkest">
                        {method.name}
                      </div>
                    )
                  })}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )
      })
    }
    return null
  }, [libraryFilter, objects])

  return <div className="mb-4.5 grid grid-cols-3 gap-4">{SelectedObjects}</div>
}

export default LibraryFilters
