import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

// const div = styled.div`
//   height: 1rem;
//   vertical-align: bottom;
//   display: inline-block;
//   margin-right: 0.5rem;
//   svg {
//     width: 100%;
//     height: 100%;
//   }
//   cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};
// `;

export const DragHandle = (props: any) => {
  const divClassnames = classNames(
    'mr-1.5 my-auto text-white',
    props.className,
    {
      'cursor-pointer': props.isDragging,
    }
  )
  return (
    <div {...props} className={divClassnames}>
      <FontAwesomeIcon icon={faGripDotsVertical} />
    </div>
  )
}
