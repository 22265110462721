'use client'
//@ts-nocheck
import React, { useState, useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { useOuterClick, usePrevious } from '@codeverse/hooks'
import { cva } from 'class-variance-authority'
import { cn } from './cn'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'

export type Option = {
  value: string | number
  label: string
}

const labelTheme = {
  base: 'absolute top-4 left-1.5 text-lg text-moon pointer-events-none font-system px-6',
  // check and radio get this same style
  check: 'inline-flex items-center',
  invalid: 'text-mars-dark',
  valid: 'text-titan-darkalt',
  disabled: 'opacity-50 cursor-not-allowed',
  size: {
    regular: '',
    large: 'top-[22px] text-[20px] font-poppins-semibold',
  },
  focused: {
    large: 'font-system',
  },
}

const selectStyles = cva(['relative'], {
  variants: {
    size: {
      small: 'h-8',
      regular: 'h-12',
      large: 'h-14',
    },
    context: {
      light: 'bg-moon-lightest',
      dark: 'bg-moon-lightalt2 !text-white',
    },
    disabled: {
      true: 'cursor-not-allowed',
      false: 'cursor-pointer',
    },
    isOpen: {
      yes: 'rounded-t-lg',
      no: 'rounded-lg',
    },
  },
})

const selectValueStyles = cva(
  ['flex', 'flex-row', 'font-poppins-semibold', 'select-none'],
  {
    variants: {
      context: {
        light: 'text-moon-dark',
        dark: 'text-white',
      },
      size: {
        small: 'pr-2 text-sm',
        regular: 'pr-4 text-lg',
        large: 'pr-4 text-xl',
      },
      disabled: {
        yes: 'cursor-not-allowed opacity-50',
        no: 'cursor-pointer',
      },
    },
  }
)

const iconStyles = cva(
  ['text-moon-light ml-auto flex flex-grow items-center justify-end'],
  {
    variants: {
      context: {
        light: 'text-moon-dark',
        dark: 'text-white',
      },
      size: {
        small: 'mr-2 text-sm',
        regular: 'mr-3 text-lg',
        large: 'mr-3 text-xl',
      },
    },
  }
)

const currentValueStyles = cva(
  ['font-poppins-semibold relative flex h-auto flex-row text-lg'],
  {
    variants: {
      context: {
        light: 'text-moon-dark',
        dark: 'text-white',
      },
      size: {
        small: 'pl-3 pt-1.5 text-sm',
        regular: 'pl-6 pt-3 text-lg',
        large: 'pl-6 pt-4 text-xl',
      },
      disabled: {
        yes: 'cursor-not-allowed opacity-50',
        no: 'cursor-pointer',
      },
    },
  }
)

const dropdownStyles = cva(
  [
    'absolute',
    'h-auto',
    'rounded-b-8',
    'shadow-lg',
    'w-full',
    'z-[3001]',
    'rounded-b-lg',
    'rounded-t-8',
  ],
  {
    variants: {
      context: {
        light: 'bg-white',
        dark: 'bg-moon-lightalt2',
      },
      size: {
        small: 'top-8',
        regular: 'top-12',
        large: 'top-14',
      },
    },
  }
)

const optionStyles = cva(
  [
    'font-poppins-semibold z-[3002] flex w-full cursor-pointer select-none items-center last:rounded-b-lg',
  ],
  {
    variants: {
      size: {
        small: 'py-2 pl-4 text-xs',
        regular: 'py-3 pl-6 text-sm',
        large: 'py-3 pl-6 text-sm',
      },
    },
  }
)

const select = {
  light: 'bg-moon-lightest',
  dark: 'bg-moon-lightalt2 !text-white',
  label: {
    base: 'absolute left-6 top-2 text-xs',
    light: 'text-moon font-sf-pro-text-regular',
    dark: 'text-white',
  },
  value: {
    withLabel: 'mt-2',
    light:
      'text-moon-lightalt2 overflow-ellipsis overflow-hidden whitespace-nowrap w-full',
    dark: 'text-white',
    size: {
      small: '',
      regular: '',
      large: '',
    },
  },
  dropdown: {
    light: 'bg-white',
    dark: 'bg-moon-lightalt2',
  },
  noOptions: 'font-poppins-bold text-center pt-2',
  dropdownText: {
    base: '',
    light: 'text-moon-dark',
    dark: 'text-white',
  },
  dropdownHover: {
    light: 'hover:bg-earth-lightest',
    dark: 'hover:bg-moon',
  },
}

//@ts-ignore
export interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Defines the color of the select
   */
  valid?: boolean
  options: Array<Option>
  defaultVal: Option | undefined
  onChange?: (option: any) => void
  optionClassnames?: string
  currentClassname?: string
  labelClassname?: string
  iconClassname?: string
  valueClassname?: string
  changedValue?: Option
  debug?: boolean
  defaultOpen?: boolean
  placeholder?: string
  context?: 'light' | 'dark'
  disabled?: boolean
  label?: string
  dropdownClassname?: string
  size: 'regular' | 'large' | 'small'
}

const Select = function Select(props: Props, ref: any) {
  const {
    valid,
    children,
    className,
    dropdownClassname,
    disabled,
    options,
    defaultVal,
    onChange,
    label,
    optionClassnames,
    currentClassname,
    labelClassname,
    iconClassname,
    valueClassname,
    changedValue,
    context = 'light',
    debug,
    placeholder,
    defaultOpen,
    size,
    ...other
  } = props

  const [currentValue, setCurrentValue] = useState(defaultVal)

  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [focused, setFocused] = useState(false)

  // useEffect(() => {
  //   if (!currentValue) {
  //     console.log('setting currentValue to defaultVal', defaultVal)
  //     setCurrentValue(defaultVal)
  //   }
  // }, [currentValue])

  // useEffect(() => {
  //   if (!previousValue) return
  //   if (typeof onChange === 'function' && previousValue !== currentValue) {
  //     console.log('setting to currentValue', currentValue)
  //     onChange(currentValue)
  //   }
  // }, [currentValue, onChange, previousValue])

  const dropdownClasses = classNames(
    'absolute h-auto rounded-b-8 shadow-lg w-full z-[3001]',
    select.dropdown[context],
    'rounded-b-lg rounded-t-8',
    {
      'top-[40px]': size === 'small',
      'top-[56px]': size === 'regular',
      'top-[64px]': size === 'large',
    }
  )
  const currentClassnames = classNames(
    currentValueStyles({
      context,
      size,
      disabled: disabled ? 'yes' : 'no',
    }),
    currentClassname
  )

  const menuItemsRef = useRef()

  useEffect(() => {
    if (isOpen && menuItemsRef) {
      const el =
        //@ts-ignore
        menuItemsRef.current?.children[
          //@ts-ignore
          menuItemsRef.current.children.length - 1
        ]

      // el?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      // })
    }
  }, [isOpen, menuItemsRef])

  const handleFocus = () => {
    setFocused(true)
  }
  const handleBlur = () => {
    setFocused(false)
  }

  const menuItems = useMemo(() => {
    if (!options) {
      const noOptionClassname = classNames(
        select.noOptions,
        select.dropdownText.base,
        select.dropdownText[context]
      )
      return (
        <div
          style={{
            height: '40px',
            backgroundColor: select.dropdown[context],
          }}
          className={noOptionClassname}
          onClick={() => {
            console.log('no options')
            // setCurrentValue(option)
            setIsOpen(false)
          }}
        >
          No Options
        </div>
      )
    }
    return options.map((option, index) => {
      const optionClassname = classNames(
        optionStyles({
          size,
        }),
        select.dropdownHover[context],
        option?.value === defaultVal?.value
          ? 'text-white'
          : select.dropdownText[context],
        optionClassnames
      )
      return (
        <div
          key={option?.value + '-' + index}
          style={{
            height: 'auto',
            backgroundColor:
              option?.value === defaultVal?.value
                ? '#0080ff'
                : select.dropdown[context],
          }}
          className={optionClassname}
          onClick={() => {
            //@ts-ignore
            onChange(option)
            setIsOpen(false)
          }}
        >
          {option?.label}
        </div>
      )
    })
  }, [options, select, defaultVal])

  const menuDropdownFiller = classNames(
    '-top-12 absolute h-12 w-full z-1',
    select.dropdown[context]
  )

  const labelClassnames = useMemo(() => {
    return classNames(select.label.base, select.label[context], labelClassname)
  }, [select])

  const valueClassnames = useMemo(() => {
    return classNames(
      select.value[context],
      {
        [select.value.withLabel]: !!label,
      },
      valueClassname,
      'pr-4'
    )
  }, [select, valueClassname])

  const iconClassnames = classNames(
    iconStyles({
      context,
      size,
    }),
    iconClassname
  )

  const innerRef = useRef()

  useOuterClick(innerRef, () => setIsOpen(false))

  return (
    <div
      className={selectStyles({
        size,
        context,
        disabled,
        isOpen: isOpen ? 'yes' : 'no',
      })}
      //@ts-ignore
      ref={innerRef}
    >
      {/* <input className="hidden" ref={ref}></input> */}
      {/* {label && <Label className={labelStyle}>{label}</Label>} */}
      <div
        className={currentClassnames}
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen)
          }
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {label && <div className={labelClassnames}>{label}</div>}
        <div
          className={cn(
            selectValueStyles({
              context,
              size,
              disabled: disabled ? 'yes' : 'no',
            }),
            className
          )}
        >
          {defaultVal?.label || placeholder}
        </div>
        <div className={iconClassnames}>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {/* {isOpen && <div className="absolute -bottom-1 h-4 w-full bg-white" />} */}
      {isOpen && (
        <div
          //@ts-ignore
          ref={menuItemsRef}
          className={dropdownStyles({
            context,
            size,
          })}
          style={{}}
        >
          {/* {isOpen && <div className={menuDropdownFiller} />} */}
          {menuItems}
        </div>
      )}
    </div>
  )
}

export default React.forwardRef(Select)
