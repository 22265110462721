import React, { useEffect } from 'react'
import { json } from '@codemirror/lang-json'
import CodeMirror from '@uiw/react-codemirror'
import { githubDark } from '@uiw/codemirror-theme-github'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { Select } from '@codeverse/helios/select'
import { UseFormSetValue } from 'react-hook-form'
import { MissionStepFormValues } from '../types'
import { useMainEditorRef } from 'apps/studio-shared/src/Components/Studio/Hooks/useMainEditorRef'
import Header from './Header'

interface ValidationTargetProps {
  setValue: UseFormSetValue<MissionStepFormValues>
}

const filterOptions = [
  { value: 'editor', label: 'Editor' },
  { value: 'kidscript', label: 'KidScript' },
  { value: 'interpreter', label: 'Interpreter' },
]

const ValidationTarget: React.FC<ValidationTargetProps> = ({ setValue }) => {
  const {
    currentStep,
    setCurrentStep,
    setInitialReplayMissionSteps,
    interpreterEvents,
    kidscriptCompileData,
    setKidScriptCompileData,
  } = useMissionStore()

  const editorRef = useMainEditorRef()

  // Set KidScript Compile Data
  useEffect(() => {
    if (editorRef) {
      const handleCompile = () => {
        const ks = editorRef.getKidScript()

        try {
          //@ts-ignore
          const ksJSON = KidScript.parseDocuments(
            ks,
            //@ts-ignore
            KidScript.Version
            //@ts-ignore
          ).toJSON()
          setKidScriptCompileData(ksJSON)
        } catch (e) {
          console.log('Error parsing KidScript JSON', e)
        }
      }
      editorRef.compiler.on('compiled', handleCompile)
      return () => {
        editorRef.compiler.on('compiled', handleCompile)
      }
    }
  }, [editorRef])

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center">
        <Header
          label="Automatic Progression"
          description="Write some JSON logic to automatically progress the user to the next step"
        />
        <div className="ml-auto">
          <Button
            className="!pl-4 pr-6 shadow-none"
            size="small"
            context="nebula"
            onClick={(e) => {
              e.preventDefault()
              setValue('validation_rules', '{}', {
                shouldDirty: true,
              })
              setValue('validation_target', 'interpreter', {
                shouldDirty: true,
              })
            }}
          >
            <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
            Add
          </Button>
        </div>
      </div>

      {currentStep?.validation_target?.length > 0 && (
        <div className="bg-neptune-darkest rounded-2xl bg-opacity-20 p-4">
          {currentStep?.validation_target && (
            <div className="mb-4">
              <Select
                size="regular"
                context={'light'}
                defaultVal={filterOptions.find(
                  (option) => currentStep?.validation_target === option.value
                )}
                onChange={(option) =>
                  setValue('validation_target', option.value)
                }
                options={filterOptions}
              />
            </div>
          )}
          {currentStep?.validation_target && (
            <div>
              <div className="bg-neptune-dark font-poppins-semibold px-4.5 w-2/4 rounded-t-xl bg-opacity-50 py-6">
                Validation Rules
              </div>
              <CodeMirror
                className="bg-moon-darkest"
                value={
                  typeof currentStep.validation_rules === 'string'
                    ? currentStep.validation_rules
                    : JSON.stringify(currentStep.validation_rules, null, 2)
                }
                height="300px"
                onChange={(value) => {
                  setValue('validation_rules', value)
                }}
                extensions={[json()]}
                editable={true}
                theme={githubDark}
              />
            </div>
          )}
          {currentStep?.validation_target &&
            currentStep?.validation_target === 'interpreter' && (
              <div className="mt-4">
                <div className="bg-neptune-dark font-poppins-semibold px-4.5 w-2/4 rounded-t-xl bg-opacity-50 py-6">
                  Output
                </div>
                <CodeMirror
                  className="bg-moon-darkest"
                  value={
                    interpreterEvents?.length > 0
                      ? typeof interpreterEvents[0] === 'string'
                        ? interpreterEvents[0]
                        : JSON.stringify(interpreterEvents[0], null, 2)
                      : '{}'
                  }
                  height="300px"
                  extensions={[json()]}
                  editable={true}
                  theme={githubDark}
                />
              </div>
            )}
          {currentStep?.validation_target &&
            currentStep?.validation_target === 'kidscript' && (
              <div className="mt-4">
                <div className="bg-neptune-dark font-poppins-semibold px-4.5 rounded-t-12 w-2/4 bg-opacity-50 py-6">
                  Output
                </div>
                <CodeMirror
                  className="bg-moon-darkest"
                  value={JSON.stringify(kidscriptCompileData, null, 2)}
                  height="300px"
                  extensions={[json()]}
                  editable={true}
                  theme={githubDark}
                />
              </div>
            )}
        </div>
      )}
    </div>
  )
}

export default ValidationTarget
