'use client'

// import { MarkdownEditor } from '@codeverse/helios/markdown'
import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import MarkdownEditor from './MarkdownPreview'

interface DetailFieldProps {
  control: Control<MissionStepFormValues>
}

const DetailField: React.FC<DetailFieldProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header label="Detail" description="Specific instructions can go here" />
      <Controller
        name="detail"
        control={control}
        render={({ field }) => <MarkdownEditor {...field} />}
      />
    </div>
  )
}

export default DetailField
