'use client'
import React from 'react'
import FormCheckbox from './FormCheckbox'
import { Control, Controller } from 'react-hook-form'
import { MissionStepFormValues } from '../types'
import Header from './Header'

interface RunnableProps {
  control: Control<MissionStepFormValues>
}

const Runnable: React.FC<RunnableProps> = ({ control }) => {
  return (
    <div className="mb-6" data-color-mode="light">
      <Header
        label="Runnable"
        description="If the step should have a run button"
      />
      <Controller
        name="runnable"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            // disabled={type === 'new'}
            classNameOverride="col-span-1"
            {...field}
          />
        )}
      />
    </div>
  )
}

export default Runnable
