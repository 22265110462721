import { DocumentSymbolOptions } from '@kidscript/editor/dist/src/types/DocumentSymbolOptions'
import { LineHighlightLineData } from '@kidscript/editor/dist/src/types/Highlight/LineHighlightLineData'
import { SymbolHighlightSymbolData } from '@kidscript/editor/dist/src/types/Highlight/SymbolHighlightSymbolData'
import { EditableSymbolsSymbolData } from '@kidscript/editor/dist/src/types/EditableSymbols/EditableSymbolsSymbolData'
import { StepLineAddress } from '@kidscript/editor/dist/src/types/StepLineAddress'
import { ChaosSectionLineAddress } from '@kidscript/editor/dist/src/types/ChaosSectionLineAddress'
import { StepSymbolAddress } from '@kidscript/editor/dist/src/types/StepSymbolAddress'
import { DocumentLineAndSymbolOptions } from '@kidscript/editor/dist/src/types/DocumentLineAndSymbolOptions'
import { DocumentOption } from '../Form/DocumentField'
import { DisplayOption } from '../Form/Display'

export type MissionStepFormValues = {
  id: string
  boilerplate: string | null
  document: number
  display: string
  position: number
  header: string
  content: string
  body: string
  detail: string
  side_bar_header: string
  image: string
  option: boolean
  kidscript: string
  line_address: LineAddress | null
  symbol_address: StepSymbolAddress | null
  library: any
  success_header: string
  highlight: DocumentLineAndSymbolOptions<
    LineHighlightLineData,
    SymbolHighlightSymbolData | null
  > | null
  video_uri: string
  audio_uri: string
  kidscript_changes: KidScriptChanges | null
  editable_symbols: DocumentSymbolOptions<EditableSymbolsSymbolData> | null
  type_over: any
  validation_target: 'kidscript' | 'interpreter' | 'editor' | null
  button_type: 'next' | 'run' | 'both' | 'none'
  validation_rules: any
  runnable: boolean
  run_automatically: boolean
}

export type KidScriptChanges = {
  documents: Array<KidScriptChangeDocumentType>
}

export type KidScriptChange =
  | {
      id?: string
      strategy: KidScriptStrategy.inherit
      locked: boolean
    }
  | {
      id?: string
      strategy:
        | KidScriptStrategy.append
        | KidScriptStrategy.prepend
        | KidScriptStrategy.replace
      kidscript: string
      locked: boolean
    }
  | {
      id?: string
      strategy: KidScriptStrategy.insert
      insertLine: number
      kidscript: string
      locked: boolean
    }

export type KidScriptChangeDocumentType = {
  document: number
  changes: Array<KidScriptChange>
}

export declare type LineAddress = StepLineAddress | ChaosSectionLineAddress

export const enum KidScriptStrategy {
  append = 'append',
  prepend = 'prepend',
  inherit = 'inherit',
  replace = 'replace',
  insert = 'insert',
}
