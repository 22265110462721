import classNames from 'classnames'
import React from 'react'
import { FileUploader } from 'react-drag-drop-files'
import ImageFileUploadDropZone from './ImageFileUploadDropZone'
import { toast } from 'react-toastify'

const fileTypes = ['JPG', 'PNG', 'JPEG']

interface ImageFileUploadProps {
  className?: string
  handleFile: any
  value?: string
  handleReset?: () => void
  theme?: 'light' | 'dark'
}

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const ImageFileUpload: React.FC<ImageFileUploadProps> = ({
  className,
  handleFile,
  value,
  handleReset,
  theme,
}) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false)
  // ref
  const inputRef = React.useRef(null)

  // triggers when file is selected with click
  const handleChange = async (file: any) => {
    if (file) {
      await toBase64(file).then((result) => handleFile(result))
    }
  }

  // triggers the input when the Button is clicked
  const onButtonClick = (e) => {
    e.preventDefault()
    // inputRef.current.click()
  }

  const labelClassname = classNames(
    'bg-noon-darkest h-full w-full flex items-center justify-center ring ring-neptune ',
    {
      'drag-active': dragActive,
    }
  )

  return (
    <div className={className}>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        multiple={false}
        maxSize={4}
        onSizeError={(error: any) => toast.error(error)}
        onTypeError={(error: any) => toast.error(error)}
      >
        <ImageFileUploadDropZone
          handleReset={handleReset}
          value={value}
          theme={theme || 'light'}
        />
      </FileUploader>
    </div>
  )
}

export default ImageFileUpload
