import React from 'react'
import { DragHandle } from './DragHandle'

interface StaticTableRowProps {
  row: any
}

const StaticTableRow: React.FC<StaticTableRowProps> = ({ row }) => {
  return (
    <tr className="" {...row.getRowProps()}>
      {row.cells.map((cell: any, i: any) => {
        if (i === 0) {
          return (
            <td
              className="bg-neptune-darkest bg-opacity-25"
              {...cell.getCellProps()}
            >
              <div className="grid grid-cols-2">
                <DragHandle isDragging />
                {/* <span>{cell.render('Cell')}</span> */}
              </div>
            </td>
          )
        }
        return <td {...cell.getCellProps()}></td>
      })}
    </tr>
  )
}

export default StaticTableRow
