'use client'
import React, { useState, useContext } from 'react'
import classNames from 'classnames'

import Label from './label'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Defines the color of the input
   */
  valid?: boolean
  /**
   * Defines if the input is disabled
   */
  disabled?: boolean
  /**
   * Defines the type of the input
   */
  type?: string
  label?: string
  showError?: boolean
  icon?: any
  size?: 'regular' | 'large'
  inputRef?: any
  inputClassName?: string
  defaultValue?: string
  id?: string
  min?: string
  max?: string
  maxLength?: number
  checked?: boolean
  placeholder?: string
}

const input = {
  base: 'relative w-full bg-[#e9eff8]/50 text-base text-moon-dark rounded-lg caret-neptune-dark font-medium font-jakarta-sans',
  input:
    'bg-[#e9eff8]/50 h-10 px-3 leading-none w-full rounded-lg transition duration-300 focus:outline-none border border-[#dfe8f5] placeholder:text-[#42476D]',
  inputWithLabel:
    'bg-[#e9eff8]/50 h-10 px-3 pt-4 leading-none w-full rounded-lg transition duration-300 focus:outline-none border border-[#dfe8f5]',
  password: 'text-moon-light font-weight-bold font-size-lg',
  active: 'focus:ring focus:ring-neptune-light',
  disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
  valid: 'ring ring-titan-darkalt',
  invalid: 'ring ring-mars-dark',
  radio:
    'text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray',
  checkbox: '',
  size: {
    regular: 'h-12',
    large: '!h-14 text-20 text-moon-darkest',
  },
}

const labelTheme = {
  base: 'absolute top-1/2 transform left-1.5 text-lg text-moon pointer-events-none font-system px-4',
  // check and radio get this same style
  check: 'inline-flex items-center',
  invalid: 'text-mars-dark',
  valid: 'text-titan-darkalt',
  disabled: 'opacity-50 cursor-not-allowed',
  size: {
    regular: '!left-0',
    large: 'top-[22px] text-[20px] font-poppins-semibold',
  },
  focused: {
    large: 'font-system',
    regular: '',
  },
}

const Input = function Input(props: Props, ref: any) {
  const {
    label,
    children,
    valid,
    disabled,
    className,
    inputClassName,
    type = 'text',
    size = 'large',
    value,
    onChange,
    showError,
    // withIcon,
    icon,
    inputRef,
    defaultValue,
    min,
    max,
    maxLength,
    checked,
    onKeyUp,
    ...other
  } = props
  const [focused, setFocused] = useState(false)
  const [inputVal, setValue] = useState('')

  const baseStyle = input.base
  const activeStyle = input.active
  const disabledStyle = input.disabled
  const validStyle = input.valid
  const invalidStyle = input.invalid
  const radioStyle = input.radio
  const checkStyle = input.checkbox

  const inputStyle = label ? input.inputWithLabel : input.input
  const inputStyleSize = input.size[size]
  const passwordInputStyle = input.password
  const inputStyling = classNames(inputStyle, inputStyleSize, {
    passwordInputStyle: type === 'password',
  })

  const labelStyle = labelTheme.base
  const labelStyleSize = labelTheme.size[size]
  const labelFocusedSize = labelTheme.focused[size]
  const validLabelStyle = labelTheme.valid
  const invalidLabelStyle = labelTheme.invalid

  function hasValidation(valid: boolean | undefined) {
    return valid !== undefined
  }

  function validationInputStyle(valid: boolean | undefined): string {
    if (hasValidation(valid)) {
      return valid ? validStyle : invalidStyle
    }
    return ''
  }

  function validationLabelStyle(valid: boolean | undefined): string {
    if (hasValidation(valid)) {
      return valid ? validLabelStyle : invalidLabelStyle
    }
    return ''
  }

  function typeStyle(type: string): string {
    switch (type) {
      case 'radio':
        return radioStyle
      case 'checkbox':
        return checkStyle
      default:
        return baseStyle
    }
  }

  const inputCls = classNames(
    inputStyling,
    hasValidation(valid) && !disabled && validationInputStyle(valid),
    // don't apply activeStyle if has valid or disabled
    !hasValidation(valid) && !disabled && activeStyle,
    // don't apply disabledStyle if has valid
    !hasValidation(valid) && disabled && disabledStyle
  )

  const cls = classNames(typeStyle(type), className)

  const labelCls = classNames(
    labelStyle,
    labelStyleSize,
    labelFocusedSize,
    hasValidation(valid) && !disabled && validationLabelStyle(valid),
    {
      'has-value':
        focused || (value && value.length > 0) || inputVal.length > 0,
    }
  )

  const handleFocus = () => {
    setFocused(true)
  }
  const handleBlur = () => {
    setFocused(false)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return (
    <div className={cls}>
      {type !== 'checkbox' && <Label className={labelCls}>{label}</Label>}
      <input
        id={props.id}
        className={inputCls + ' ' + inputClassName}
        type={type}
        disabled={disabled}
        {...other}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value || inputVal}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={onChange || handleChange}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyUp={props.onKeyUp}
        // checked={checked}
      ></input>
      {children}
      {icon && icon()}
    </div>
  )
}

export default React.forwardRef(Input)
