import { SatelliteClient } from '@codeverse/satellite-client'
import { FetchOptions } from '../fetch/FetchOptions'

export const getStreamToken = (userId?: string) =>
  ({
    method: 'GET',
    url: `/users/${userId}`,
    query: {
      include_meta: {
        stream_token: true,
      },
    },
  } as FetchOptions)

export const getUserKeyrings = (userId: string) =>
  ({
    method: 'GET',
    url: `/users/${userId}/keyring_grants`,
    query: {
      include_deleted: true,
    },
  } as FetchOptions)

export const getUserFollowers = (userId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(`/users/${userId}/followers`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
    },
  })
}

export const getUserFollowings = (userId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(`/users/${userId}/followings`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
    },
  })
}

export const getUserProfile = (username: string) => {
  return SatelliteClient.platform.get(`/users/${username}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}

export const updateDocument = (
  documentId: string,
  filename: any,
  kidscript: string,
  projectId: string
) => {
  const data = {
    data: {
      id: documentId,
      attributes: {
        filename: filename,
        kidscript: kidscript,
      },
      relationships: {
        project: {
          data: {
            type: 'projects',
            id: projectId,
          },
        },
      },
      type: 'documents',
    },
  }

  return SatelliteClient.platform.put(`/documents/${documentId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data,
  })
}

export const getUserPublicApps = (userId: string | undefined) => {
  const currentUserId = localStorage.getItem('currentUserId')
  return SatelliteClient.platform.get(`/users/${userId}/public_apps`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      access_token: localStorage.getItem('accessToken'),
      current_user_id: currentUserId,
      current_organization_id: localStorage.getItem('currentOrganizationId'),
    },
  })
}

export const getUserStarredProjects = (userId?: string) => {
  return SatelliteClient.platform.get(`/users/${userId}/version_stars/`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}

export const createUserProjectVersionStar = (versionId: string) => {
  const userId = localStorage.getItem('currentUserId')
  return SatelliteClient.platform.post('/version_stars', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      relationships: {
        version: {
          id: versionId,
          type: 'versions',
        },
      },
    },
    query: {
      current_user_id: userId,
    },
    serializer: 'version_stars',
  })
}
export const deleteUserProjectVersionStar = (starId: string) => {
  return SatelliteClient.platform.delete(`/version_stars/${starId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}
