import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AuthStore {
  isAuthenticated: boolean
  setIsAuthenticated: (isAuthenticated: boolean) => void
  currentUserId: string
  setCurrentUserId: (currentUserId: string) => void
  accessToken: string
  setAccessToken: (accessToken: string) => void
}

const useAuthStore = create<AuthStore>()(
  devtools(
    (set) => ({
      isAuthenticated: false,
      setIsAuthenticated: (isAuthenticated: boolean) =>
        set((state) => ({ isAuthenticated: isAuthenticated })),
      currentUserId: '',
      setCurrentUserId: (currentUserId: string) =>
        set((state) => ({ currentUserId: currentUserId })),
      accessToken: '',
      setAccessToken: (accessToken: string) =>
        set((state) => ({ accessToken: accessToken })),
    }),
    {
      name: 'Auth Store',
    }
  )
)

export default useAuthStore
