'use client'
import ImageFileUpload from 'apps/studio-shared/src/Components/Missions/MissionBuilder/Mission/Form/ImageFileUpload'
import Header from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Header'
import { headerClassnames } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/Styles'
import { MissionStepFormValues } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/types'
import classNames from 'classnames'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface ImageProps {
  control: Control<MissionStepFormValues>
}

const ImageField: React.FC<ImageProps> = ({ control }) => {
  return (
    <div>
      <Header label="Image" description="The image to display in the content" />
      <Controller
        name="image"
        control={control}
        render={({ field }) => {
          return (
            <ImageFileUpload
              className="pb-5"
              theme="dark"
              handleFile={field.onChange}
              value={field.value}
              handleReset={() => field.onChange(null)}
            />
          )
        }}
      />
    </div>
  )
}

export default ImageField
