'use client'

import { v4 as uuidv4 } from 'uuid'

import React, { useCallback, useMemo, useState } from 'react'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import {
  KidScriptChange,
  KidScriptChangeDocumentType,
  KidScriptStrategy,
  MissionStepFormValues,
} from '../../types'
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { useParams } from 'react-router'
import DocumentKidScriptChanges from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/Form/KidScriptChanges/DocumentKidScriptChanges'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import Portal from 'apps/studio/components/Shared/Portal'
import Modal from '@codeverse/helios/modal'
import KidScriptEditModal from './KidScriptEditModal'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionStepActions from '../../useMissionStepActions'

interface KidScriptChangesProps {
  control: Control<MissionStepFormValues>
  setValue: UseFormSetValue<MissionStepFormValues>
  getValues: UseFormGetValues<MissionStepFormValues>
  setEditorValues: () => void
}

const KidScriptChanges: React.FC<KidScriptChangesProps> = ({
  control,
  setValue,
  getValues,
  setEditorValues,
}) => {
  const params = useParams()
  const { currentStep } = useMissionStore()
  const { currentKidScriptChange, setCurrentKidScriptChange } =
    useMissionBuilderStore()
  const { kidscriptEditModal, setKidscriptEditModal } = useUIStore()
  const { missionBuilderRefs } = useRuntimeRefsStore()
  const { replayMissionStep } = useMissionStepActions()
  const { data: missionSteps } = useMissionSteps(params?.id as string)
  const [currentDocument, setCurrentDocument] = useState<'main' | 'head'>(
    'main'
  )
  const missionEditorValidatorRef = useMemo(() => {}, [])

  const MenuBarItemClassnames =
    'flex justify-center items-center cursor-pointer relative text-base text-bold w-full rounded-[18px] transition-all duration-300 hover:ring hover:ring-neptune-lightest hover:ring-opacity-20'

  const mainClassnames = classNames(MenuBarItemClassnames, {
    'ring ring-neptune-lightest !ring-opacity-70': currentDocument === 'main',
    // 'hover:ring hover:ring-neptune-light': state.menuSelection !== 'projects',
  })

  const headClassnames = classNames(MenuBarItemClassnames, {
    'ring ring-neptune-lightest !ring-opacity-70': currentDocument === 'head',
    // 'hover:ring hover:ring-neptune-light': state.menuSelection !== 'projects',
  })

  const textareaClassnames = classNames(
    'bg-moon-lightest focus:outline-none focus:ring focus:ring-neptune font-poppins-medium max-h-[72px] pl-[16px] placeholder-moon pr-1 py-2 resize-none rounded-lg text-base text-white w-full'
  )

  const MenuBarText =
    'flex flex-row items-center relative text-sm leading-[14px] font-bold transition-all duration-300'
  const textClassnames = classNames(MenuBarText, '', {})

  const stepDependencies = useMemo(() => {
    const missionBuilderRef = missionBuilderRefs['main']
    if (missionBuilderRef && currentStep) {
      try {
        const stepDeps = missionBuilderRef.getStepDependencies(
          currentStep.position
        )
        return stepDeps
      } catch (e) {
        console.log(e)
      }
    }
    return []
  }, [missionBuilderRefs, currentStep])

  const addKidScriptChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    documentNumber: 0 | 1
  ) => {
    e.preventDefault()
    const values = getValues()
    const tempKidscriptChanges = Object.assign({}, values?.kidscript_changes)

    const documents = tempKidscriptChanges.documents
    const foundDocument = documents?.find(
      (document: any) => document.document === documentNumber
    )

    if (foundDocument && foundDocument.changes) {
      const hasInherit = foundDocument.changes.find(
        (change: any) => change.strategy === 'inherit'
      )
      const newObject = Object.assign(
        {},
        {
          strategy: KidScriptStrategy.append,
          locked: true,
          id: uuidv4(),
          kidscript: '',
        },
        hasInherit && {
          kidscript: '',
          insertLine: 1,
        }
      )
      foundDocument.changes.push(newObject)
    } else if (missionSteps?.length === 0) {
      tempKidscriptChanges.documents = []
      tempKidscriptChanges.documents.push({
        document: documentNumber,
        changes: [
          {
            id: uuidv4(),
            strategy: KidScriptStrategy.replace,
            kidscript: '',
            locked: true,
          },
        ],
      })
    } else {
      if (!tempKidscriptChanges.documents) {
        tempKidscriptChanges.documents = []
        tempKidscriptChanges.documents.push({
          document: documentNumber,
          changes: [
            {
              id: uuidv4(),
              strategy: KidScriptStrategy.append,
              kidscript: '',
              locked: true,
            },
          ],
        })
      } else {
        tempKidscriptChanges.documents.push({
          document: documentNumber,
          changes: [
            {
              id: uuidv4(),
              strategy: KidScriptStrategy.append,
              kidscript: '',
              locked: true,
            },
          ],
        })
      }
    }
    setValue('kidscript_changes', tempKidscriptChanges, { shouldDirty: true })
    replayMissionStep(getValues(), true)
  }

  const removeKidScriptChange = useCallback(
    (documentIndex: 0 | 1, kidscriptChangeIndex: number) => {
      const tempKidscriptChanges = Object.assign(
        {},
        {
          ...currentStep?.kidscript_changes,
        }
      )
      if (tempKidscriptChanges) {
        const ksDocuments = tempKidscriptChanges.documents
        const foundDocument = ksDocuments[documentIndex]
        foundDocument?.changes.splice(kidscriptChangeIndex, 1)
        setValue('kidscript_changes', tempKidscriptChanges, {
          shouldDirty: true,
        })
        setEditorValues()
        replayMissionStep(getValues(), true)
      }
    },
    [currentStep, setValue]
  )

  const handleSetKidScriptChanges = (
    documentIndex: number,
    changeIndex: number,
    kidscriptChange: KidScriptChange
  ) => {
    const kidscriptChanges = getValues('kidscript_changes')
    const newKidscriptChanges = Object.assign({}, kidscriptChanges)
    //@ts-ignore
    const document = newKidscriptChanges?.documents.find((doc, index) => {
      return index === documentIndex
    })

    if (!document?.changes) return

    const newDocument: KidScriptChangeDocumentType = {
      document: documentIndex,
      changes: [...document.changes],
    }

    if (document.changes[changeIndex]) {
      //@ts-ignore
      newKidscriptChanges?.documents[documentIndex].changes.splice(
        changeIndex,
        1,
        kidscriptChange
      )
    } else {
      if (documentIndex) {
        // newKidscriptChanges.documents.splice(changeIndex, 1, newDocument)
      }
    }
    setEditorValues()
    replayMissionStep(getValues(), true)
    setValue('kidscript_changes', newKidscriptChanges, { shouldDirty: true })
  }
  return (
    <div className="mt-4">
      <div className="flex flex-row items-center">
        <h4 className="leading-24 !mb-0 text-white">KidScript Changes</h4>
        <div className="ml-auto">
          <Button
            className="!pl-4 pr-6 shadow-none"
            size="small"
            context="nebula"
            onClick={(e) => {
              e.preventDefault()
              addKidScriptChange(e, currentDocument === 'main' ? 1 : 0)
            }}
          >
            <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
            Add
          </Button>
        </div>
      </div>
      <div className="mt-0.5">
        <p className="text-neptune-lightest mb-4 text-xs leading-6">
          Determine inherited, appended, changed KidScript here
        </p>
      </div>

      <div className="bg-neptune-darkest mb-6 mt-6 grid h-9 w-full grid-cols-2 gap-1.5 rounded-[18px] bg-opacity-30 text-white transition-all duration-300">
        <div
          id="KidScriptChanges_Main"
          className={mainClassnames}
          onClick={() => setCurrentDocument('main')}
        >
          <div className={textClassnames}>
            <div>Main.ks</div>
          </div>
        </div>
        <div
          id="KidScriptChanges_Head"
          className={headClassnames}
          onClick={() => setCurrentDocument('head')}
        >
          <div className={textClassnames}>
            <div>Head.ks</div>
          </div>
        </div>
      </div>

      <div
        className={classNames('min-h-[230px]', {
          hidden: currentDocument !== 'main',
        })}
      >
        {
          <Controller
            name="kidscript_changes"
            control={control}
            render={({ field }) => {
              const MainDocument = field.value?.documents?.find(
                (doc) => doc.document === 1
              )
              if (!MainDocument) {
                return <></>
              }
              return (
                <DocumentKidScriptChanges
                  changes={MainDocument.changes}
                  handleSetKidScriptChanges={handleSetKidScriptChanges}
                  control={control}
                  documentIndex={
                    field.value?.documents?.findIndex(
                      (doc) => doc.document === 1
                    ) as 0 | 1
                  }
                  removeKidScriptChange={removeKidScriptChange}
                  //@ts-ignore
                  kidscriptChanges={field.value}
                  setValue={setValue}
                  stepDependencies={stepDependencies}
                />
              )
            }}
          />
        }
      </div>
      <div
        className={classNames('min-h-[230px]', {
          hidden: currentDocument !== 'head',
        })}
      >
        {
          <Controller
            name="kidscript_changes"
            control={control}
            render={({ field }) => {
              const HeadDocument = field.value?.documents?.find(
                (doc) => doc.document === 0
              )
              if (!HeadDocument) {
                return <></>
              }
              return (
                <DocumentKidScriptChanges
                  changes={HeadDocument.changes}
                  handleSetKidScriptChanges={handleSetKidScriptChanges}
                  control={control}
                  documentIndex={
                    field?.value?.documents.findIndex(
                      (doc) => doc.document === 0
                    ) as 0 | 1
                  }
                  removeKidScriptChange={removeKidScriptChange}
                  //@ts-ignore
                  kidscriptChanges={field?.value}
                  setValue={setValue}
                  stepDependencies={stepDependencies}
                />
                // <>w</>
              )
            }}
          />
        }
      </div>

      <Controller
        name="kidscript_changes"
        control={control}
        render={({ field }) => {
          if (!currentKidScriptChange) return <></>
          const { documentIndex, changeIndex } = currentKidScriptChange
          return (
            <Portal idToSearch="MissionBuilderKidScriptEditModal">
              <KidScriptEditModal
                handleSetKidScriptChanges={handleSetKidScriptChanges}
                documents={field?.value?.documents}
                documentIndex={documentIndex}
                changeIndex={changeIndex}
              />
            </Portal>
          )
        }}
      />
    </div>
  )
}

export default KidScriptChanges
