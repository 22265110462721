'use client'

import { DynamicSymbolContentPosition } from '@kidscript/editor/dist/src/types/DynamicSymbolContent/DynamicSymbolContentPosition'
import { LibraryFilterOptions } from '@kidscript/library-filter'
import { useCallback, useEffect, useMemo } from 'react'
import useMissionStore from '../../../../Stores/useMissionStore'
import { EditorOptions } from '@kidscript/editor/dist/src/types/EditorOptions'
import { useParams, usePathname } from 'next/navigation'
import { useRuntimeRefsStore } from '../../../Studio/Hooks/Runtime/useRuntimeRefsStore'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'
import { KidScriptStrategy } from '@kidscript/editor/dist/src/types/KidScriptStrategy'
import { EditorMode } from '@kidscript/editor/dist/src/types/EditorMode'
import NovaCoin from 'apps/studio-shared/src/Components/Home/ProfilePanel/NovaCoin.svg'
import Image from 'next/image'
import {
  useAllMissionStepInsertables,
  useMissionAchievement,
  useMissionStepInsertables,
  useMissionSteps,
} from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { toast } from 'react-toastify'
import { MissionStepChanges } from '@kidscript/editor/dist/src/types/MissionStepChanges'
import useKidScript from '../../../Studio/Hooks/useKidScript'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useUIStore from 'apps/studio-shared/src/Stores/useUIStore'
import { useMutateMissionAchievement } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import { useQueryClient } from '@tanstack/react-query'

export const missionStepsToOptions = (
  missionSteps: any,
  guidedMissionStepPortalRef: any,
  missionStepInsertables?: any,
  missionAchievementStepId?: string
) => {
  return missionSteps.map((step: any, index: number) => {
    const insertables = missionStepInsertables
      ? missionStepInsertables[index]?.missionStepInsertables
      : []
    return {
      document: step.document,
      mode: step.boilerplate,
      kidScriptChanges:
        step.kidscript_changes?.documents.length > 0
          ? step.kidscript_changes
          : { documents: [] },
      highlights: step.highlight,
      insertables: insertables,
      editableSymbols: step.editable_symbols,
      typeOver: step.type_over,
      libraryFilter: step.library as LibraryFilterOptions,
      dynamicContent:
        (missionAchievementStepId === step.id || missionSteps.length === 1) &&
        (step.line_address || step.symbol_address) &&
        guidedMissionStepPortalRef?.current
          ? Object.assign(
              {},
              {
                content: guidedMissionStepPortalRef.current,
                position: step.symbol_address
                  ? ('under' as DynamicSymbolContentPosition)
                  : step.display === 'modal'
                  ? ('over' as DynamicSymbolContentPosition)
                  : ('outside' as DynamicSymbolContentPosition),
              },
              step.symbol_address && {
                symbolAddress: step.symbol_address,
              },
              step.line_address && {
                lineAddress: step.line_address,
              }
            )
          : null,
    }
  })
}

const useMissionStepActions = () => {
  const params = useParams()
  const pathname = usePathname()

  const isMissionBuilder = pathname.includes('mission_builder')
  const { currentMission } = useMissionStore()
  const { data: missionSteps } = useMissionSteps(currentMission?.id)
  const {
    currentStep,
    missionStepPortalRef,
    setCurrentStep,
    setShowPortal,
    missionAchievement: missionAchievementGraphql,
  } = useMissionStore()
  const { data: missionAchievement } = useMissionAchievement(
    missionAchievementGraphql?.id
  )
  const queryClient = useQueryClient()
  const { kidScriptSetup } = useRuntimeStore()
  const { editorRefs } = useRuntimeRefsStore()
  const { setShowMissionCompleteModal } = useUIStore()
  const { setGuidedMissionMode, setBlockNextStep } = useMissionStore()
  const {
    previewMissionMode,
    setPreviewMissionMode,
    setShowMissionEditor,
    missionInsertablesToCreate,
  } = useMissionBuilderStore()
  const { stopKidScript, runKidScript } = useKidScript()
  const mutateMissionAchievement = useMutateMissionAchievement()

  const { data: MissionStepInsertablesData } = useAllMissionStepInsertables(
    params?.id as string
  )

  // const insertablesToReplay = useMemo(() => {
  //   if (!currentStep) return []
  //   //@ts-ignore
  //   let insertablesByMissionSteps = []
  //   MissionStepInsertablesData?.missions.map((mission) => {
  //     mission.missionSteps.map((step) => {
  //       if (step.position < currentStep.position) {
  //         //@ts-ignore
  //         insertablesByMissionSteps.push(...step.missionStepInsertables)
  //       }
  //     })
  //   })
  //   //@ts-ignore
  //   return insertablesByMissionSteps
  // }, [MissionStepInsertablesData, currentStep])
  const mainEditorRef = editorRefs['main']
  const replayMissionStep = useCallback(
    (
      currentStepToPlay: any,
      adminMode: boolean,
      stepChanges?: Array<MissionStepChanges>,
      previewMission?: boolean
    ) => {
      // if (!missionStepPortalRef.current) return
      const missionStepsToPlay = missionSteps?.filter((step: any) => {
        return step.attributes.position < currentStepToPlay?.position
      })

      const optionsToReplay =
        (missionStepsToPlay?.map((step: any) =>
          getEditorOption(step.attributes)
        ) as EditorOptions[]) || []
      const lastStep = getEditorOption(currentStepToPlay)
      optionsToReplay.push(lastStep)
      if (mainEditorRef && optionsToReplay?.length > 0) {
        try {
          console.log('Replaying mission steps:', optionsToReplay)
          console.log('Step Changes:', stepChanges)
          console.log('Admin Mode:', adminMode)
          mainEditorRef.replayOptions(optionsToReplay, adminMode, stepChanges)
        } catch (e) {
          toast.dismiss()
          //@ts-ignore
          H?.consumeError(e, 'Error replaying mission steps', {
            options: optionsToReplay,
            stepChanges,
          })
          console.error('Failed to replay options', e)
          //@ts-ignore
        }
      }
    },
    [
      missionSteps,
      editorRefs,
      kidScriptSetup,
      missionStepPortalRef,
      mainEditorRef,
    ]
  )

  const getEditorOption = useCallback(
    (stepDetails: any) => {
      const stepDetail = stepDetails

      const newDiv = document.createElement('div')
      newDiv.setAttribute('id', 'MissionStepPortal')

      //@ts-ignore
      let insertablesToReplay = []
      MissionStepInsertablesData?.missions.map((mission) => {
        mission.missionSteps.map((step) => {
          if (stepDetail.position === step.position) {
            //@ts-ignore
            insertablesToReplay.push(...step.missionStepInsertables)
          }
        })
      })
      const showDynamicContent =
        (stepDetails.display === 'line' || stepDetails.display === 'modal') &&
        (stepDetails.line_address || stepDetails.symbol_address) &&
        stepDetail
      const options: EditorOptions = {
        document:
          typeof stepDetail.document === 'number'
            ? stepDetail.document
            : //@ts-ignore
              stepDetail.document,
        //@ts-ignore
        mode: stepDetail.boilerplate,
        // insertables: missionInsertablesToCreate || missionStepInsertables || [],
        //@ts-ignore
        insertables: insertablesToReplay,
        //@ts-ignore
        kidScriptChanges: stepDetail.kidScriptChanges
          ? stepDetail.kidScriptChanges?.documents.length > 0
            ? stepDetail.kidScriptChanges
            : { documents: [] }
          : stepDetail.kidscript_changes?.documents.length > 0
          ? stepDetail.kidscript_changes
          : { documents: [] },
        highlights:
          stepDetail.highlight && Object.keys(stepDetail.highlight).length !== 0
            ? stepDetail.highlight
            : undefined,
        editableSymbols:
          stepDetail.editable_symbols &&
          Object.keys(stepDetail.editable_symbols).length !== 0
            ? stepDetail.editable_symbols
            : undefined,
        typeOver: stepDetail.type_over,
        libraryFilter:
          stepDetail.library && Object.keys(stepDetail.library).length !== 0
            ? (stepDetail.library as LibraryFilterOptions)
            : undefined,
        dynamicContent: showDynamicContent
          ? Object.assign(
              {},
              {
                content: missionStepPortalRef?.current,
                position: stepDetails.symbol_address
                  ? ('under' as DynamicSymbolContentPosition)
                  : stepDetails.display === 'modal'
                  ? ('over' as DynamicSymbolContentPosition)
                  : ('outside' as DynamicSymbolContentPosition),
              },
              stepDetails.symbol_address && {
                symbolAddress: stepDetails.symbol_address,
              },
              stepDetails.line_address && {
                lineAddress: stepDetails.line_address,
              }
            )
          : null,
      }
      return options
    },
    [missionStepPortalRef, MissionStepInsertablesData]
  )

  const resetMissionStepBuilderEditor = useCallback(() => {
    const editorRef = editorRefs['main']
    if (!editorRef) return
    editorRef.reset()
    editorRef.setOptions(
      {
        document: 1,
        mode: 'info' as EditorMode.info,
        insertables: [],
        kidScriptChanges: {
          documents: [
            {
              document: 0,
              changes: [
                {
                  kidscript: '',
                  strategy: 'replace' as KidScriptStrategy.replace,
                  locked: true,
                },
              ],
            },
            {
              document: 1,
              changes: [
                {
                  kidscript:
                    '# choose a step to start adding kidscript changes',
                  strategy: 'replace' as KidScriptStrategy.replace,
                  locked: true,
                },
              ],
            },
          ],
        },
        highlights: undefined,
        typeOver: undefined,
        libraryFilter: undefined,
        dynamicContent: undefined,
      },
      true
    )
  }, [editorRefs])

  const handleCloseMissionEditor = () => {
    setPreviewMissionMode(false)
    setGuidedMissionMode(false)
    setShowMissionEditor(true)
    setShowPortal(false)
  }

  const handleNextStep = useCallback(() => {
    stopKidScript()
    setShowPortal(false)
    const nextStep = missionSteps?.find((step: any) => {
      return step.attributes.position === currentStep?.position + 1
    })

    if (nextStep && editorRefs['main']) {
      let stepChanges = editorRefs['main'].getMissionStepChanges()
      const editorOption = getEditorOption(nextStep.attributes)

      editorRefs['main']?.setOptions(
        editorOption,
        false,
        //@ts-ignore
        editorRefs['main'].getMissionStepChanges()
      )
      setCurrentStep({ id: nextStep.id, ...nextStep.attributes })
      if (nextStep.attributes.run_automatically) {
        runKidScript()
      }
      setShowPortal(true)
      if (missionAchievement && missionAchievement.step_changes) {
        console.log(
          'missionAchievement.step_changes',
          missionAchievement.step_changes
        )
        if (stepChanges) {
          stepChanges = missionAchievement.step_changes?.steps?.concat([
            stepChanges,
          ])
        }
      }

      mutateMissionAchievement.mutate(
        {
          //@ts-ignore
          missionStepId: nextStep.id,
          missionAchievementId: missionAchievement?.id,
          data: Object.assign(
            {},
            stepChanges && {
              step_changes: {
                steps: stepChanges,
              },
            }
          ),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['missionAchievement'] })
          },
        }
      )
    } else {
      handleCloseMissionEditor()
    }
  }, [
    currentStep,
    missionStepPortalRef,
    replayMissionStep,
    getEditorOption,
    missionSteps,
    missionAchievement,
  ])

  const handlePrevStep = () => {
    stopKidScript()
    setShowPortal(false)
    const prevStep = missionSteps?.find((step: any) => {
      return step.attributes.position === currentStep?.position - 1
    })

    const stepChanges = missionAchievement?.step_changes?.steps?.filter(
      //@ts-ignore
      (stepChange) => stepChange.step < prevStep?.attributes.position
    )

    if (prevStep) {
      //@ts-ignore
      replayMissionStep(prevStep.attributes, false, stepChanges || [])
      //@ts-ignore
      setCurrentStep({ id: prevStep.id, ...prevStep.attributes })
      // replayMissionStep(nextStep, false, true)
      //@ts-ignore
      if (prevStep.attributes.run_automatically) {
        runKidScript()
      }
      setShowPortal(true)
      //TODO: save mission progression
      mutateMissionAchievement.mutate(
        {
          //@ts-ignore
          missionStepId: prevStep.id,
          missionAchievementId: missionAchievement?.id,
          data: {
            step_changes: {
              steps: stepChanges,
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['missionAchievement'] })
          },
        }
      )
    } else {
      handleCloseMissionEditor()
    }
  }

  const handleFinish = () => {
    setBlockNextStep(false)
    if (isMissionBuilder) {
      handleCloseMissionEditor()
    } else {
      setShowMissionCompleteModal(true)
      mutateMissionAchievement.mutate(
        {
          //@ts-ignore
          missionStepId: currentStep.id,
          missionAchievementId: missionAchievement?.id,
          data: Object.assign({
            completed: true,
          }),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['missionAchievement'] })
          },
        }
      )
      toast.success(
        () => {
          return (
            <div className="flex min-w-[500px] flex-row items-center justify-center">
              <div>Mission Complete!</div>
              &nbsp; You've Earned:{' '}
              <div className="bg-moon-darkest ml-2 flex h-8 w-auto flex-row items-center self-end rounded-full p-1 text-white">
                <Image src={NovaCoin} alt="NovaCoin" height={25} width={24} />+
                {currentMission.coins}
              </div>
              <div className="ml-2 flex h-8 w-auto items-center justify-center self-end rounded-full bg-white p-2">
                +{currentMission.points} skill points
              </div>
            </div>
          )
        },
        {
          position: 'bottom-center',
        }
      )
    }
  }

  return {
    replayMissionStep,
    getEditorOption,
    resetMissionStepBuilderEditor,
    handleNextStep,
    handleFinish,
    handlePrevStep,
  }
}

export default useMissionStepActions
