import { Editor } from '@kidscript/editor'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type RefsStore = {
  editorRefs: Record<string, Editor | null>
  interpreterRefs: Record<string, any | null>
  missionBuilderRefs: Record<string, any | null>
  setEditorRef: (key: string, ref: Editor | null) => void
  setInterpreterRef: (key: string, ref: any | null) => void
  setMissionBuilderRef: (key: string, ref: any | null) => void
  resetEditorRefs: () => void
}

export const useRuntimeRefsStore = create<RefsStore>()((set) => ({
  editorRefs: {},
  setEditorRef: (key, ref) =>
    set((state) => ({ editorRefs: { ...state.editorRefs, [key]: ref } })),
  interpreterRefs: {},
  setInterpreterRef: (key, ref) =>
    set((state) => ({
      interpreterRefs: { ...state.interpreterRefs, [key]: ref },
    })),
  missionBuilderRefs: {},
  setMissionBuilderRef: (key, ref) =>
    set((state) => ({
      missionBuilderRefs: { ...state.missionBuilderRefs, [key]: ref },
    })),
  resetEditorRefs: () =>
    set((state) => ({
      editorRefs: {},
      interpreterRefs: {},
      missionBuilderRefs: {},
    })),
}))
